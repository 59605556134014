import aweigh from './aweigh.svg';
import heaveUp from './heaveUp.svg';
import letGo from './letGo.svg';
import outOfTheWater from './outOfTheWater.svg';
import round from './round.svg';
import secured from './secured.svg';
import vesselAtAnchor from './vesselAtAnchor.svg';

export default {
  aweigh,
  heaveUp,
  letGo,
  outOfTheWater,
  round,
  secured,
  vesselAtAnchor,
};
