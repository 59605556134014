<template>
  <div class="textField" :class="{ marked: mark }">
    <span class="titleWrapper">{{ $t('protocol.posLat') }}</span>
    <div class="border d-flex align-center rounded py-1 px-3 gray_first--text bg-white">
      <input
        ref="degreesRef"
        v-model="degrees"
        autofill="off"
        class="textInput text-right"
        data-test="input-latitude-degrees"
        type="text"
        @click="degreesRef?.select()"
      />
      <span class="mr-1">°</span>
      <input
        ref="minutesRef"
        v-model="minutes"
        autofill="off"
        class="textInput text-right"
        data-test="input-latitude-minutes"
        type="text"
        @click="minutesRef?.select()"
      />
      <span>.</span>
      <input
        ref="secondsRef"
        v-model="seconds"
        autofill="off"
        class="textInput"
        data-test="input-latitude-seconds"
        type="text"
        @click="secondsRef?.select()"
      />
      <span class="mr-1">'</span>
      <v-switch
        ref="directionRef"
        v-model="direction"
        class="direction mt-0 primary--text"
        dense
        hide-details
        false-value="N"
        true-value="S"
        :label="direction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Coordinate } from '@anschuetz-elog/common';
import { ComponentPublicInstance, computed, defineComponent, PropType, ref, toRef } from 'vue';

export default defineComponent({
  name: 'LatitudeField',

  props: {
    value: {
      type: Object as PropType<Coordinate | null>,
      default: null,
    },
    mark: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_latitude: Coordinate | null): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const degreesRef = ref<HTMLInputElement>();
    const minutesRef = ref<HTMLInputElement>();
    const secondsRef = ref<HTMLInputElement>();
    const directionRef = ref<ComponentPublicInstance>();

    const degrees = computed<string>({
      get() {
        return !value.value ? '' : value.value.degrees;
      },
      set(degrees: string) {
        emitLatitude(degrees, minutes.value, seconds.value, direction.value);
        if (degrees.length >= 2) {
          minutesRef.value?.select();
        }
      },
    });

    const minutes = computed<string>({
      get() {
        return !value.value ? '' : value.value.minutes;
      },
      set(minutes: string) {
        emitLatitude(degrees.value, minutes, seconds.value, direction.value);
        if (minutes.length >= 2) {
          secondsRef.value?.select();
        }
      },
    });

    const seconds = computed<string>({
      get() {
        return !value.value ? '' : value.value.seconds;
      },
      set(seconds: string) {
        emitLatitude(degrees.value, minutes.value, seconds, direction.value);
        if (`${seconds}`.length >= 3 && directionRef.value) {
          directionRef.value.$el.querySelector('input')?.select();
        }
      },
    });

    const direction = computed<'N' | 'S'>({
      get() {
        return !value.value ? 'N' : (value.value.direction as 'N' | 'S');
      },
      set(direction: 'N' | 'S') {
        emitLatitude(degrees.value, minutes.value, seconds.value, direction);
      },
    });

    function emitLatitude(degrees: string, minutes: string, seconds: string, direction: 'N' | 'S'): void {
      if (!degrees && !minutes && !seconds) {
        context.emit('input', null);
      } else {
        context.emit('input', {
          degrees,
          minutes,
          seconds,
          direction,
        });
      }
    }

    return { degrees, minutes, seconds, direction, degreesRef, minutesRef, secondsRef, directionRef };
  },
});
</script>

<style scoped>
.border {
  border-style: solid;
  border-width: 1px;
}

.titleWrapper {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  text-align: left;
}

.textField .textInput {
  font-size: 16px;
  background: var(--v-white-base);
  outline: none;
  flex: 1 1 auto;
  width: 100%;
  padding: 4px 0 2px;
}

.textField.marked .textInput {
  background-color: var(--v-error_bg-base);
}

.direction {
  min-width: 60px;
  max-width: 60px;
}
</style>
