<template>
  <Fragment>
    <DetailsListEntry v-if="!edit" class="ma-0" :title="element.label" :content="model" />
    <ComboBox
      v-else
      v-model="model"
      :title="element.label"
      :mark="invalid"
      :content-array="contentArray"
      :data-test="`crewmember-${element.name}`"
    />
  </Fragment>
</template>

<script lang="ts">
import { CrewListMember, CrewMemberElement, logger } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';

import ComboBox from '#/components/ComboBox.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';
import useFind from '#/compositions/useFind';
import { getLatestObjectsWithHistory } from '#/lib/history';

export default defineComponent({
  name: 'FormCrewMember',

  components: {
    DetailsListEntry,
    ComboBox,
  },

  props: {
    element: {
      type: Object as PropType<CrewMemberElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },
  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const { data: crewLists } = useFind('crewList');

    const validCrewLists = computed(() => {
      return getLatestObjectsWithHistory(crewLists.value)
        .filter((crew) => crew.data.endTimestamp === undefined)
        .map(({ data }) => data);
    });

    const validCrewMembers = computed(() => {
      return validCrewLists.value.reduce<CrewListMember[]>((acc, cl) => {
        return [...acc, ...cl.crewMembers];
      }, []);
    });

    const model = computed<string | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string') {
          logger.error(
            'Value does not fit to crew member element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    const contentArray = computed<string[]>(() =>
      (validCrewMembers.value || [])
        .filter(({ name }) => (name || '').trim().length > 0)
        .map((cm) => {
          if ((cm.rank || '').trim().length === 0) {
            return cm.name;
          }
          return `${cm.name} (${cm.rank})`;
        }),
    );

    return {
      contentArray,
      model,
    };
  },
});
</script>
