<template>
  <Checkbox v-model="model" :title="element.label" :disabled="!edit" :mark="invalid" />
</template>

<script lang="ts">
import { CheckboxElement, logger } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, onMounted, PropType, toRef } from 'vue';

import Checkbox from '#/components/Checkbox.vue';

export default defineComponent({
  name: 'FormCheckbox',

  components: {
    Checkbox,
  },

  props: {
    element: {
      type: Object as PropType<CheckboxElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    onMounted(() => {
      emit('input', { ...value.value, [element.value.name]: value.value[element.value.name] ?? false });
    });

    const model = computed<boolean>({
      get() {
        const v = value.value[element.value.name] || false;
        if (typeof v !== 'boolean') {
          logger.error(
            'Value does not fit to checkbox element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return false;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    return { model };
  },
});
</script>
