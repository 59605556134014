<template>
  <div class="protocolEntry">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProtocolEntry',
});
</script>

<style scoped>
.protocolEntry {
  display: flex;
  margin: 0 8px 16px;
  text-align: left;
}

.protocolEntry > * {
  flex-grow: 1;
}

.protocolEntry > *:not(:only-child):not(:last-child) {
  margin-right: 8px;
}
</style>
