<template>
  <Fragment v-if="link === undefined"><slot /></Fragment>
  <div v-else class="flex gap-4">
    <div class="flex-grow">
      <DetailsListEntry v-if="linkValue" class="ma-0" :title="element.label" :content="readModeLinkValue" />
      <slot v-else />
    </div>
    <div
      v-if="linkedProtocol"
      class="flex flex-grow-0 h-10 w-10 bg-primary justify-center items-center self-end rounded cursor-pointer"
      @click="linkedProtocolModalOpen = true"
    >
      <Icon name="action/details" color="white" />
    </div>
    <div
      v-if="edit"
      class="flex flex-grow-0 h-10 w-10 bg-primary justify-center items-center self-end rounded cursor-pointer"
      @click="changeLinkModalOpen = true"
    >
      <Icon data-test="icon-link" name="action/link" color="white" />
    </div>

    <Modal
      v-if="linkedProtocol"
      :is-open="linkedProtocolModalOpen"
      :title="$tc('protocol.linking.linked_protocol', undefined, { field: element.label })"
      :max-width="800"
      view-only
      @cancel="linkedProtocolModalOpen = false"
    >
      <ProtocolDetails :protocol="linkedProtocol" one-column-layout />
    </Modal>
    <Modal
      v-if="edit"
      :is-open="changeLinkModalOpen"
      :title="$tc('protocol.linking.link_protocol', undefined, { field: element.label })"
      :max-width="1200"
      view-only
      @cancel="changeLinkModalOpen = false"
    >
      <ProtocolTable
        id="dataTable"
        class="ma-0"
        :protocols="linkableProtocols"
        :is-loading="linkableProtocolsLoading"
        :loading-message="$tc('loading', undefined, { entity: $tc('protocol.linking.linkable_protocols') })"
        :search-bar-title="$tc('protocol.linking.choose_linked_protocol')"
        start-with-all-dates
        hide-deleted
        @protocol-clicked="linkProtocol"
      >
        <template #componentBeforeTableStart>
          <div class="flex justify-center items-center h-13.5">
            <button
              v-t="'protocol.proceed_without_link'"
              class="text-primary text-sm font-semibold border-solid border-1 border-primary rounded h-10.5 px-1 py-2.5"
              data-test="button-createWithoutLink"
              @click="linkProtocol(undefined)"
            />
          </div>
        </template>
      </ProtocolTable>
    </Modal>
  </div>
</template>

<script lang="ts">
import { Element, FieldValue, isLinkableValue, isProtocolLink, Protocol, ProtocolLink } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import { getProtocolLinkValue } from '#/components/form/utils';
import Icon from '#/components/Icon.vue';
import Modal from '#/components/Modal.vue';
import ProtocolDetails from '#/components/protocol/ProtocolDetails.vue';
import ProtocolTable from '#/components/protocol/ProtocolTable.vue';
import useGet from '#/compositions/useGet';
import { loadProtocols } from '#/compositions/useProtocols';
import { getLatestObjectsWithHistory } from '#/lib/history';

import { ProtocolLinkConfigProp } from './utils';

export default defineComponent({
  name: 'ProtocolLinkContainer',

  components: {
    DetailsListEntry,
    Icon,
    Modal,
    ProtocolTable,
    ProtocolDetails,
  },

  props: {
    value: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    element: {
      type: Object as PropType<Element>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    link: {
      type: Object as PropType<ProtocolLinkConfigProp | undefined>,
      default: undefined,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const link = toRef(props, 'link');

    const changeLinkModalOpen = ref(false);
    const linkedProtocolModalOpen = ref(false);

    const linkValue = computed<ProtocolLink | undefined>({
      get() {
        const model = value.value[element.value.name];
        if (!isProtocolLink(model)) {
          return undefined;
        }
        return model;
      },
      set(newLinkValue) {
        emit('input', { ...value.value, [element.value.name]: newLinkValue });
      },
    });

    const readModeLinkValue = computed(() => {
      return getProtocolLinkValue(linkValue.value, false);
    });

    const protocolId = computed(() =>
      link.value !== undefined && linkValue.value !== undefined ? linkValue.value.protocol._id : undefined,
    );
    const { data: linkedProtocol } = useGet('protocol', protocolId);

    const { data: targetProtocolContentType } = useGet(
      'protocolContentType',
      computed(() => {
        if (!link.value) {
          return undefined;
        }
        return link.value.target.protocolContentType._id;
      }),
    );

    const currentLinkOwner = computed(() => {
      if (!link.value) {
        return null;
      }
      const {
        source: { protocol },
      } = link.value;
      return {
        _id: protocol._id,
        type: '_id' in protocol.type ? protocol.type._id : protocol.type.ref._id,
      };
    });
    const { data: potentialOtherOwners } = loadProtocols(
      computed(() => currentLinkOwner.value?.type),
      computed(() => ({
        _id: { $ne: currentLinkOwner.value?._id },
      })),
    );
    const latestPotentialOwners = computed(() => {
      const protocols = potentialOtherOwners.value.filter((protocol) => {
        const protocolTypeId = '_id' in protocol.type ? protocol.type._id : protocol.type.ref._id;
        const sourceProtocolTypeId = link.value
          ? '_id' in link.value.source.protocol.type
            ? link.value.source.protocol.type._id
            : link.value.source.protocol.type.ref._id
          : undefined;
        // this filtering is redundant (see query of #protocolsWithLinkQuery) but necessary for online only mode
        // TODO: remove this filtering when gateway supports querying for it
        return link.value !== undefined && protocolTypeId === sourceProtocolTypeId;
      });
      return getLatestObjectsWithHistory(protocols).map((protocolWithHistory) => protocolWithHistory.data);
    });
    const alreadyLinkedProtocolIds = computed(() => {
      if (!link.value) {
        return [];
      }
      const {
        source: { protocolContentType },
      } = link.value;
      return latestPotentialOwners.value.reduce<string[]>((ids, protocol) => {
        const data = protocol.contents.find(({ type }) => type._id === protocolContentType._id)?.data;
        if (!data) {
          return ids;
        }
        const id = (data[element.value.name] as undefined | ProtocolLink)?.protocol?._id;
        if (id === undefined) {
          return ids;
        }
        return [...ids, id];
      }, []);
    });

    const { data: allLinkableProtocols, isLoading: linkableProtocolsLoading } = loadProtocols(
      computed(() => {
        if (!link.value) {
          return undefined;
        }
        return link.value.target.protocolType._id;
      }),
    );
    const linkableProtocols = computed(() => {
      if (!link.value) {
        return [];
      }
      const linkTarget = link.value.target;
      const protocols = allLinkableProtocols.value.filter((protocol) => {
        const protocolTypeId = '_id' in protocol.type ? protocol.type._id : protocol.type.ref._id;
        // this filtering is redundant (see query of #linkableProtocolsQuery) but necessary for online only mode
        // TODO: remove this filtering when gateway supports querying for it
        return protocolTypeId === linkTarget.protocolType._id;
      });
      const latestProtocols = getLatestObjectsWithHistory(protocols).map(
        (protocolWithHistory) => protocolWithHistory.data,
      );
      if (link.value.source.allowedMultipleLinking) {
        return latestProtocols;
      }
      return latestProtocols.filter(
        (linkableProtocol) => !alreadyLinkedProtocolIds.value.includes(linkableProtocol._id),
      );
    });

    function linkProtocol(selectedProtocol: Protocol | undefined): void {
      changeLinkModalOpen.value = false;
      if (!link.value) {
        return;
      }
      if (!selectedProtocol) {
        linkValue.value = undefined;
        return;
      }
      const content = selectedProtocol.contents.find(({ type }) => type._id === targetProtocolContentType.value?._id);
      const targetElement = targetProtocolContentType.value?.schema?.find(
        (element): element is Element =>
          link.value !== undefined && element.type !== undefined && element._id === link.value.target.elementId,
      );
      const value = !content || !targetElement ? undefined : (content.data[targetElement.name] as FieldValue);
      if (!isLinkableValue(value)) {
        throw new Error(`Can not link to such a type of value: ${value}`);
      }
      linkValue.value = {
        protocol: Protocol.createRef(selectedProtocol._id),
        value: isProtocolLink(value) ? value.value : value,
      };
    }

    return {
      linkValue,
      readModeLinkValue,
      changeLinkModalOpen,
      linkedProtocolModalOpen,
      protocolId,
      linkedProtocol,
      allLinkableProtocols,
      linkableProtocols,
      linkableProtocolsLoading,
      linkProtocol,
      alreadyLinkedProtocolIds,
      latestPotentialOwners,
      potentialOtherOwners,
    };
  },
});
</script>
