import chevronDown from './chevronDown.svg';
import chevronLeft from './chevronLeft.svg';
import chevronRight from './chevronRight.svg';
import chevronSmallDown from './chevronSmallDown.svg';
import chevronSmallRight from './chevronSmallRight.svg';
import chevronSmallUp from './chevronSmallUp.svg';
import chevronUp from './chevronUp.svg';
import cornerLeftUp from './cornerLeftUp.svg';
import straightLeft from './straightLeft.svg';
import straightRight from './straightRight.svg';

export default {
  chevronDown,
  chevronLeft,
  chevronRight,
  chevronSmallDown,
  chevronSmallRight,
  chevronSmallUp,
  chevronUp,
  cornerLeftUp,
  straightLeft,
  straightRight,
};
