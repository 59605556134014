import { VueConstructor } from 'vue';

import action from './action';
import ais from './ais';
import arrow from './arrow';
import checkbox from './checkbox';
import circle from './circle';
import eye from './eye';
import fullscreen from './fullscreen';
import logbook, { anchoring, atSeaPack, inPortPack, mooring, soundingSpots, watchHandover } from './logbook';
import logo from './logo';
import formularus from './logo/formularus';
import mimeType from './mimeType';
import notification from './notification';
import person from './person';
import status from './status';
import symbol from './symbol';
import vessel from './vessel';
import wifi from './wifi';

type IconMap<P extends string, I extends string> = Record<`${P}/${I}`, VueConstructor>;

function prefixIcons<P extends string, I extends string>(icons: Record<I, VueConstructor>, prefix: P): IconMap<P, I> {
  return Object.entries(icons).reduce<IconMap<P, I>>(
    (prefixedIcons, [name, icon]) => {
      return { ...prefixedIcons, [`${prefix}/${name}`]: icon };
    },
    {} as IconMap<P, I>,
  );
}

const icons = {
  ...prefixIcons(action, 'action'),
  ...prefixIcons(ais, 'ais'),
  ...prefixIcons(arrow, 'arrow'),
  ...prefixIcons(checkbox, 'checkbox'),
  ...prefixIcons(circle, 'circle'),
  ...prefixIcons(eye, 'eye'),
  ...prefixIcons(fullscreen, 'fullscreen'),
  ...prefixIcons(anchoring, 'logbook/anchoring'),
  ...prefixIcons(atSeaPack, 'logbook/atSea'),
  ...prefixIcons(inPortPack, 'logbook/inPort'),
  ...prefixIcons(mooring, 'logbook/mooring'),
  ...prefixIcons(watchHandover, 'logbook/watchHandover'),
  ...prefixIcons(logbook, 'logbook'),
  ...prefixIcons(mimeType, 'mime-type'),
  ...prefixIcons(formularus, 'logo/formularus'),
  ...prefixIcons(logo, 'logo'),
  ...prefixIcons(notification, 'notification'),
  ...prefixIcons(person, 'person'),
  ...prefixIcons(status, 'status'),
  ...prefixIcons(symbol, 'symbol'),
  ...prefixIcons(wifi, 'wifi'),
  ...prefixIcons(soundingSpots, 'logbook/soundingSpots'),
  ...prefixIcons(vessel, 'vessel'),
};

export default icons;

type IconName = keyof typeof icons;

export { IconName };
