import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/responsibilities',
    name: 'responsibilities',
    component: () => import('#/views/responsibility/Responsibilities.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.RESPONSIBILITY } },
  },
  {
    path: '/responsibilities/new/:responsibilityId?',
    name: 'responsibility-create',
    component: () => import('#/views/responsibility/ResponsibilityCreate.vue'),
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.RESPONSIBILITY } },
  },

  {
    path: '/responsibilities/:responsibilityId',
    name: 'responsibility',
    component: () => import('#/views/responsibility/Responsibility.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.RESPONSIBILITY } },
  },
  {
    path: '/responsibilities/:responsibilityId/edit',
    name: 'responsibility-edit',
    component: () => import('#/views/responsibility/ResponsibilityEdit.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.RESPONSIBILITY } },
  },
];

export default routes;
