import classificationSociety from './classificationSociety.svg';
import docHolder from './docHolder.svg';
import documentOfCompliance from './documentOfCompliance.svg';
import flagState from './flagState.svg';
import internationalShipSecurityCertificate from './internationalShipSecurityCertificate.svg';
import ISMRelevantChecklist from './ISMRelevantChecklist.svg';
import ISPSRelevantChecklist from './ISPSRelevantChecklist.svg';
import logbooksKeptAdditionally from './logbooksKeptAdditionally.svg';
import otherChecklists from './otherChecklists.svg';
import registerBareboatCharterers from './registerBareboatCharterers.svg';
import registeredOwner from './registeredOwner.svg';
import safetyManagementCertificate from './safetyManagementCertificate.svg';

export default {
  classificationSociety,
  docHolder,
  documentOfCompliance,
  flagState,
  internationalShipSecurityCertificate,
  ISMRelevantChecklist,
  ISPSRelevantChecklist,
  logbooksKeptAdditionally,
  otherChecklists,
  registerBareboatCharterers,
  registeredOwner,
  safetyManagementCertificate,
};
