<template>
  <div class="flex gap-4">
    <div class="flex-grow">
      <DetailsListEntry class="mb-0 mt-3" :title="element.label" :content="readModeModel" />
    </div>

    <div
      v-if="linkedProtocol && edit"
      class="flex flex-grow-0 h-10 w-10 bg-primary justify-center items-center self-end rounded cursor-pointer"
    >
      <Icon
        data-test="icon-takeover"
        name="action/takeOver"
        color="white"
        @click.native.stop="showTakeoverDataModal = true"
      />
    </div>

    <div
      v-if="linkedProtocol"
      class="flex flex-grow-0 h-10 w-10 bg-primary justify-center items-center self-end rounded cursor-pointer"
      @click="linkedProtocolModalOpen = true"
    >
      <Icon data-test="icon-eye" name="eye/on" color="white" />
    </div>
    <div
      v-if="edit"
      class="flex flex-grow-0 h-10 w-10 bg-primary justify-center items-center self-end rounded cursor-pointer"
      @click="changeLinkModalOpen = true"
    >
      <Icon data-test="icon-link" name="action/link" color="white" />
    </div>
    <Modal
      v-if="showTakeoverDataModal"
      data-test-id="take-over"
      is-open
      :title="$tc('protocol.take_over_data')"
      :max-width="650"
      @cancel="showTakeoverDataModal = false"
      @confirm="applyTakeOverData"
    />

    <Modal
      v-if="linkedProtocol"
      data-test-id="linked-protocol"
      :is-open="linkedProtocolModalOpen"
      :title="$tc('protocol.linking.linked_protocol', undefined, { field: element.label })"
      :max-width="800"
      view-only
      @cancel="linkedProtocolModalOpen = false"
    >
      <ProtocolDetails :protocol="linkedProtocol" one-column-layout />
    </Modal>
    <Modal
      v-if="edit"
      :is-open="changeLinkModalOpen"
      :title="$tc('protocol.linking.link_protocol', undefined, { field: element.label })"
      :max-width="800"
      view-only
      @cancel="changeLinkModalOpen = false"
    >
      <ProtocolTable
        id="dataTable"
        class="ma-0"
        :protocols="allLinkableProtocols"
        :is-loading="linkableProtocolsLoading"
        :loading-message="$tc('loading', undefined, { entity: $tc('protocol.linking.linkable_protocols') })"
        :search-bar-title="$tc('protocol.linking.choose_linked_protocol')"
        start-with-all-dates
        hide-deleted
        @protocol-clicked="linkProtocol"
      >
        <template #componentBeforeTableStart>
          <div class="flex justify-center items-center h-13.5">
            <button
              v-t="'protocol.proceed_without_link'"
              class="text-primary text-sm font-semibold border-solid border-1 border-primary rounded h-10.5 px-1 py-2.5"
              data-test="button-createWithoutLink"
              @click="linkProtocol(undefined)"
            />
          </div>
        </template>
      </ProtocolTable>
    </Modal>
  </div>
</template>

<script lang="ts">
import { isProtocolLink, logger, Protocol, ProtocolLink, ProtocolLinkElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import Icon from '#/components/Icon.vue';
import Modal from '#/components/Modal.vue';
import ProtocolDetails from '#/components/protocol/ProtocolDetails.vue';
import ProtocolTable from '#/components/protocol/ProtocolTable.vue';
import { loadProtocols } from '#/compositions/useProtocols';
import { getLinkValue } from '#/helpers/ProtocolUtilities';

import { getProtocolLinkValue } from './utils';

export default defineComponent({
  name: 'FormProtocolLink',

  components: {
    DetailsListEntry,
    Modal,
    ProtocolDetails,
    Icon,
    ProtocolTable,
  },

  props: {
    element: {
      type: Object as PropType<ProtocolLinkElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'protocollink:takeover-data': (_linkedProtocol: Protocol) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const model = computed<ProtocolLink | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && !isProtocolLink(v)) {
          logger.error(
            'Value does not fit to protocol link element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    const linkedProtocolModalOpen = ref(false);
    const changeLinkModalOpen = ref(false);
    const showTakeoverDataModal = ref(false);

    const { data: allLinkableProtocols, isLoading: linkableProtocolsLoading } = loadProtocols(
      computed(() => element.value.protocolType),
    );

    const linkedProtocol = computed(() => {
      return allLinkableProtocols.value.find((protocol) => {
        if (!model.value) {
          return;
        }
        return protocol._id === model.value.protocol._id;
      });
    });

    function linkProtocol(selectedProtocol: Protocol | undefined): void {
      changeLinkModalOpen.value = false;
      if (!selectedProtocol) {
        model.value = undefined;
        return;
      }
      model.value = getLinkValue(selectedProtocol, element.value);
    }

    const readModeModel = computed(() => {
      return getProtocolLinkValue(model.value);
    });

    function applyTakeOverData(): void {
      if (linkedProtocol.value !== undefined) {
        emit('protocollink:takeover-data', linkedProtocol.value);
      }
      showTakeoverDataModal.value = false;
    }

    return {
      model,
      linkedProtocol,
      readModeModel,
      linkedProtocolModalOpen,
      changeLinkModalOpen,
      linkableProtocolsLoading,
      linkProtocol,
      allLinkableProtocols,
      applyTakeOverData,
      showTakeoverDataModal,
    };
  },
});
</script>
