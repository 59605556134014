import {
  AnomalyFunction,
  autocalculation,
  AutocalculationFunctionName,
  AutoCompleteElement,
  ComponentElement,
  ObjectElement,
  PositionTransformer,
  Protocol,
  ProtocolContentType,
  ProtocolLink,
  ProtocolLinkConfig,
  ProtocolType,
  Ref,
  Schema,
} from '@anschuetz-elog/common';
import { sum } from 'lodash';
import moment from 'moment';

import useFeathers from '#/compositions/useFeathers';
import i18n from '#/i18n';
import { dateTimeStr } from '#/utilities';

export type CustomValidationFunction = (elementValue: unknown) => { valid: boolean; errorMessage: string };

export function matches(errorMessage: string, regExp: RegExp): CustomValidationFunction {
  return (elementValue) => {
    if (typeof elementValue !== 'string') {
      return { valid: false, errorMessage };
    }
    return { valid: regExp.test(elementValue), errorMessage };
  };
}

export const technicalName = matches(i18n.tc('form.validation.technical_name'), /^[a-zA-Z0-9-_]+$/);

export function not(errorMessage: string, values: unknown[]): CustomValidationFunction {
  return (elementValue) => {
    return { valid: !values.includes(elementValue), errorMessage };
  };
}

export function unique(alreadyUsedValues: unknown[]): CustomValidationFunction {
  return not(i18n.tc('form.validation.unique'), alreadyUsedValues);
}

export function isFormulaValid(schema: Schema): CustomValidationFunction {
  return (elementValue) => {
    if (!elementValue) {
      return {
        valid: true,
        errorMessage: i18n.tc('form.validation.formula.invalid_expression'),
      };
    }
    const result = autocalculation.isFormulaValid(elementValue as string, schema, useFeathers().get('localCache'));
    if (typeof result === 'object') {
      const errorMessages: string[] = [];
      if (result.invalidVariables.length > 0) {
        errorMessages.push(
          i18n.tc('form.validation.formula.invalid_variables', undefined, {
            variables: `"${result.invalidVariables.join('", "')}"`,
          }),
        );
      }
      if (result.invalidFunctions.length > 0) {
        errorMessages.push(
          i18n.tc('form.validation.formula.invalid_functions', undefined, {
            functions: `"${result.invalidFunctions.join('", "')}"`,
          }),
        );
      }
      errorMessages.push(
        ...result.wrongFunctionUsages.map((wrongFunctionUsage) => {
          switch (wrongFunctionUsage.type) {
            case 'args_count':
              return i18n.tc('form.validation.formula.wrong_function_usage.args_count', undefined, wrongFunctionUsage);
            case 'no_variable_list':
              return i18n.tc(
                'form.validation.formula.wrong_function_usage.no_variable_list',
                undefined,
                wrongFunctionUsage,
              );
            case 'no_equipment_item_variable':
              return i18n.tc(
                'form.validation.formula.wrong_function_usage.no_equipment_item_variable',
                undefined,
                wrongFunctionUsage,
              );
            case 'no_equipment_item_prop':
              return i18n.tc(
                'form.validation.formula.wrong_function_usage.no_equipment_item_prop',
                undefined,
                wrongFunctionUsage,
              );
            default:
              return '';
          }
        }),
      );
      return {
        valid: false,
        errorMessage: errorMessages
          .filter((message) => message.trim())
          .map((message) => `- ${message}`)
          .join('\n'),
      };
    }
    return {
      valid: result,
      errorMessage: i18n.tc('form.validation.formula.invalid_expression'),
    };
  };
}

export function isFormulaAtMost(maxValue: string | undefined): CustomValidationFunction {
  return (elementValue) => {
    const errorMessage = i18n.tc('form.validation.max', undefined, {
      max: maxValue,
    });
    try {
      if (!maxValue || !elementValue) {
        return { valid: true, errorMessage };
      }
      const evaluatedMaxValue = autocalculation.execute(maxValue, {});
      const minValue = autocalculation.execute(elementValue as string, {});
      if (typeof evaluatedMaxValue === 'number' && typeof minValue === 'number') {
        return { valid: minValue <= evaluatedMaxValue, errorMessage };
      }
      // in this case one of the formulas could not be executed properly e.g. because variables are missing in the scope.
      // So we can't evaluate the max constraint and therefor assume that this is checked elsewhere and return valid "true"
      return { valid: true, errorMessage };
    } catch {
      // in this case one of the formulas is invalid, so we assume that this is checked elsewhere and return valid "true"
      return { valid: true, errorMessage };
    }
  };
}

export function isFormulaAtLeast(minValue: string | undefined): CustomValidationFunction {
  return (elementValue) => {
    const errorMessage = i18n.tc('form.validation.min', undefined, {
      min: minValue,
    });
    try {
      if (!minValue || !elementValue) {
        return { valid: true, errorMessage };
      }
      const evaluatedMinValue = autocalculation.execute(minValue, {});
      const maxValue = autocalculation.execute(elementValue as string, {});
      if (typeof evaluatedMinValue === 'number' && typeof maxValue === 'number') {
        return { valid: evaluatedMinValue <= maxValue, errorMessage };
      }
      // in this case one of the formulas could not be executed properly e.g. because variables are missing in the scope.
      // So we can't evaluate the min constraint and therefor assume that this is checked elsewhere and return valid "true"
      return { valid: true, errorMessage };
    } catch {
      // in this case one of the formulas is invalid, so we assume that this is checked elsewhere and return valid "true"
      return { valid: true, errorMessage };
    }
  };
}

export function isPresent(allElements: unknown[], type: string): CustomValidationFunction {
  return (elementValue) => ({
    valid: allElements.includes(elementValue),
    errorMessage: i18n.tc('form.validation.type_value_not_present', undefined, { type }),
  });
}

export function refElementWrapper(element: AutoCompleteElement): ObjectElement {
  const { conditional, ...childElementConfig } = element;
  return {
    _id: element._id,
    type: 'object',
    name: element.name,
    label: '',
    children: [
      {
        ...childElementConfig,
        _id: '_id',
        name: '_id',
      },
    ],
    conditional,
  };
}

export function ensureNumber(value: number): number | undefined {
  if (isNaN(value)) {
    return undefined;
  }
  return value;
}

export type ProtocolLinkConfigProp = {
  target: {
    protocolType: Ref<ProtocolType>;
    protocolContentType: Ref<ProtocolContentType>;
    elementId: string;
  };
  source: {
    protocol: Protocol;
    protocolContentType: Ref<ProtocolContentType>;
    elementId: string;
    allowedMultipleLinking?: boolean;
  };
};

export function getLinkConfigProps(
  protocol: Protocol,
  protocolContentTypeId: string,
  links?: ProtocolLinkConfig[],
): ProtocolLinkConfigProp[] {
  return (links || []).map<ProtocolLinkConfigProp>((link) => ({
    target: link.target,
    source: {
      elementId: link.elementId,
      protocol,
      protocolContentType: ProtocolContentType.createRef(protocolContentTypeId),
      allowedMultipleLinking: link.multipleLinkingAllowed,
    },
  }));
}

export function getProtocolLinkValue(protocolLink: ProtocolLink | undefined, isDateTime = true): string {
  if (!protocolLink) {
    return i18n.tc('form.protocol_link.no_link_set');
  }
  if (typeof protocolLink.value !== 'object') {
    if (isDateTime) {
      return dateTimeStr(
        typeof protocolLink.value === 'string' ? protocolLink.value : moment(protocolLink.value).toISOString(),
      );
    }
    return (protocolLink.value || '').toString();
  } else {
    const position = `Pos: ${PositionTransformer.format(protocolLink.value.position as string)}`;
    return protocolLink.value.timestamp ? `${dateTimeStr(protocolLink.value.timestamp)} ${position}` : position;
  }
}

const autocalculationFunctionDescriptions: Record<AutocalculationFunctionName, string> = {
  default: i18n.tc('mathjs.default'),
  sum: i18n.tc('mathjs.sum'),
  equipProp: i18n.tc('mathjs.equipProp'),
  round: i18n.tc('mathjs.round'),
};

export function getFormulaHintsSchema(schema: Schema): ComponentElement[] {
  const availableVariables = autocalculation.getAvailableVariables(schema, useFeathers().get('localCache'));
  const hintsSchema: ComponentElement[] = [
    {
      component: 'p',
      children: i18n.tc('settings.wizard.content_type_form.schema.list_of_variables'),
      class: 'font-bold mb-0',
    },
  ];
  hintsSchema.push(
    ...availableVariables.map((element) => ({
      component: 'p',
      children: `${element.name} - ${element.label}`,
      class: 'italic mb-0 whitespace-pre-wrap',
    })),
  );
  hintsSchema.push({
    component: 'p',
    children: i18n.tc('settings.wizard.content_type_form.schema.list_of_functions'),
    class: 'font-bold mb-0',
  });
  hintsSchema.push(
    ...Object.entries(autocalculationFunctionDescriptions).map(([name, description]) => ({
      component: 'p',
      children: `${name} ${description}`,
      class: 'italic mb-0',
    })),
  );
  return hintsSchema;
}

function getMedian(values: number[]): number {
  values.sort((a, b) => a - b);
  const middleIndex = Math.floor(values.length / 2);

  if (values.length % 2 === 0) {
    return (values[middleIndex - 1] + values[middleIndex]) / 2;
  } else {
    return values[middleIndex];
  }
}

export function applyAnomalyEvaluationFunction(
  evaluationFunction: AnomalyFunction,
  entries: number[],
): number | undefined {
  if (entries.length === 0) {
    return undefined;
  }
  switch (evaluationFunction) {
    case 'min':
      return Math.min(...entries);
    case 'max':
      return Math.max(...entries);
    case 'average':
      return sum(entries) / entries.length;
    case 'median':
      return getMedian(entries);
    default:
      return undefined;
  }
}
