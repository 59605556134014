import { OfflineQueueAction } from '@anschuetz-elog/common';

import { connect } from '@/compositions/useFeathers';
import logger from '@/logger';
import { createFeathersClient } from '#/compositions/useFeathers';

import { Database, getDB, loadDB, OFFLINE_QUEUE_NAME } from './databases';

type OfflineQueueItems = OfflineQueueAction;

export class OfflineQueueActionError extends Error {}

export const offlineDatabase = (): Database<OfflineQueueItems> =>
  getDB<OfflineQueueItems>(OFFLINE_QUEUE_NAME) || loadDB<OfflineQueueItems>(OFFLINE_QUEUE_NAME);

export async function pushOfflineQueue(): Promise<void> {
  logger.info('Pushing changes from offline-queue ...');

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const queue = await offlineDatabase().changes<OfflineQueueAction>({ live: false, include_docs: true });

  const offlineQueueItems = queue.results.filter((row) => row.doc && !row.deleted);

  if (offlineQueueItems.length < 1) {
    logger.info('No changes in offline-queue');
    return;
  }

  const feathers = createFeathersClient();
  connect(feathers);

  try {
    for (const row of offlineQueueItems) {
      // skip empty actions
      if (!row.doc) {
        continue;
      }

      /* eslint-disable @typescript-eslint/no-unused-vars */
      const {
        _attachments: attachments,
        _conflicts: conflicts,
        _rev: rev,
        /* eslint-enable @typescript-eslint/no-unused-vars */
        ...action
      } = row.doc;

      try {
        // await executeOfflineQueueAction(action, feathers);
        await feathers.service('sync').create(action);

        await offlineDatabase().remove(row.doc);
      } catch (error) {
        logger.error('Error at performing offline queue action.', action, error);
        throw new OfflineQueueActionError();
      }
    }
    logger.info('Pushed changes from offline-queue');
  } finally {
    setTimeout(() => {
      feathers.emit('disconnect');
    }, 1000);
  }
}
