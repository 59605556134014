import { Voyage } from '@anschuetz-elog/common';
import moment from 'moment';

import { getLatestObjectsWithHistory } from './history';

export function getValidVoyage(voyages: Voyage[], timestamp: moment.MomentInput): Voyage | null {
  if (voyages.length > 0) {
    // const fullVoyageList = this.voyages.concat(this.voyages[0].history);
    const latestVoyagesWithHistory = getLatestObjectsWithHistory(voyages);
    const fullVoyageList = latestVoyagesWithHistory.map((voyageHistory) => voyageHistory.data);
    const momentTimestamp = moment(timestamp);
    // This checks if the supplied date is in range of start and end date of voyage. granularity is milliseconds

    const validVoyage = fullVoyageList.find(
      (voyage) =>
        voyage.startTime &&
        moment(voyage.startTime).isSameOrBefore(momentTimestamp) &&
        (voyage.endTime === undefined || moment(voyage.endTime).isSameOrAfter(momentTimestamp)),
    );
    if (validVoyage) {
      return validVoyage;
    }
  }
  return null;
}
