import { store as coreStore, dynamicConfig } from '@anschuetz-elog/frontend-core';
import debug from 'debug';
import Vue from 'vue';

import store from '@/store';

function checkBackendIntegrity() {
  if (coreStore.state.websocket.isConnected && !store.state.serviceWorker.updating) {
    void store.dispatch.backendIntegrityCheck.checkBackendIntegrity();
  } else {
    store.commit.backendIntegrityCheck.setChecked(false);
  }
}

export function load(): void {
  if (dynamicConfig('ONLINE_ONLY')) {
    return;
  }

  Vue.use((): void => {
    coreStore.original.watch(
      (state) => [state.websocket.isConnected],
      () => checkBackendIntegrity(),
    );
    store.original.watch(
      (state) => [state.serviceWorker.updating],
      () => checkBackendIntegrity(),
    );

    debug('Backend integrity check registered');
  });
}
