import backendIntegrityCheck, { BackendIntegrityCheckState } from './backendIntegrityCheck';
import offline, { OfflineState } from './offline';
import serviceWorker, { ServiceWorkerState } from './serviceWorker';
import systemStatus, { SystemStatusState } from './systemStatus';

export default {
  systemStatus,
  offline,
  backendIntegrityCheck,
  serviceWorker,
};

export interface ModuleStates {
  offline: OfflineState;
  backendIntegrityCheck: BackendIntegrityCheckState;
  systemStatus: SystemStatusState;
  serviceWorker: ServiceWorkerState;
}
