import jsPDF from 'jspdf';

import { dateTimeStr } from '#/utilities';

import logo from './footer-logo.png';
import { drawMarks, drawSeparationLine } from './helpers';
import * as style from './style';
import { DateSubsection } from './types';

export const generateFootersAndMarks = (
  doc: jsPDF,
  vesselName?: string,
  signingUser?: string,
  dateSubsections?: DateSubsection[],
  gatewayVersion?: string,
): void => {
  let pageCounterForSubsection = 0;
  let currentSubsectionIndex = 0;
  const totalNumberOfPages = doc.getNumberOfPages();
  for (let page = 1; page < totalNumberOfPages + 1; page++) {
    if (dateSubsections) {
      if (page <= dateSubsections[currentSubsectionIndex].endPage) {
        pageCounterForSubsection++;
      } else {
        pageCounterForSubsection = 1;
        currentSubsectionIndex++;
      }
    }
    doc.setPage(page);
    drawMarks(doc);
    drawSeparationLine(doc, {
      y: style.SEPARATION_LINE_FOOTER_Y,
      color: style.SEPARATION_LINE_COLOR,
      skipNewPageCheck: true,
    });
    doc.setFont(style.FOOTER_FONT_TYPE);
    doc.setFontSize(style.FOOTER_FONT_SIZE);
    doc.setTextColor(style.FOOTER_FONT_COLOR);
    let pageCounterText = `page ${doc.getCurrentPageInfo().pageNumber}/${totalNumberOfPages}`;
    if (dateSubsections) {
      pageCounterText = `date: ${dateSubsections[currentSubsectionIndex].date}\npage of date ${pageCounterForSubsection}/${dateSubsections[currentSubsectionIndex].noOfPages}\n${pageCounterText}`;
    }
    doc.text(pageCounterText, style.CONTENT_MARGIN_LEFT + style.TABLE_DATA_WIDTH, style.FOOTER_Y, {
      align: 'right',
    });
    let leftFooterText = `Exported at: ${dateTimeStr()}`;
    if (signingUser) {
      leftFooterText += `\nExported by: ${signingUser}`;
    }
    if (vesselName) {
      leftFooterText += `\n${vesselName}`;
    }
    if (gatewayVersion) {
      leftFooterText += `\neLog version: ${gatewayVersion}`;
    }
    doc.text(leftFooterText, style.CONTENT_MARGIN_LEFT, style.FOOTER_Y);

    const signatureMarginRight = Math.max(...pageCounterText.split('\n').map((line) => doc.getTextWidth(line)));
    addSignatureHolder(doc, signatureMarginRight);
    doc.addImage(
      logo,
      'PNG',
      style.CONTENT_MARGIN_LEFT + (style.TABLE_DATA_WIDTH - style.LOGO_WIDTH) / 2,
      style.LOGO_Y,
      style.LOGO_WIDTH,
      style.LOGO_HEIGHT,
    );
  }
};

const addSignatureHolder = (doc: jsPDF, marginRight: number) => {
  doc.setFont(style.FOOTER_FONT_TYPE);
  doc.setFontSize(style.FOOTER_FONT_SIZE);
  doc.setTextColor(style.FOOTER_FONT_COLOR);
  const signHolderText = 'Signature of master';
  const signatureHolderPositionX = style.CONTENT_MARGIN_LEFT + (style.TABLE_DATA_WIDTH + style.LOGO_WIDTH) / 2 + 3;
  const signatureHolderPositionY = style.FOOTER_Y + 10;
  doc.line(
    signatureHolderPositionX,
    signatureHolderPositionY - 3,
    style.CONTENT_MARGIN_LEFT + style.TABLE_DATA_WIDTH - marginRight,
    signatureHolderPositionY - 3,
  );
  doc.text(signHolderText, signatureHolderPositionX, signatureHolderPositionY);
};
