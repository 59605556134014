<template>
  <div class="relative">
    <v-radio-group v-model="innerValue" :label="title" :disabled="disabled">
      <v-radio
        v-for="(item, index) in items"
        :key="index"
        :on-icon="onIcon"
        :off-icon="offIcon"
        color="primary"
        :label="item.label"
        :value="item.value"
      />
    </v-radio-group>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import { RadioButtonItems, RadioButtonValue } from './RadioButton.types';

export default defineComponent({
  name: 'RadioButton',

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array as PropType<RadioButtonItems>,
      required: true,
    },
    value: {
      type: [String, Number, Boolean] as PropType<RadioButtonValue>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: RadioButtonValue): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const innerValue = computed({
      get() {
        return value.value;
      },
      set(newValue: RadioButtonValue) {
        context.emit('input', newValue);
      },
    });

    const onIcon = computed(
      () =>
        `${offIcon.value} M12 9C10.373 9 9 10.373 9 12C9 13.627 10.373 15 12 15C13.627 15 15 13.627 15 12C15 10.373 13.627 9 12 9Z`,
    );

    const offIcon = computed(
      () =>
        'M12 5C8.141 5 5 8.141 5 12C5 15.859 8.141 19 12 19C15.859 19 19 15.859 19 12C19 8.141 15.859 5 12 5ZM12 17C9.243 17 7 14.757 7 12C7 9.243 9.243 7 12 7C14.757 7 17 9.243 17 12C17 14.757 14.757 17 12 17Z',
    );

    return {
      innerValue,
      onIcon,
      offIcon,
    };
  },
});
</script>
