import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import {
  dynamicConfig,
  i18n,
  registerAdditionalInformationPages,
  registerAdditionalSettingsPages,
} from '@anschuetz-elog/frontend-core';

import { useInstallationWizard } from './compositions/useInstallationWizard';

registerAdditionalSettingsPages((ability) => {
  const isExternalConfigured = !!dynamicConfig('EXTERNAL_CONFIGURATION');
  const canReadSystemSettings = ability.can('read', AUTH_RESOURCES.SYSTEM_SETTINGS);
  const canReadBackup = ability.can('read', AUTH_RESOURCES.BACKUP);
  const canReadLogs = ability.can('read', AUTH_RESOURCES.LOGS);
  const canReadInstallationWizard = ability.can('read', AUTH_RESOURCES.INSTALLATION_WIZARD);
  return {
    before: [
      {
        id: 'settingsButton',
        text: i18n.tc('settings.gateway_config'),
        icon: 'action/gatewayConfiguration',
        hidden: isExternalConfigured || !canReadSystemSettings,
        action: { name: 'settings' },
      },
    ],
    after: [
      {
        id: 'settings-backup',
        text: i18n.tc('backup.title'),
        icon: 'action/backup',
        hidden: isExternalConfigured || !canReadBackup,
        action: { name: 'backup' },
      },
      {
        id: 'settings-logs',
        text: i18n.tc('logs.title'),
        icon: 'action/logs',
        color: 'secondary_light',
        hidden: isExternalConfigured || !canReadLogs,
        action: { name: 'logs' },
      },
      {
        id: 'settings-users-myAccount',
        text: 'My Account',
        icon: 'action/myAccount',
        hidden: isExternalConfigured,
        action: { name: 'settings-user-my-account' },
      },
      {
        id: 'installation-wizard',
        text: i18n.tc('installation_wizard.installation_wizard'),
        icon: 'action/installationWizard',
        hidden: !canReadInstallationWizard,
        action: () => {
          useInstallationWizard().isInstallationWizardActive = true;
          window.scrollTo(0, 0);
        },
      },
    ],
  };
});

registerAdditionalInformationPages(() => ({
  before: [
    {
      id: 'aboutElog',
      text: i18n.tc('about.about'),
      icon: 'logo/raytheonLogo',
      action: { name: 'about' },
    },
    {
      id: 'user-manual',
      text: i18n.tc('user_manual.user_manual'),
      icon: 'action/userManual',
      action: { name: 'user-manual' },
    },
  ],
  after: [
    {
      id: 'healthStatusButton',
      text: i18n.tc('health_status.health_status'),
      icon: 'logbook/healthStatus',
      action: { name: 'health-status' },
    },
  ],
}));
