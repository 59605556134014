import attach from './attach.svg';
import automatedRecording from './automatedRecording.svg';
import backup from './backup.svg';
import book from './book.svg';
import calendar from './calendar.svg';
import contentType from './content-type.svg';
import copy from './copy.svg';
import deleteIcon from './delete.svg';
import details from './details.svg';
import dispatch from './dispatch.svg';
import edit from './edit.svg';
import filter from './filter.svg';
import gatewayConfiguration from './gatewayConfiguration.svg';
import group from './group.svg';
import home from './home.svg';
import info from './info.svg';
import installationWizard from './installationWizard.svg';
import jsonExport from './json-export.svg';
import jsonImport from './json-import.svg';
import link from './link.svg';
import login from './login.svg';
import logout from './logout.svg';
import logs from './logs.svg';
import lweNetwork from './lweNetwork.svg';
import menu from './menu.svg';
import myAccount from './myAccount.svg';
import newBook from './new-book.svg';
import newContentType from './new-content-type.svg';
import newGroup from './new-group.svg';
import newType from './new-type.svg';
import pdfExport from './pdf-export.svg';
import power from './power.svg';
import resetCloud from './resetCloud.svg';
import save from './save.svg';
import saveDraft from './saveDraft.svg';
import search from './search.svg';
import selfCheck from './selfCheck.svg';
import settings from './settings.svg';
import shipNetwork from './shipNetwork.svg';
import systemTime from './systemTime.svg';
import takeOver from './takeover.svg';
import type from './type.svg';
import userManagement from './userManagement.svg';
import userManual from './userManual.svg';
import wizard from './wizard.svg';
import xlsExport from './xls-export.svg';

export default {
  attach,
  automatedRecording,
  backup,
  calendar,
  copy,
  delete: deleteIcon,
  details,
  dispatch,
  edit,
  takeOver,
  filter,
  gatewayConfiguration,
  home,
  info,
  installationWizard,
  link,
  login,
  logout,
  logs,
  lweNetwork,
  menu,
  myAccount,
  'pdf-export': pdfExport,
  power,
  'reset-cloud': resetCloud,
  save,
  saveDraft,
  search,
  'self-check': selfCheck,
  settings,
  shipNetwork,
  systemTime,
  userManagement,
  userManual,
  'xls-export': xlsExport,
  'json-export': jsonExport,
  'json-import': jsonImport,
  wizard,
  'content-type': contentType,
  book,
  group,
  type,
  'new-book': newBook,
  'new-group': newGroup,
  'new-type': newType,
  'new-content-type': newContentType,
};
