import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { utilities } from '@anschuetz-elog/frontend-core';
import { RouteConfig } from 'vue-router';
import { DefaultProps } from 'vue/types/options';

const settingsRoutes: RouteConfig[] = [
  {
    path: 'system',
    name: 'settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.SYSTEM_SETTINGS } },
  },

  // user settings
  {
    path: 'my-account',
    name: 'settings-user-my-account',
    component: () => import('@/views/settings/user/MyAccount.vue'),
    meta: { auth: utilities.AuthenticationMode.AUTHENTICATED_ONLY, externallyConfigurable: true },
  },
  // system settings
  {
    path: 'backup',
    name: 'backup',
    component: () => import('@/views/settings/Backups.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.BACKUP }, externallyConfigurable: true },
  },
  {
    path: 'logs',
    name: 'logs',
    component: () => import('@/views/settings/Logs.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.LOGS } },
  },
  {
    path: 'system/time',
    name: 'settings-system-time',
    component: () => import('@/views/settings/ManualTimeSettings.vue'),
    meta: {
      authorization: { action: 'update', subject: AUTH_RESOURCES.SYSTEM_TIME_SETTINGS },
      externallyConfigurable: true,
    },
  },
  {
    path: 'system',
    name: 'settings-system',
    component: () => import('@/views/settings/SystemSettings.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.SYSTEM_SETTINGS } },
    children: [
      {
        path: 'ais/edit',
        name: 'settings-ais-edit',
        props: (): DefaultProps => ({ edit: true }),
        component: () => import('@/components/systemSettings/AisSettings.vue'),
        meta: {
          authorization: { action: 'update', subject: AUTH_RESOURCES.AIS_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'ais',
        name: 'settings-ais',
        component: () => import('@/components/systemSettings/AisSettings.vue'),
        meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.AIS_SETTINGS }, externallyConfigurable: true },
      },
      {
        path: 'network/edit',
        name: 'settings-network-edit',
        props: (): DefaultProps => ({ edit: true }),
        component: () => import('@/components/systemSettings/NetworkSettings.vue'),
        meta: {
          authorization: { action: 'update', subject: AUTH_RESOURCES.SHIP_NETWORK_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'network',
        name: 'settings-network',
        component: () => import('@/components/systemSettings/NetworkSettings.vue'),
        meta: {
          authorization: { action: 'read', subject: AUTH_RESOURCES.SHIP_NETWORK_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'lwe-network/edit',
        name: 'settings-lwe-network-edit',
        props: (): DefaultProps => ({ edit: true }),
        component: () => import('@/components/systemSettings/LweNetworkSettings.vue'),
        meta: {
          authorization: { action: 'update', subject: AUTH_RESOURCES.LWE_NETWORK_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'lwe-network',
        name: 'settings-lwe-network',
        component: () => import('@/components/systemSettings/LweNetworkSettings.vue'),
        meta: {
          authorization: { action: 'read', subject: AUTH_RESOURCES.LWE_NETWORK_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'automated-recording/edit',
        name: 'settings-automated-recording-edit',
        props: (): DefaultProps => ({ edit: true }),
        component: () => import('@/components/systemSettings/AutomatedRecordingSettings.vue'),
        meta: {
          authorization: { action: 'update', subject: AUTH_RESOURCES.AUTOMATED_RECORDING_SETTINGS },
          externallyConfigurable: true,
        },
      },
      {
        path: 'automated-recording',
        name: 'settings-automated-recording',
        component: () => import('@/components/systemSettings/AutomatedRecordingSettings.vue'),
        meta: {
          authorization: { action: 'read', subject: AUTH_RESOURCES.AUTOMATED_RECORDING_SETTINGS },
          externallyConfigurable: true,
        },
      },
    ],
  },
];

const routes: RouteConfig[] = [
  ...utilities.prefixRoutes('/settings', settingsRoutes),
  {
    path: '/health-status',
    name: 'health-status',
    component: () => import('@/views/settings/healthStatus/HealthStatus.vue'),
    // meta: { authorization: { action: 'read', subject: 'health-status' } },
  },
  {
    path: '/manual',
    name: 'user-manual',
    component: () => import('@/views/UserManual.vue'),
    meta: { auth: { mode: utilities.AuthenticationMode.DONT_CARE }, availableWithoutClientRegistration: true },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/About.vue'),
    meta: { auth: { mode: utilities.AuthenticationMode.DONT_CARE }, availableWithoutClientRegistration: true },
  },
];

export default routes;
