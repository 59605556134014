import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('#/views/equipment-list/EquipmentList.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.EQUIPMENT } },
  },
  {
    path: '/equipment/:equipmentListId',
    name: 'equipment-details',
    component: () => import('#/views/equipment-list/Equipment.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.EQUIPMENT } },
  },
  {
    path: '/equipment/:equipmentListId/new',
    name: 'equipment-item-create',
    component: () => import('#/views/equipment-list/EquipmentItemCreate.vue'),
    props: true,
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.EQUIPMENT } },
  },
  {
    path: '/equipment/:equipmentListId/:equipmentItemId',
    name: 'equipment-item',
    component: () => import('#/views/equipment-list/EquipmentItem.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.EQUIPMENT } },
  },
  {
    path: '/equipment/:equipmentListId/:equipmentItemId/edit',
    name: 'equipment-item-edit',
    component: () => import('#/views/equipment-list/EquipmentItemEdit.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.EQUIPMENT } },
  },
];

export default routes;
