import { onUnmounted, ref, Ref } from 'vue';

/**
 *
 * @param task The task function where the first parameter must be a stop predicate provided by this task manager. This predicate returns true if a running must stop itself and therefor should be checked regularly inside the task.
 * @returns An object containing two properties `startTask` and `taskRunning`. `startTask` is a function to start a new task. `taskRunning` is a flag saying if a task is currently running or not.
 */
export function ensureSingleRunningTask<P extends Array<unknown>, R>(
  task: (stop: () => boolean, ...params: P) => Promise<R>,
): {
  startTask: (...params: P) => Promise<R>;
  taskRunning: Ref<boolean>;
} {
  const currentToken = ref(0);
  const taskRunning = ref(false);
  const nextToken = () => {
    currentToken.value = currentToken.value + 1;
    return currentToken.value;
  };

  // when a component is unmounted we want to stop the current task by simulating a next task
  onUnmounted(nextToken);

  async function startTask(...params: P): Promise<R> {
    const myToken = nextToken();
    taskRunning.value = true;
    const stop = () => myToken !== currentToken.value;
    const result = await task(stop, ...params);
    if (!stop()) {
      taskRunning.value = false;
    }
    return result;
  }
  return { startTask, taskRunning };
}
