import anchor from './anchor.svg';
import anchoring from './anchoring';
import atPort from './atPort.svg';
import atSeaPack from './atSea';
import atSea from './atSea.svg';
import auditLog from './auditLog.svg';
import barge from './barge.svg';
import bellbook from './bellbook.svg';
import book from './book.svg';
import box from './box.svg';
import caution from './caution.svg';
import crew from './crew.svg';
import draftAndStability from './draftAndStability.svg';
import environmental from './environmental.svg';
import equipment from './equipment.svg';
import freeEntry from './freeEntry.svg';
import heading from './heading.svg';
import healthStatus from './healthStatus.svg';
import inPortPack from './inPort';
import inPort from './inPort.svg';
import mooring from './mooring';
import mooringArrival from './mooringArrival.svg';
import mooringDeparture from './mooringDeparture.svg';
import nonBunkerbarge from './nonBunkerbarge.svg';
import order from './order.svg';
import pilotOnBoard from './pilotOnBoard.svg';
import plannedTrack from './plannedTrack.svg';
import position from './position.svg';
import record from './record.svg';
import regulations from './regulations.svg';
import report from './report.svg';
import responsibility from './responsibility.svg';
import safetyRecord from './safetyRecord.svg';
import safetyRecord2 from './safetyRecord2.svg';
import shoreBunkering from './shoreBunkering.svg';
import shoreDeliveries from './shoreDeliveries.svg';
import soundings from './soundings.svg';
import soundingSpots from './soundingSpots';
import speedAndDepth from './speedAndDepth.svg';
import steeringMode from './steeringMode.svg';
import table from './table.svg';
import timeAndDate from './timeAndDate.svg';
import timeZone from './timeZone.svg';
import tug from './tug.svg';
import tugRelease from './tugRelease.svg';
import vesselAndCrew from './vesselAndCrew.svg';
import vesselParticulars from './vesselParticulars.svg';
import voyageStatus from './voyageStatus.svg';
import watchHandover from './watchHandover';
import weather from './weather.svg';

export default {
  anchor,
  atPort,
  atSea,
  auditLog,
  barge,
  bellbook,
  book,
  box,
  caution,
  crew,
  draftAndStability,
  environmental,
  equipment,
  freeEntry,
  heading,
  healthStatus,
  inPort,
  mooringArrival,
  mooringDeparture,
  nonBunkerbarge,
  order,
  pilotOnBoard,
  plannedTrack,
  position,
  record,
  regulations,
  report,
  safetyRecord,
  safetyRecord2,
  shoreBunkering,
  shoreDeliveries,
  soundings,
  speedAndDepth,
  steeringMode,
  table,
  timeAndDate,
  timeZone,
  tug,
  tugRelease,
  vesselAndCrew,
  vesselParticulars,
  voyageStatus,
  weather,
  responsibility,
};

export { anchoring, atSeaPack, inPortPack, mooring, soundingSpots, watchHandover };
