<template>
  <DetailsListEntry v-if="!edit" class="ma-0" :title="element.label" :content="model" />
  <TextField
    v-else
    v-model="model"
    :type="type"
    :data-test="`input-${element.name}`"
    :title="element.label"
    :blank-title="!element.label"
    :placeholder="element.placeholder"
    :mark="invalid"
  />
</template>

<script lang="ts">
import { logger, TimeElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import TextField from '#/components/TextField.vue';

export default defineComponent({
  name: 'FormTime',

  components: {
    DetailsListEntry,
    TextField,
  },

  props: {
    element: {
      type: Object as PropType<TimeElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const type = ref<string>('time');

    const model = computed<string | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'number') {
          logger.error(
            'Value does not fit to text element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return moment.utc(v).format('HH:mm');
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: moment.duration(newElementValue).asMilliseconds() });
      },
    });

    return { model, type };
  },
});
</script>
