export type ErrorName = 'conflict' | 'not_found';

type PouchError = Error & {
  name: ErrorName;
  message: string;
  code: number;
};

function isPouchError(error: unknown): error is PouchError {
  return error instanceof Error && error.constructor.name === 'PouchError';
}

export function ignoreError(error: unknown, errorToIgnore: ErrorName): boolean {
  if (!isPouchError(error)) {
    return false;
  }
  return error.name === errorToIgnore;
}
