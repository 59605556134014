import { Book, FlagState, SystemInfo } from '@anschuetz-elog/common';
import { sortBy } from 'lodash';
import moment from 'moment';
import { computed, Ref, ref } from 'vue';

import i18n from '#/i18n';
import { dateStr } from '#/utilities';

import useGet from './useGet';

type LicenseName = 'FLAG_LIBERIA' | 'NOON_REPORT' | 'WATCH_ORDERS' | Book['technicalName'];
type Licenses = { feature: string; featureLabel: string; validTo: string; valid: boolean }[];

/** get all licenses */
let licenses: Ref<Licenses> | undefined = undefined;

export function getLicenses(): Ref<Licenses> {
  if (!licenses) {
    const { data: systemInfo } = useGet('systemInfo', ref(SystemInfo.FixedId));
    licenses = computed(() => {
      if (!systemInfo.value || !systemInfo.value.gatewayInfo) {
        return [];
      }

      const licenses = systemInfo.value.gatewayInfo.licenses.map((license) => {
        return {
          ...license,
          feature: license.feature.split('.')[1],
          validTo: license.valid
            ? license.validTo
              ? dateStr(license.validTo)
              : i18n.tc('about.valid_forever')
            : i18n.tc('about.license_invalid'),
          // we map validity to expired check here (a license is expired if we have a validTo date and this date is in the past)
          valid: license.valid && !(license.validTo && moment(license.validTo).isBefore()),
        };
      });

      return sortBy(licenses, ['featureLabel']);
    });
  }
  return licenses;
}

const bookLicenseMap: Record<LicenseName, string> = {
  BallastWaterRecordBook: 'BALLAST_WATER_RECORD_BOOK',
  bellBook: 'BELLBOOK',
  BioFoulingRecordBook: 'BIOFOULING_RECORD_BOOK',
  BunkSamp: 'BUNKER_LOGBOOK',
  CRB: 'CARGO_RECORD_BOOK',
  deckLogbook: 'DECK_LOGBOOK',
  ChronometerRateBook: 'CHRONOMETER_RATE_BOOK',
  CompassObservationBook: 'COMPASS_OBSERVATION_BOOK',
  ControlledDrugsRegister: 'DRUGS_REGISTER',
  EngineLogbook: 'ENGINE_LOGBOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FLAG_LIBERIA: 'FLAG_LIBERIA',
  GarbageRecordBookPartI: 'GARBAGE_RECORD_BOOK_I',
  GarbageRecordBookPart2: 'GARBAGE_RECORD_BOOK_II',
  HarbourTowage: 'HARBOUR_TOWAGE',
  KeyRegisterBook: 'KEY_REGISTER_BOOK',
  LiftingAppliancesRecordBook: 'REGISTER_OF_LIFTING_APPLIANCES',
  MedicalLogbook: 'MEDICAL_LOGBOOK',
  MLCInspections: 'MLC_RECORD_BOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  NOON_REPORT: 'NOON_REPORT',
  ODSRecB: 'ODS_RECORD_BOOK',
  OilRecordBookPartI: 'OIL_RECORD_BOOK_I',
  ORBII: 'OIL_RECORD_BOOK_II',
  RadioLogbook: 'RADIO_LOGBOOK',
  RadarLogbook: 'RADAR_LOGBOOK',
  SealRecordBook: 'SEAL_RECORD_BOOK',
  SewageAndGraywaterRecordBook: 'SEWAGE_RECORD_BOOK',
  ShipSecurityLogbook: 'SHIP_SECURITY_LOGBOOK',
  SoundingBookDeck: 'SOUNDING_BOOK',
  SoundingBookEngine: 'SOUNDING_BOOK',
  tanks: 'TANKS',
  VisitorLogbook: 'VISITOR_LOGBOOK',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  WATCH_ORDERS: 'WATCH_ORDERS',
};

export function isLicenseValid(licenseName: LicenseName, licenses: Licenses): boolean {
  return licenses.some((license) => license.valid && license.feature === bookLicenseMap[licenseName]);
}

export function checkLiberiaFlagState(flagStates: Ref<FlagState[]>): {
  invalidFlagStates: Ref<Record<FlagState['_id'], boolean>>;
  someInvalid: Ref<boolean>;
} {
  const licenses = getLicenses();

  function isFlagStateValid(flagState: FlagState) {
    if (isLicenseValid('FLAG_LIBERIA', licenses.value)) {
      return true;
    }
    const isLiberia = /l\s*i\s*b\s*e\s*r\s*i\s*a/i.test(flagState.flagState);
    if (isLiberia) {
      return (
        flagState.ceaseDateOfFlagstateRegistration !== undefined &&
        moment(flagState.ceaseDateOfFlagstateRegistration).isSameOrBefore(undefined, 'date')
      );
    }
    return true;
  }

  const invalidFlagStates = computed(() => {
    return flagStates.value.reduce<Record<FlagState['_id'], boolean>>(
      (dict, flagState) => ({
        ...dict,
        [flagState._id]: !isFlagStateValid(flagState),
      }),
      {},
    );
  });

  const someInvalid = computed(() => {
    return Object.values(invalidFlagStates.value).some((invalid) => invalid);
  });

  return { invalidFlagStates, someInvalid };
}
