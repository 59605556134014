import sizeof from 'object-sizeof';
import { Ref, ref } from 'vue';

type LogEntry = {
  type: 'log' | 'error' | 'warn' | 'info';
  message: unknown[]; // because browserConsole messages can be of different types (string, objects, etc.)
};

const logs = ref<LogEntry[]>([]);
const ONE_KILO_BYTE = 1000;

export default function useBrowserLogs(): {
  initBrowserLogs: () => void;
  logs: Ref<LogEntry[]>;
  downloadLogs: () => void;
} {
  function addLog(logEntry: LogEntry) {
    if (sizeof(logs.value) < ONE_KILO_BYTE) {
      logs.value.push(logEntry);
    } else {
      logs.value = [];
      logs.value.push(logEntry);
    }
  }

  function initBrowserLogs() {
    logs.value = [];
    const browserConsole = ref(console);

    // Override browserConsole methods
    const originalLog = browserConsole.value.log;
    browserConsole.value.log = function (...args: unknown[]) {
      addLog({ type: 'log', message: args });
      originalLog.apply(browserConsole, args);
    };

    const originalError = browserConsole.value.error;
    browserConsole.value.error = function (...args: unknown[]) {
      addLog({ type: 'error', message: args });
      originalError.apply(browserConsole, args);
    };

    const originalWarn = browserConsole.value.warn;
    browserConsole.value.warn = function (...args: unknown[]) {
      addLog({ type: 'warn', message: args });
      originalWarn.apply(browserConsole, args);
    };

    const originalInfo = browserConsole.value.info;
    browserConsole.value.info = function (...args: unknown[]) {
      addLog({ type: 'info', message: args });
      originalInfo.apply(browserConsole, args);
    };
  }

  // Function to download logs
  const downloadLogs = () => {
    const blob = new Blob([JSON.stringify(logs.value, null, 2)], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'browserConsole-logs.txt';
    link.click();
  };

  return {
    initBrowserLogs,
    logs,
    downloadLogs,
  };
}
