import { defineModule } from 'direct-vuex';

import useFeathers from '#/compositions/useFeathers';
import { Debug } from '#/lib';

import { ONLINE_RECONNECT_STRATEGY } from './auth';

const debug = Debug('store:websocket');

export interface WebsocketState {
  isConnected: boolean;
  isAuthenticated: boolean;
}

export default defineModule({
  namespaced: true,

  state: (): WebsocketState => ({
    isConnected: false,
    isAuthenticated: false,
  }),

  getters: {
    isConnected: (state): boolean => state.isConnected,
    isAuthenticated: (state): boolean => state.isAuthenticated,
  },

  mutations: {
    async connect(state): Promise<void> {
      try {
        await useFeathers().reAuthenticate(
          true,
          import.meta.env.VITE_APP_MODE !== 'cloud' ? ONLINE_RECONNECT_STRATEGY : undefined,
        );
      } catch (error) {
        // ignore error as most likely not authenticated
      }
      state.isConnected = true;
    },
    disconnect(state): void {
      state.isConnected = false;
    },
    message(state, message: string): void {
      // log all messages
      debug('[Websocket] <', message);
    },
    setAuthenticated(state, isAuthenticated: boolean): void {
      state.isAuthenticated = isAuthenticated;
    },
  },

  actions: {},
});
