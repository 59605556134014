<template>
  <RadioButton
    v-model="model"
    :title="element.label"
    :disabled="!edit"
    :mark="invalid"
    :items="items"
    :data-test="`radio-${element.name}`"
  />
</template>

<script lang="ts">
import { logger, RadioButtonElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';

import { RadioButtonItems } from '#/components/RadioButton.types';
import RadioButton from '#/components/RadioButton.vue';

export default defineComponent({
  name: 'FormRadioButton',

  components: {
    RadioButton,
  },

  props: {
    element: {
      type: Object as PropType<RadioButtonElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const model = computed<string | number | boolean | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string' && typeof v !== 'number' && typeof v !== 'boolean') {
          logger.error(
            'Value does not fit to radio button element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    const items = computed((): RadioButtonItems => {
      return (element.value.items || []).map((item) => ({
        value: item.value,
        label: item.label,
      }));
    });

    return { model, items };
  },
});
</script>
