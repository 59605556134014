import { Application, Approval, Protocol } from '@anschuetz-elog/common';

import logger from '@/logger';
import store from '@/store';

import { getProtocolIdsByGatewayAmendmentIds, loadUnappliedApprovals, markApprovalFlagsAsApplied } from './sync-meta';

/**
 * Listens on approval created events at the given feathers application and applies them directly.
 *
 * If the app is currently synching no applying is performed because this would lead to too much
 * parallel writes on the protocol pouchdb. The application will be triggered separately after
 * the sync has been completed.
 */
export function registerApprovalMapper(feathers: Application): void {
  feathers.service('approval').on('created', (approval) => {
    if (store.state.offline.isSyncPending) {
      return;
    }
    void applyApproval(approval, feathers);
  });
}

type ApprovalType = keyof Pick<Protocol, 'firstApproved' | 'secondApproved'>;

async function applyApproval(approval: Approval, feathers: Application): Promise<void> {
  const approvalProp: ApprovalType = approval.stage === 1 ? 'firstApproved' : 'secondApproved';
  const gatewayAmendmentIds = approval.protocols.map(({ data: { _id: gatewayAmendmentId } }) => gatewayAmendmentId);
  logger.info('Applying approval %s.', approval._id);
  const protocolIds = await getProtocolIdsByGatewayAmendmentIds(gatewayAmendmentIds);
  await feathers
    .service('protocol')
    .patch(null, { [approvalProp]: true }, { onlineEvent: true, query: { _id: { $in: protocolIds } } });
  if (protocolIds.length < gatewayAmendmentIds.length) {
    logger.warn(
      'Too few protocols loaded for applying approval %s: %s protocols, %s approved objects. Will be tried again at next time.',
      approval._id,
      protocolIds.length,
      gatewayAmendmentIds.length,
    );
  } else {
    await markApprovalFlagsAsApplied(approval);
    logger.info('Approval %s applied.', approval._id);
  }
}

/** Applies all unapplied approvals via the given feathers application */
export async function applyApprovals(feathers: Application): Promise<void> {
  logger.info('Applying approvals...');
  const start = Date.now();
  const approvalIds = await loadUnappliedApprovals();
  logger.info('%s Approvals to apply', approvalIds.length, approvalIds);
  for (const approvalId of approvalIds) {
    const approval = await feathers.service('approval').get(approvalId);
    await applyApproval(approval, feathers);
  }
  logger.info('All approvals applied. Took %sms', Date.now() - start);
}
