<template>
  <Fragment>
    <Box class="flex flex-col" :class="{ crossed: deleted }" title-centered :title="checklistType.name">
      <div fluid class="py-[5px] mb-4">
        <div class="justify-start">
          <Form
            :value="checkpointsData"
            :schema="checkpointSchema"
            :edit="edit"
            class="flex flex-col basis-full flex-grow-0 gap-y-2 px-2"
            @input="updateData($event)"
            @valid="$emit('update:childValidation', $event)"
          />
        </div>
      </div>
    </Box>
  </Fragment>
</template>

<script lang="ts">
import {
  ChecklistType,
  getChecklistSchema,
  Protocol,
  ProtocolContentData,
  ProtocolContentType,
  Schema,
} from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, toRef } from 'vue';

import Form from '#/components/form/Form.vue';
import Box from '#/components/layout/Box.vue';

export default defineComponent({
  name: 'ChecklistContentBox',

  components: {
    Box,
    Form,
  },

  props: {
    checklist: {
      type: Object as PropType<Protocol>,
      required: true,
    },

    checklistType: {
      type: Object as PropType<ChecklistType>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    deleted: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocol': (protocol: Protocol): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:childValidation': (valid: boolean): boolean => true,
  },

  setup(props, { emit }) {
    const checklistType = toRef(props, 'checklistType');
    const checklist = toRef(props, 'checklist');

    const checkpointsData = computed<ProtocolContentData>(
      () => checklist.value.contents.find((content) => content.type._id === checklistType.value._id)?.data || {},
    );

    const checkpointSchema = computed<Schema>(() => {
      return getChecklistSchema(checklistType.value);
    });

    function updateData(newData: ProtocolContentData | undefined) {
      const update: Protocol = {
        ...checklist.value,
        contents: [
          ...checklist.value.contents.filter((content) => content.type._id !== checklistType.value._id),
          {
            type: ProtocolContentType.createRef(checklistType.value._id),
            data: newData || {},
          },
        ],
      };
      emit('update:protocol', update);
    }
    return { checkpointsData, checkpointSchema, updateData };
  },
});
</script>

<style scoped>
.crossed ::v-deep .listContent {
  text-decoration: line-through;
}
</style>
