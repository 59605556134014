import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/crew-list',
    component: () => import('#/views/crew-list/CrewList.vue'),
    // props are required here because this component loads any necessary data
    // for the children and therefor he needs the props of the children
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.CREW_LIST } },
    children: [
      {
        path: '',
        name: 'crew-lists',
        component: () => import('#/views/crew-list/CrewLists.vue'),
      },
      {
        path: 'new',
        name: 'crew-list-new',
        component: () => import('#/views/crew-list/CrewListCreate.vue'),
        props: true,
        meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.CREW_LIST } },
      },
      {
        path: ':crewListId',
        name: 'crew-list-details',
        component: () => import('#/views/crew-list/CrewListDetails.vue'),
        props: true,
      },
      {
        path: ':crewListId/edit',
        name: 'crew-list-edit',
        component: () => import('#/views/crew-list/CrewListEdit.vue'),
        props: true,
        meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.CREW_LIST } },
      },
    ],
  },
];

export default routes;
