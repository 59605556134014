<template>
  <span v-if="typeof details === 'string'">{{ details }}</span>
  <div v-else>
    <template v-for="(detail, detailIndex) in details">
      <span :key="`${detailIndex}-title`" class="font-bold"> {{ detail.contentType }}: </span>
      <LineDetails :key="`${detailIndex}-lines`" :lines="detail.details" line-label-style="font-bold italic" />
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ProtocolContentTypeDetails } from '#/types';

import LineDetails from './LineDetails.vue';

export default defineComponent({
  name: 'ProtocolContentTypeDetails',

  components: { LineDetails },

  props: {
    details: {
      type: [Array, String] as PropType<ProtocolContentTypeDetails[] | string>,
      required: true,
    },
  },
});
</script>
