import { Protocol, VesselParticulars, Voyage } from '@anschuetz-elog/common';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';

import { getDetails } from '#/components/protocol/ProtocolUIConfigDetails';
import { MOMENT_FILE_TIMESTAMP } from '#/config/time';
import { getValidVoyage } from '#/lib/voyages';
import { Line } from '#/types';
import { dateTimeStr, durationStr, getProtocolTypeName } from '#/utilities';

import addFontsToJsPdf from './fonts/addFontsToJsPdf';
import { checkForNewPage, drawSeparationLine, drawTableBorder } from './helpers';
import { generateFootersAndMarks } from './pageFooterAndMarks';
import * as style from './style';
import { VesselInfoForPDFExport, VoyageInfoForPDFExport } from './types';

const OFFSET = 7;
const LABEL_POS = style.CONTENT_MARGIN_LEFT;
const INDENTED_LABEL_POS = LABEL_POS + 5;
const VAL_POS_HEADER = LABEL_POS + 40;
const VAL_POS = LABEL_POS + 82;
const TABLE_POS = INDENTED_LABEL_POS;

function addText(pdfDocument: jsPDF, text: string | number | undefined, x: number, y: number, suffix?: string): number {
  y = checkForNewPage(pdfDocument, y);
  let ensuredText: string;
  pdfDocument.setFont(style.TABLE_DATA_CONTENT_FONT_TYPE);
  if (text === undefined || `${text}`.length === 0) {
    ensuredText = '- not defined -';
    pdfDocument.setFont(style.UNDEFINED_FONT_TYPE);
  } else if (suffix) {
    ensuredText = `${text} ${suffix}`;
  } else {
    ensuredText = `${text}`;
  }
  pdfDocument.text(ensuredText, x, y);
  pdfDocument.setFont(style.TABLE_DATA_CONTENT_FONT_TYPE);
  return y;
}

export function addDetails(pdfDocument: jsPDF, details: Line[], yPosition: number, addInitialOffset = false): number {
  if (addInitialOffset) {
    yPosition += OFFSET;
  }
  details.forEach((detail) => {
    if (Array.isArray(detail.value)) {
      yPosition = checkForNewPage(pdfDocument, yPosition);
      yPosition = addText(pdfDocument, `${detail.label}:`, INDENTED_LABEL_POS, yPosition);
      if (!Array.isArray(detail.value[0].value)) {
        throw new Error('detail.value[0].value is not an array');
      }
      autoTable(pdfDocument, {
        head: [detail.value[0].value.map((item) => item.label)],
        body: detail.value.map((item) => {
          if (!Array.isArray(item.value)) {
            throw new Error('item.value is not an array');
          }
          return item.value.map((subItem) => {
            if (Array.isArray(subItem.value)) {
              throw new Error('subItem.value is an array');
            }
            return subItem.value;
          });
        }),
        didDrawCell: (data) => {
          drawTableBorder(pdfDocument, {
            x: data.cell.x,
            y: data.cell.y,
            width: data.cell.width,
            color: style.TABLE_DATA_HEADER_FONT_COLOR,
          });
          yPosition = data.cell.y + data.row.height;
        },
        headStyles: {
          textColor: style.TABLE_DATA_HEADER_FONT_COLOR,
          fontSize: style.TABLE_DATA_HEADER_FONT_SIZE,
          font: style.TABLE_DATA_HEADER_FONT_TYPE,
        },
        margin: { left: TABLE_POS, bottom: style.TABLE_DATA_MARGIN_BOTTOM },
        tableWidth: style.TABLE_DATA_WIDTH - TABLE_POS,
        theme: 'plain',
        startY: yPosition + OFFSET / 2,
        styles: {
          font: style.TABLE_DATA_CONTENT_FONT_TYPE,
          fontSize: style.TABLE_DATA_CONTENT_FONT_SIZE,
          textColor: style.TABLE_DATA_CONTENT_FONT_COLOR,
        },
      });
      yPosition += OFFSET;
    } else {
      yPosition = addText(pdfDocument, `${detail.label}:`, INDENTED_LABEL_POS, yPosition);
      yPosition = addText(pdfDocument, detail.value, VAL_POS, yPosition) + OFFSET;
    }
  });
  return yPosition;
}

export function protocolPdfExport(
  protocol: Protocol,
  previousProtocol: Protocol | undefined,
  voyages: Voyage[],
  vesselParticulars: VesselParticulars[],
): void {
  let pdfDocument = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });
  pdfDocument = addFontsToJsPdf(pdfDocument);
  const currentDateTime = moment();

  const typeName = getProtocolTypeName(protocol);
  pdfDocument.setFont(style.PAGE_TITLE_FONT_TYPE);
  pdfDocument.setFontSize(style.PAGE_TITLE_FONT_SIZE);
  pdfDocument.setTextColor(style.PAGE_TITLE_FONT_COLOR);
  pdfDocument.setProperties({ title: typeName });
  pdfDocument.text(typeName, style.DOCUMENT_WIDTH / 2, style.DOCUMENT_TITLE_Y, {
    align: 'center',
    baseline: 'middle',
  });
  drawSeparationLine(pdfDocument, {
    y: style.SEPARATION_LINE_PAGE_TITLE_Y,
    color: style.SEPARATION_LINE_TOP_FIRST_PAGE_COLOR,
  });

  pdfDocument.setFont(style.TABLE_DATA_CONTENT_FONT_TYPE);
  pdfDocument.setFontSize(style.TABLE_DATA_CONTENT_FONT_SIZE);
  pdfDocument.setTextColor(style.TABLE_DATA_CONTENT_FONT_COLOR);

  let voyageData: VoyageInfoForPDFExport = {};
  if (voyages.length != 0 && protocol) {
    const validVoyage = getValidVoyage(voyages, protocol.timestamp);
    if (validVoyage) {
      voyageData = {
        voyageNumber: validVoyage.voyageNumber,
        nextPort: validVoyage.portTo,
        previousPort: validVoyage.portFrom,
        eta: validVoyage.estimatedTimeOfArrival ? dateTimeStr(validVoyage.estimatedTimeOfArrival) : undefined,
        atd: validVoyage.startTime ? dateTimeStr(validVoyage.startTime) : undefined,
      };
    }
  }

  let vesselData: VesselInfoForPDFExport = {};
  if (vesselParticulars.length != 0 && protocol) {
    // sort vesselParticulars by createdTimestamp
    vesselParticulars.sort((a: VesselParticulars, b: VesselParticulars) => {
      if (moment(a.createdTimestamp).isBefore(b.createdTimestamp)) {
        return -1;
      }
      if (moment(a.createdTimestamp).isAfter(b.createdTimestamp)) {
        return 1;
      } else {
        return 0;
      }
    });
    // go through sorted array backwards and return first vesselName which is created before the noonReport.timestamp
    for (let i = vesselParticulars.length - 1; i > -1; i--) {
      if (moment(vesselParticulars[i].createdTimestamp).isBefore(protocol.timestamp)) {
        vesselData = {
          vesselName: vesselParticulars[i].vesselName,
          imo: vesselParticulars[i].vesselIMO,
        };
        break;
      }
    }
  }

  let yPosition = style.SEPARATION_LINE_PAGE_TITLE_Y + OFFSET;
  addText(pdfDocument, 'Vessel Name:', LABEL_POS, yPosition);
  yPosition = addText(pdfDocument, vesselData.vesselName, VAL_POS_HEADER, yPosition) + OFFSET;

  addText(pdfDocument, 'Vessel IMO:', LABEL_POS, yPosition);
  yPosition = addText(pdfDocument, vesselData.imo, VAL_POS_HEADER, yPosition) + OFFSET;

  addText(pdfDocument, 'Voyage:', LABEL_POS, yPosition);
  yPosition = addText(pdfDocument, voyageData.voyageNumber, VAL_POS_HEADER, yPosition) + OFFSET;

  addText(pdfDocument, 'Master:', LABEL_POS, yPosition);
  yPosition = addText(pdfDocument, voyageData.master, VAL_POS_HEADER, yPosition) + OFFSET / 2;

  yPosition = drawSeparationLine(pdfDocument, { y: yPosition, color: style.SEPARATION_LINE_COLOR }) + OFFSET;

  addText(pdfDocument, 'Timestamp:', LABEL_POS, yPosition);
  addText(pdfDocument, dateTimeStr(protocol.timestamp), VAL_POS_HEADER, yPosition);

  addText(pdfDocument, 'Last report:', VAL_POS_HEADER + 50, yPosition);
  yPosition =
    addText(
      pdfDocument,
      previousProtocol ? dateTimeStr(previousProtocol.timestamp) : '--',
      VAL_POS_HEADER + 80,
      yPosition,
    ) + OFFSET;

  addText(pdfDocument, 'Covered time:', LABEL_POS, yPosition);
  yPosition =
    addText(
      pdfDocument,
      previousProtocol
        ? durationStr(moment.duration(moment(protocol.timestamp).diff(previousProtocol.timestamp)))
        : '--',
      VAL_POS_HEADER,
      yPosition,
    ) + OFFSET;

  addText(pdfDocument, 'Report ID:', LABEL_POS, yPosition);
  yPosition = addText(pdfDocument, protocol._id, VAL_POS_HEADER, yPosition) + OFFSET;

  addText(pdfDocument, 'Next Port:', LABEL_POS, yPosition);
  addText(pdfDocument, voyageData.nextPort, VAL_POS_HEADER, yPosition);

  addText(pdfDocument, 'ETA:', LABEL_POS + 80, yPosition);
  yPosition = addText(pdfDocument, voyageData.eta, VAL_POS_HEADER + 50, yPosition) + OFFSET;

  addText(pdfDocument, 'Previous Port:', LABEL_POS, yPosition);
  addText(pdfDocument, voyageData.previousPort, VAL_POS_HEADER, yPosition);

  addText(pdfDocument, 'ATD:', LABEL_POS + 80, yPosition);
  yPosition = addText(pdfDocument, voyageData.atd, VAL_POS_HEADER + 50, yPosition) + OFFSET / 2;

  yPosition = drawSeparationLine(pdfDocument, { y: yPosition, color: style.SEPARATION_LINE_COLOR }) + OFFSET;

  const protocolContentTypeDetails = getDetails(protocol);
  if (Array.isArray(protocolContentTypeDetails)) {
    protocolContentTypeDetails.forEach((protocolContentTypeDetail, index) => {
      if (index !== 0) {
        yPosition = drawSeparationLine(pdfDocument, { y: yPosition, color: style.SEPARATION_LINE_COLOR }) + OFFSET;
      }
      yPosition = addText(pdfDocument, `${protocolContentTypeDetail.contentType}:`, LABEL_POS, yPosition) + OFFSET;
      yPosition = addDetails(pdfDocument, protocolContentTypeDetail.details, yPosition);
    });
  } else {
    addText(pdfDocument, protocolContentTypeDetails, LABEL_POS, yPosition);
  }

  generateFootersAndMarks(pdfDocument, vesselData.vesselName);

  pdfDocument.save(`${typeName}_${currentDateTime.format(MOMENT_FILE_TIMESTAMP)}.pdf`);
}
