import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/checklist-types',
    name: 'checklist-types',
    component: () => import('#/views/checklist-type/ChecklistTypes.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.CHECKLIST_TYPE } },
  },
  {
    path: '/checklist-type/new/:checklistTypeId?',
    name: 'checklist-type-create',
    component: () => import('#/views/checklist-type/ChecklistTypeCreate.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.CHECKLIST_TYPE } },
  },
  {
    path: '/checklist-type/:checklistTypeId/edit',
    name: 'checklist-type-edit',
    component: () => import('#/views/checklist-type/ChecklistTypeEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.CHECKLIST_TYPE } },
  },
];

export default routes;
