import { omit } from 'lodash';
import PouchDB from 'pouchdb-browser';
import { v5 as uuidV5 } from 'uuid';

const PAGE_SIZE = 100000000;

type Protocol = {
  _id: string;
  deleted?: boolean;
  predecessor?: { _id: string };
  gatewayAmendmentId: string;
  gatewayRecordId?: string; // this field is now gatewayAmendmentId so we need to delete it
};

function createGatewayAmendmentId(protocol: Protocol): string {
  if (!protocol.deleted && protocol.predecessor) {
    return uuidV5('amendment', protocol._id);
  }
  return protocol._id;
}

/**
 * This migration is there to set the gatewayAmendmentId field for each already synced protocol and report.
 */
export default async function gatewayAmendmentIdMigration(): Promise<void> {
  let skip = 0;
  let lastTotal = 1;
  const protocolDb = new PouchDB<Protocol>('protocol');
  while (lastTotal > skip) {
    const protocols = await protocolDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skip,
    });
    await protocolDb.bulkDocs(
      protocols.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Protocol> => doc !== undefined)
        .map((protocol) => ({
          ...omit(protocol, 'gatewayRecordId'),
          gatewayAmendmentId: createGatewayAmendmentId(protocol),
        })),
    );
    lastTotal = protocols.total_rows;
    skip += PAGE_SIZE;
  }

  let skipReports = 0;
  let lastTotalReports = 1;
  const reportDb = new PouchDB<Protocol>('report');
  while (lastTotalReports > skipReports) {
    const reports = await reportDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skipReports,
    });
    await reportDb.bulkDocs(
      reports.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Protocol> => doc !== undefined)
        .map((report) => ({
          ...omit(report, 'gatewayRecordId'),
          gatewayAmendmentId: createGatewayAmendmentId(report),
        })),
    );
    lastTotalReports = reports.total_rows;
    skipReports += PAGE_SIZE;
  }
}
