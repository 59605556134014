import { pick } from 'lodash';
import { computed, Ref, ref } from 'vue';
import { EmitFn } from 'vue/types/v3-setup-context';

type ValidationInfo = { valid: boolean; params: Record<string, unknown> };
type ValidationDictValue = ValidationInfo | Record<string, ValidationInfo | undefined>;
type ValidationDictionary = Record<string, ValidationDictValue | undefined>;

export default function useValidation(emit?: EmitFn<{ valid: (_valid: boolean) => boolean }>): {
  onValidation: (key: string | [string, string], valid: boolean, params?: Record<string, unknown>) => void;
  isValid: Ref<boolean>;
  resetValidation: (keep?: string[]) => void;
  isElementValid: (key: string | [string, string]) => boolean;
  getParams: (key: string | [string, string]) => Record<string, unknown>;
  validityDictionary: Ref<ValidationDictionary>;
} {
  type ValidationInfo = { valid: boolean; params: Record<string, unknown> };
  type ValidationDictValue = ValidationInfo | Record<string, ValidationInfo | undefined>;
  const validityDictionary = ref<ValidationDictionary>({});

  function isValidationInfo(value: ValidationDictValue): value is ValidationInfo {
    return value.valid !== undefined;
  }

  const isValid = computed(() =>
    Object.values(validityDictionary.value).every((entry) => {
      if (entry === undefined) {
        return true;
      }
      if (!isValidationInfo(entry)) {
        return Object.values(entry).every((subEntry) => subEntry === undefined || subEntry.valid);
      }
      return entry.valid;
    }),
  );

  /**
   *
   * @param key if a string set overall validity flag otherwise set sub validation criteria.
   * This is used e.g. for validating different aspects of an input field
   * @param valid if the overall validity or the single aspect  is valid or not
   * @param params some meta information used to generate validation messages
   */
  function onValidation(key: string | [string, string], valid: boolean, params: Record<string, unknown> = {}): void {
    let dictKey: string;
    const info: ValidationInfo = { valid, params };
    let dictValue: ValidationDictValue;
    if (Array.isArray(key)) {
      dictKey = key[0];
      let oldValue = validityDictionary.value[dictKey];
      if (oldValue === undefined || isValidationInfo(oldValue)) {
        oldValue = {};
      }
      dictValue = { ...oldValue, [key[1]]: info };
    } else {
      dictKey = key;
      dictValue = info;
    }
    validityDictionary.value = { ...validityDictionary.value, [dictKey]: dictValue };
    if (emit) {
      emit('valid', isValid.value);
    }
  }

  function resetValidation(keep: string[] = []): void {
    validityDictionary.value = { ...pick(validityDictionary.value, keep) };
    if (emit) {
      emit('valid', isValid.value);
    }
  }

  /**
   *
   * @param key if a string get overall validity flag otherwise get sub criteria validity flag.
   * When key is string but validation was done with aspects it checks if all aspects of the key are valid.
   */
  function isElementValid(key: string | [string, string]): boolean {
    let dictKey: string;
    if (Array.isArray(key)) {
      dictKey = key[0];
    } else {
      dictKey = key;
    }
    const dictValue = validityDictionary.value[dictKey];
    if (dictValue === undefined) {
      return true;
    }
    if (!isValidationInfo(dictValue)) {
      if (Array.isArray(key)) {
        const info = dictValue[key[1]];
        return info === undefined ? true : info.valid;
      }
      return Object.values(dictValue).every((part) => part === undefined || part.valid);
    }
    return dictValue.valid;
  }

  /**
   *
   * @param key if a string get overall validation message params otherwise get sub criteria params.
   * When key is string but validation was done with aspects it returns empty params.
   */
  function getParams(key: string | [string, string]): Record<string, unknown> {
    let dictKey: string;
    if (Array.isArray(key)) {
      dictKey = key[0];
    } else {
      dictKey = key;
    }
    const dictValue = validityDictionary.value[dictKey];
    if (dictValue === undefined) {
      return {};
    }
    if (!isValidationInfo(dictValue)) {
      if (Array.isArray(key)) {
        const info = dictValue[key[1]];
        return info === undefined ? {} : info.params;
      }
      return {};
    }
    return dictValue.params;
  }

  return {
    onValidation,
    isValid,
    resetValidation,
    isElementValid,
    getParams,
    validityDictionary: computed(() => Object.freeze(validityDictionary.value)),
  };
}
