import PouchDB from 'pouchdb-browser';

/**
 * This migration deletes the pouchDB of the client device db.
 */
export async function clientDeviceDeleteMigration(): Promise<void> {
  const clientDevicePouchDB = new PouchDB('client-device');

  await clientDevicePouchDB.destroy();
}
