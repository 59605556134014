import { utilities } from '@anschuetz-elog/frontend-core';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/setup/client-device',
    name: 'setup-client-device',
    component: () => import('@/views/setup/SetupClientDevice.vue'),
    meta: { auth: utilities.AuthenticationMode.DONT_CARE },
  },
];

export default routes;
