<template>
  <div
    :class="{ 'connection-icon': true, 'is-connected': isConnected, 'is-sync-pending': isSyncPending }"
    data-test="connection-icon"
  >
    <Icon :name="iconName" class="wifi" color="gray_first" />
    <Icon name="wifi/down" sizing="origin" class="wifi-load wifi-down" />
    <Icon name="wifi/up" sizing="origin" class="wifi-load wifi-up" />
  </div>
</template>

<script lang="ts">
import { Icon, IconName } from '@anschuetz-elog/frontend-core';
import { computed, defineComponent, toRef } from 'vue';

export default defineComponent({
  name: 'ConnectionIcon',

  components: { Icon },

  props: {
    isConnected: {
      type: Boolean,
      required: true,
    },

    isSyncPending: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const isConnected = toRef(props, 'isConnected');

    const iconName = computed<IconName>(() => `wifi/${isConnected.value ? 'on' : 'off'}`);

    return { iconName };
  },
});
</script>

<style scoped>
.connection-icon {
  position: relative;
  width: 24px;
  height: 24px;
}

.wifi {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
}

.wifi-up,
.wifi-down {
  display: none;
  position: absolute;
  width: 8px;
  height: 10px;
  bottom: 0px;
}

.wifi-up {
  animation: blink-up 1.25s ease-in-out infinite;
  right: -2px;
}

.wifi-down {
  animation: blink-down 1.25s ease-in-out infinite;
  left: -2px;
}

.connection-icon.is-connected.is-sync-pending .wifi-load {
  display: block;
}

@keyframes blink-up {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@keyframes blink-down {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
</style>
