import { AUTH_RESOURCES, InstallationState } from '@anschuetz-elog/common';
import { store as coreStore, useRoute, useRouter } from '@anschuetz-elog/frontend-core';
import { useStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, getCurrentInstance, Ref } from 'vue';

import useFind from '@/compositions/useFind';
import useFeathers from '#/compositions/useFeathers';

export const useInstallationWizard = defineStore('installation-wizard', () => {
  const vm = getCurrentInstance();
  const isInstallationWizardActive: Ref<boolean> = useStorage('elog::installation-wizard-active', false);

  const route = useRoute();
  const router = useRouter();

  const isAuthenticated = computed(() => {
    return coreStore.getters.auth.isAuthenticated;
  });

  const isConnected = computed(() => {
    return coreStore.getters.websocket.isConnected;
  });

  const { data: installationStates } = useFind(
    'installation-state',
    computed(() =>
      isAuthenticated.value &&
      isConnected.value &&
      vm &&
      vm.proxy.$ability.can('read', AUTH_RESOURCES.INSTALLATION_WIZARD)
        ? {}
        : undefined,
    ),
  );

  const installationState = computed<InstallationState>(
    () =>
      installationStates.value[0] ||
      ({
        addEquipmentItems: false,
        changeDefaultAdmin: false,
        configureCloudConnection: false,
        configureUsers: false,
        setGatewayConfig: false,
        uploadLicenseFiles: false,
        enterVesselParticulars: false,
        addCrewList: false,
        setBookConfigurator: false,
        addChecklists: false,
      } as InstallationState),
  );

  const installationWizardSteps = computed<
    {
      title: string;
      description: string;
      to: { name: string };
      done: boolean;
      installationStateKey: keyof InstallationState;
      subDescriptions?: {
        routeName: string;
        description: string;
        hideDone?: boolean;
      }[];
    }[]
  >(() => [
    {
      title: 'Configure users',
      description: 'Create and configure the personal user accounts for the relevant part of your crew.',
      done: installationState.value.configureUsers,
      installationStateKey: 'configureUsers',
      to: { name: 'settings-users' },
      subDescriptions: [
        {
          routeName: 'settings-users',
          description:
            'Each user need his own username and access. To create a user click on the plus sign at the top of the table. According regulations only personalized and not role-based users are allowed. If you have configured all the users, you can click on "Done".',
        },
        {
          routeName: 'settings-user-new',
          description:
            'The fullname must be entered in the name field. Set an initial password for the user. Configure the roles and book access the user shall have. According regulations only personalized and not role-based users are allowed. When you are done, click on the save icon.',
          hideDone: true,
        },
        {
          routeName: 'settings-user',
          description:
            'User has been created. You can edit the user if you forgot something. If you want to add an additional user, click on the arrow-up icon in the bottom left corner to get back on the users list and repeat the creation steps. If you have configured all the users, you can click on "Done".',
        },
        {
          routeName: 'settings-user-edit',
          description:
            'You can adjust the name, roles and book access of an already existing user here. According regulations only personalized and not role-based users are allowed. When you are done, click on the save icon.',
          hideDone: true,
        },
      ],
    },
    {
      title: 'Change default admin password',
      description: 'The default admin password must be changed to a secure and personal password.',
      done: installationState.value.changeDefaultAdmin,
      installationStateKey: 'changeDefaultAdmin',
      to: { name: 'settings-user-my-account' },
    },
    {
      title: 'Upload license file',
      description: 'Upload the license file provided separately by Anschütz.',
      done: installationState.value.uploadLicenseFiles,
      installationStateKey: 'uploadLicenseFiles',
      to: { name: 'about' },
      subDescriptions: [
        {
          routeName: 'about',
          description:
            'Click on the button with the description "Import license" located in the bottom right corner and upload the license file. The license file is provided separately by Anschütz.',
        },
      ],
    },
    {
      title: 'Set gateway configuration',
      description: 'Configure the gateway according the ship setup referring to service manual.',
      done: installationState.value.setGatewayConfig,
      installationStateKey: 'setGatewayConfig',
      to: { name: 'settings' },
      subDescriptions: [
        {
          routeName: 'settings',
          description:
            'Configure the gateway according the ship setup referring to service manual. Open the separate settings categories for more instructions. "Reset cloud connection" is necessary if you discover any synchronisation issues with the eLog cloud. You can also execute a self check by clicking on "Self check". It will open a new browser tab with the self check results. If you have configured everything, you can click on "Done".',
        },
        {
          routeName: 'settings-network',
          description:
            'Configure the ship network settings according the ship setup referring to service manual by clicking on the edit icon in the bottom right corner. When everything is correct, click on the arrow-up icon in the bottom left corner to get back to the settings overview.',
          hideDone: true,
        },
        {
          routeName: 'settings-network-edit',
          description:
            'Configure the ship network settings according the ship setup referring to service manual. Save it by clicking on the save icon in the bottom right corner.',
          hideDone: true,
        },
        {
          routeName: 'settings-lwe-network',
          description:
            'Configure the LWE network settings according the ship setup referring to service manual by clicking on the edit icon in the bottom right corner. When everything is correct, click on the arrow-up icon in the bottom left corner to get back to the settings overview.',
          hideDone: true,
        },
        {
          routeName: 'settings-lwe-network-edit',
          description:
            'Configure the LWE network settings according the ship setup referring to service manual. Save it by clicking on the save icon in the bottom right corner.',
          hideDone: true,
        },
        {
          routeName: 'settings-ais',
          description:
            'Configure the AIS settings according the ship setup referring to service manual by clicking on the edit icon in the bottom right corner. When everything is correct, click on the arrow-up icon in the bottom left corner to get back to the settings overview.',
          hideDone: true,
        },
        {
          routeName: 'settings-ais-edit',
          description:
            'Configure the AIS settings according the ship setup referring to service manual. Save it by clicking on the save icon in the bottom right corner.',
          hideDone: true,
        },
        {
          routeName: 'settings-automated-recording',
          description:
            'Configure the automated recording settings according the ship setup referring to service manual by clicking on the edit icon in the bottom right corner. When everything is correct, click on the arrow-up icon in the bottom left corner to get back to the settings overview.',
          hideDone: true,
        },
        {
          routeName: 'settings-automated-recording-edit',
          description:
            'Configure the automated recording settings according the ship setup referring to service manual. Save it by clicking on the save icon in the bottom right corner.',
          hideDone: true,
        },
        {
          routeName: 'settings-system-time',
          description:
            'Configure the gateway system time according the ship setup referring to service manual. Save it by clicking on the "update time settings" button in the bottom right corner. If the correct time is set, click on the arrow-up icon in the bottom left corner to get back to the settings overview.',
          hideDone: true,
        },
      ],
    },
    {
      title: 'Add equipment items',
      // TODO: add link
      description: 'Add all the equipment items of the ship referring to user manual chapter 5.5.',
      done: installationState.value.addEquipmentItems,
      installationStateKey: 'addEquipmentItems',
      to: { name: 'equipment' },
      subDescriptions: [
        {
          routeName: 'equipment',
          description:
            'To get the application properly running all the equipment below need to be defined. Open each equipment list to enter the associated equipment items. Referring to user manual chapter 5.5. There may be configuration of a sister vessel or configuration done from shore which can be imported at the end of the page. You can also do an export there for a sister vessel. If every equipment has been added, you can click on "Done".',
        },
        {
          routeName: 'equipment-details',
          description:
            'Each equipment item of the open equipment list must be entered. To create an equipment item click on the plus sign at the top of the table. To see the details of an equipment item, you can click on the corresponding row in the table. If all equipment items have been added, click on the arrow-up icon in the bottom left corner to get back on the equipment lists and go on with the next equipment list.',
          hideDone: true,
        },
        {
          routeName: 'equipment-item-create',
          description:
            'The name of the equipment item and all additional properties need to be entered. Additionally you can deactivate certain input elements of logbook records for the open equipment item by clicking on the blue buttons in the box "Adjustments of content types". When you are done, click on the save icon.',
          hideDone: true,
        },
        {
          routeName: 'equipment-item',
          description:
            'You can edit or delete the equipment item by clicking on the corresponding button in the bottom right corner. If you want to add an additional equipment, click on the arrow-up icon in the bottom left corner to get back on the equipment items list and repeat the creation steps.',
          hideDone: true,
        },
        {
          routeName: 'equipment-item-edit',
          description:
            'You can adjust all information of the equipment item. You can also deactivate or reactivate certain input elements of logbook records for the open equipment item by clicking on the blue buttons in the box "Adjustments of content types". When you are done, click on the save icon.',
          hideDone: true,
        },
      ],
    },
    {
      title: 'Enter vessel particulars',
      description: 'Enter the vessel particulars and additional documentation of your ship.',
      done: installationState.value.enterVesselParticulars,
      installationStateKey: 'enterVesselParticulars',
      to: { name: 'vessel' },
      subDescriptions: [
        {
          routeName: 'vessel',
          description:
            'Enter the vessel particulars and document details accordingly by clicking on the edit icon in the bottom right corner. If all data is correct, you can click on "Done".',
        },
        {
          routeName: 'vessel-edit',
          description:
            'Enter the vessel particulars and document details accordingly. Clicking on the green tile buttons below the vessel details input elements will open dialogs where you can enter additional data. For example, you can upload static documents such as the tank overview for ORB I there. When you are done, click on the save icon.',
          hideDone: true,
        },
      ],
    },
    {
      title: 'Add crew list',
      description: 'Enter the personal data of all the current crew members.',
      done: installationState.value.addCrewList,
      installationStateKey: 'addCrewList',
      to: { name: 'crew-lists' },
      subDescriptions: [
        {
          routeName: 'crew-lists',
          description:
            'There must always be a valid crew list with all crew members and their personal data. There can be multiple crew lists but there can be only one currently active one. To create a new crew list click on the plus sign at the top of the "History" table. If you have configured the crew list, you can click on "Done".',
        },
        {
          routeName: 'crew-list-new',
          description:
            'Enter the personal data of each crew member. To add a new crew member click on the plus sign on top of the crew list table. The fullname must be entered in the name field for each. You can also remove a crew member again by clicking on the bin icon at the end of the corresponding row of the table. Also enter the timestamp for the start of the validity period. The end timestamp must remain empty to show that this crew list is currently active.',
          hideDone: true,
        },
        {
          routeName: 'crew-list-details',
          description:
            'Crew list has been created. You can edit the crew list if you forgot something by clicking on the edit icon in the right bottom corner.',
          hideDone: true,
        },
        {
          routeName: 'crew-list-edit',
          description:
            'You can adjust all the crew list data. Enter the personal data of each crew member. To add a new crew member click on the plus sign on top of the crew list table. The fullname must be entered in the name field for each. You can also remove a crew member again by clicking on the bin icon at the end of the corresponding row of the table. Also enter the timestamp for the start of the validity period. The end timestamp must remain empty to show that this crew list is currently active.',
          hideDone: true,
        },
      ],
    },
    {
      title: 'Book configuration',
      description: 'Configure those books that are relevant for your ship.',
      done: installationState.value.setBookConfigurator,
      installationStateKey: 'setBookConfigurator',
      to: { name: 'settings-book-configuration' },
      subDescriptions: [
        {
          routeName: 'settings-book-configuration',
          description:
            'Deactivate those books that are not used on your ship. Simply click on the blue tiles to deactivate. You can reactivate books later on by clicking on them again.',
        },
      ],
    },
    {
      title: 'Configure checklists',
      description: 'Configure the checklists according your procedures.',
      done: installationState.value.addChecklists,
      installationStateKey: 'addChecklists',
      to: { name: 'checklist-types' },
      subDescriptions: [
        {
          routeName: 'checklist-types',
          description:
            'You can configure all the checklists according your procedures. To create a checklist click on white green-border button "Add new checklist". If you have configured all the checklists, you can click on "Done".',
        },
        {
          routeName: 'checklist-type-create',
          description:
            'The title of the checklist as well as the default logbook for this checklist must be entered. Then you can add as many checkpoints as you need by clicking on "Add new checkpoint". A checkpoint is either a yes/no question or a selection of custom options. You can delete or edit the checkpoints by clicking on the icons next to the according checkpoint. The order of the checkpoints can also be changed via drag and drop. When you are done, click on the save icon.',
          hideDone: true,
        },
        {
          routeName: 'checklist-type-edit',
          description:
            'You can adjust all information of the checklist type. You can add as many checkpoints as you need by clicking on "Add new checkpoint". A checkpoint is either a yes/no question or a selection of custom options. You can delete or edit the checkpoints by clicking on the icons next to the according checkpoint. The order of the checkpoints can also be changed via drag and drop. When you are done, click on the save icon.',
          hideDone: true,
        },
      ],
    },
  ]);

  const activeInstallationWizardStep = computed(() => {
    const activeStep = installationWizardSteps.value.find((step) =>
      route.value.matched.some(
        (m) => m.name === step.to.name || step.subDescriptions?.some(({ routeName }) => m.name === routeName),
      ),
    );
    if (!activeStep) {
      return undefined;
    }
    const subDescription = activeStep.subDescriptions?.find(({ routeName }) =>
      route.value.matched.some((m) => m.name === routeName),
    );
    if (subDescription) {
      return {
        ...activeStep,
        description: subDescription.description,
        hideDone: subDescription.hideDone || false,
      };
    }
    return { ...activeStep, hideDone: false };
  });

  const nextInstallationWizardStep = computed(() => installationWizardSteps.value.find((step) => !step.done));

  async function updateInstallationStep(step: keyof InstallationState, value: boolean): Promise<void> {
    const feathers = useFeathers();
    if (installationState.value._id === undefined) {
      await feathers
        .service('installation-state')
        .create({ ...installationState.value, _id: 'installation-state', [step]: value });
    } else {
      await feathers
        .service('installation-state')
        .patch('installation-state', { ...installationState.value, [step]: value });
    }
  }

  async function finishActiveInstallationWizardStep() {
    if (!activeInstallationWizardStep.value) {
      return;
    }

    await updateInstallationStep(activeInstallationWizardStep.value.installationStateKey, true);

    const nextStep = nextInstallationWizardStep.value;
    if (!nextStep) {
      await router.push({ name: 'home' });
      return;
    }

    await router.push(nextStep.to);
  }

  return {
    isInstallationWizardActive,
    installationWizardSteps,
    installationState,
    activeInstallationWizardStep,
    updateInstallationStep,
    finishActiveInstallationWizardStep,
  };
});
