<template>
  <Box :title="$tc('protocol.files.title')" title-centered>
    <FormEntry>
      <div v-if="protocol.files.length === 0" class="text-center mt-4 text-gray_second">
        {{ $tc('protocol.files.no_files') }}
      </div>
      <div v-for="file in protocol.files" :key="file._id" class="flex flex-row">
        <FileInformation :file-id="file._id" service="record-file" />
        <Icon
          v-if="!readonly"
          name="action/delete"
          color="primary"
          class="cursor-pointer"
          @click.native="removeFile(file._id)"
        />
      </div>
      <Button
        v-if="!readonly"
        class="flex justify-center w-full text-white mt-2"
        :text="$tc('protocol.files.upload_files')"
        @click="openFileUploaderIfConnected"
      >
        {{ $tc('protocol.files.upload_files') }}
      </Button>
    </FormEntry>

    <FileUpload
      v-if="!readonly"
      :title="$tc('protocol.files.upload_dialog.title')"
      :show="openFileUploader"
      service="record-file"
      @files-uploaded="addFiles"
      @close="openFileUploader = false"
    />
  </Box>
</template>

<script lang="ts">
import { Protocol, RecordFile, Ref } from '@anschuetz-elog/common';
import { computed, defineComponent, getCurrentInstance, PropType, ref, toRef } from 'vue';

import Button from '#/components/buttons/Button.vue';
import FileInformation from '#/components/FileInformation.vue';
import FileUpload from '#/components/FileUpload.vue';
import FormEntry from '#/components/FormEntry.vue';
import Icon from '#/components/Icon.vue';
import Box from '#/components/layout/Box.vue';
import i18n from '#/i18n';
import store from '#/store';

export default defineComponent({
  name: 'ProtocolFilesBox',

  components: {
    Box,
    Button,
    FormEntry,
    FileInformation,
    FileUpload,
    Icon,
  },

  props: {
    protocol: {
      type: Object as PropType<Protocol>,
      required: true,
    },

    readonly: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocol': (protocol: Protocol): boolean => true,
  },

  setup(props, context) {
    const protocol = toRef(props, 'protocol');
    const openFileUploader = ref(false);

    const vm = getCurrentInstance();

    const isConnected = computed(() => {
      return store.getters.websocket.isConnected;
    });

    function addFiles(filesToAdd: Ref<RecordFile>[]) {
      const existingFiles = protocol.value.files.map((file) => file._id);
      const files = [...protocol.value.files, ...filesToAdd.filter((file) => !existingFiles.includes(file._id))];
      context.emit('update:protocol', { ...protocol.value, files });
    }

    function removeFile(id: string) {
      const newFiles = protocol.value.files.filter((file) => file._id !== id);
      context.emit('update:protocol', { ...protocol.value, files: newFiles });
    }

    function openFileUploaderIfConnected() {
      if (!vm) {
        return;
      }
      if (!isConnected.value) {
        vm.proxy.$toasted.info(i18n.tc('file_upload.upload_not_possible_offline'));
        return;
      }
      openFileUploader.value = !openFileUploader.value;
    }

    return {
      openFileUploader,
      addFiles,
      removeFile,
      openFileUploaderIfConnected,
    };
  },
});
</script>
