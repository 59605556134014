<template>
  <Box data-test="info-books" :class="{ booksInfo: true }" title-centered :title="$tc('book.shown_in_books')">
    <div class="content white">
      <template v-for="book in booksWithProtocol">
        <div :key="book._id">
          <Checkbox
            v-if="!readonly || book.isProtocolInBook"
            :key="book._id"
            :value="book.isProtocolInBook"
            :class="{ hideCheckbox: readonly }"
            :disabled="
              readonly ||
              (book._id !== selectedBook && isConfidentialBookSelected) ||
              (book.confidential && isNonConfidentialBookSelected) ||
              !book.isLicensed
            "
            :title="book.name"
            :data-test="`checkbox-${book._id}`"
            @input="updateSelectedBooks(book._id, $event)"
          />
          <template v-if="book.isProtocolInBook">
            <FileInformation v-for="doc in book.documentation" :key="doc._id" :file-id="doc._id" service="file" />
          </template>
        </div>
      </template>
      <FormEntry v-if="isConfidentialBookSelected" class="bg-warning_bg border-2 rounded !border-warning p-2 space-y-2">
        <div class="text-warning">
          {{ $tc('book.confidential_book_hint') }}
        </div>
      </FormEntry>
    </div>
  </Box>
</template>

<script lang="ts">
import { Book, Protocol } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import Checkbox from '#/components/Checkbox.vue';
import FileInformation from '#/components/FileInformation.vue';
import FormEntry from '#/components/FormEntry.vue';
import Box from '#/components/layout/Box.vue';
import { getBooksForUser } from '#/helpers/AuthorizedBooksForUser';

export default defineComponent({
  name: 'BooksInfo',
  components: { Box, Checkbox, FileInformation, FormEntry },
  props: {
    protocol: { type: Object as PropType<Protocol>, required: true },
    readonly: { type: Boolean },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocol': (_protocol: Protocol) => true,
  },

  setup(props, { emit }) {
    const protocol = toRef(props, 'protocol');

    const authorizedBooks = getBooksForUser();

    const booksWithProtocol = computed(() => {
      return authorizedBooks.value.map((book) => {
        return {
          ...book,
          isProtocolInBook: protocol.value.books.some((protocolBook) => {
            if (protocolBook._id === book._id) {
              return true;
            }
          }),
        };
      });
    });

    const isConfidentialBookSelected = computed(() => {
      return protocol.value.books.some(
        (selectedBook) => booksWithProtocol.value.find((book) => book._id === selectedBook._id)?.confidential === true,
      );
    });

    const isNonConfidentialBookSelected = computed(() => {
      return protocol.value.books.some(
        (selectedBook) => !booksWithProtocol.value.find((book) => book._id === selectedBook._id)?.confidential,
      );
    });

    const selectedBook = ref<string>('');

    function updateSelectedBooks(bookId: Book['_id'], isSelected: boolean): void {
      const books = protocol.value.books.filter((book) => book._id !== bookId); // get all selected books but not the current one
      // re-add current book if selected
      if (isSelected) {
        selectedBook.value = bookId;
        books.push(Book.createRef(bookId));
      }

      emit('update:protocol', {
        ...protocol.value,
        books,
      });
    }

    return {
      booksWithProtocol,
      updateSelectedBooks,
      selectedBook,
      isNonConfidentialBookSelected,
      isConfidentialBookSelected,
    };
  },
});
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column;
  padding: 16px;
  color: var(--v-gray_first-base);
  font-size: 16px;
}

.hideCheckbox ::v-deep .v-input--selection-controls__input {
  display: none;
}

::v-deep .v-input--selection-controls {
  margin: 4px 0;
}
</style>
