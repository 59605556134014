<template>
  <Box class="flex flex-col" :class="{ crossed: deleted }" title-centered :title="$tc('checklist.remarks')">
    <div fluid class="py-[5px] mb-4">
      <div class="justify-start">
        <Form
          v-model="formValue"
          :schema="schema"
          :edit="edit"
          class="flex flex-col basis-full flex-grow-0 gap-y-2 px-2"
          @valid="$emit('update:childValidation', $event)"
        />
      </div>
    </div>
  </Box>
</template>

<script lang="ts">
import {
  FIXED_PROTOCOL_CONTENT_TYPE_IDS,
  getRemarksSchema,
  Protocol,
  ProtocolContentType,
} from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, toRef } from 'vue';

import Form from '#/components/form/Form.vue';
import Box from '#/components/layout/Box.vue';
import i18n from '#/i18n';

export default defineComponent({
  name: 'RemarksBox',

  components: {
    Box,
    Form,
  },

  props: {
    checklist: {
      type: Object as PropType<Protocol>,
      required: true,
    },
    edit: {
      type: Boolean,
    },

    deleted: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:protocol': (protocol: Protocol): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'update:childValidation': (valid: boolean): boolean => true,
  },

  setup(props, { emit }) {
    const checklist = toRef(props, 'checklist');

    const formValue = computed<Record<string, unknown>>({
      get() {
        return (
          checklist.value.contents.find((content) => content.type._id === FIXED_PROTOCOL_CONTENT_TYPE_IDS.REMARKS)
            ?.data || {}
        );
      },
      set(value) {
        const update: Protocol = {
          ...checklist.value,
          contents: [
            ...checklist.value.contents.filter(
              (content) => content.type._id !== FIXED_PROTOCOL_CONTENT_TYPE_IDS.REMARKS,
            ),
            {
              type: ProtocolContentType.createRef(FIXED_PROTOCOL_CONTENT_TYPE_IDS.REMARKS),
              data: { ...value },
            },
          ],
        };
        emit('update:protocol', update);
      },
    });

    const schema = getRemarksSchema(i18n.tc('checklist.remarks'));

    return { formValue, schema };
  },
});
</script>

<style scoped>
.crossed ::v-deep .listContent {
  text-decoration: line-through;
}
</style>
