<template>
  <Fragment>
    <DetailsListEntry
      v-if="!edit"
      :title="element.label"
      class="!m-0 bg-success_bg rounded-[5px]"
      :content="readModeModel"
    />
    <AutoComplete
      v-else
      v-model="model"
      :title="element.label"
      :placeholder="element.placeholder"
      :mark="invalid"
      :items="items"
      :no-data-text="noItems"
      :class="{ 'v-input:bg-success_bg': !!model }"
      :data-test="`equipmentitem-${element.name}`"
    />
    <Form
      v-if="equipmentListSchema"
      :value="selectedEquipmentItemData"
      :schema="equipmentListSchema"
      class="flex flex-col basis-full flex-grow-0 gap-y-2 mt-2"
    />
  </Fragment>
</template>

<script lang="ts">
import { EquipmentItemElement, logger, Schema } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';

import { AutoCompleteItems } from '#/components/AutoComplete.types';
import AutoComplete from '#/components/AutoComplete.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';
import Form from '#/components/form/Form.vue';
import useFind from '#/compositions/useFind';
import useGet from '#/compositions/useGet';
import i18n from '#/i18n';
import { getLatestObjectsWithHistory } from '#/lib/history';

export default defineComponent({
  name: 'FormEquipmentItem',

  components: {
    AutoComplete,
    DetailsListEntry,
    Form,
  },

  props: {
    element: {
      type: Object as PropType<EquipmentItemElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const model = computed<string | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string') {
          logger.error(
            'Value does not fit to equipment item element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    const { data: equipmentList } = useGet(
      'equipmentList',
      computed(() => element.value.equipmentList),
    );

    const { data: equipmentItems } = useFind(
      'equipmentItem',
      computed(() => ({
        query: { 'list._id': element.value.equipmentList },
      })),
    );

    const equipmentItem = computed(() => {
      return equipmentItems.value.find((equipmentItem) => equipmentItem._id === model.value);
    });

    const relevantEquipmentItems = computed(() => {
      const latest = getLatestObjectsWithHistory(equipmentItems.value)
        .map(({ data }) => data)
        .filter((item) => !item.deleted);
      if (equipmentItem.value) {
        // add selected equipment item if is not in the latest ones
        return [...new Set([...latest, equipmentItem.value])];
      }
      return latest;
    });

    const readModeModel = computed(() => {
      if (model.value && !equipmentItem.value) {
        return i18n.tc('unknown_equipment_item');
      }
      return equipmentItem.value?.name;
    });

    const items = computed((): AutoCompleteItems => {
      return relevantEquipmentItems.value
        .map((equipmentItem) => ({
          value: equipmentItem._id,
          text: equipmentItem.name,
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    });

    const noItems = computed(() => {
      return i18n.tc(`equipment.no_item`, undefined, { EquipmentList: equipmentList.value?.name });
    });

    const selectedEquipmentItemData = computed(() => equipmentItem.value?.itemData || {});

    const equipmentListSchema = computed<Schema | undefined>(() => {
      if (!equipmentList.value || !element.value.showItemData) {
        return undefined;
      }
      return equipmentList.value.itemSchema;
    });

    return {
      model,
      items,
      noItems,
      readModeModel,
      equipmentItems,
      equipmentItem,
      selectedEquipmentItemData,
      equipmentListSchema,
    };
  },
});
</script>
