<template>
  <v-checkbox
    v-model="innerValue"
    v-bind="$attrs"
    hide-details
    dense
    :disabled="disabled"
    :label="title"
    :on-icon="onIcon"
    :off-icon="offIcon"
    :class="{ 'checkbox-background': mark }"
    @click.native.stop
  />
</template>

<script lang="ts">
import { computed, defineComponent, toRef } from 'vue';

export default defineComponent({
  name: 'Checkbox',

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: Boolean,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    mark: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: boolean): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const innerValue = computed<boolean>({
      get() {
        if (value) {
          return value.value;
        }
        return false;
      },
      set(newValue: boolean) {
        context.emit('input', newValue);
      },
    });

    const onIcon = computed(() => {
      return `${offIcon} M10.996 12.556L9.70005 11.285L8.30005 12.715L11.004 15.362L15.703 10.711L14.297 9.289L10.996 12.556Z`;
    });

    const offIcon =
      'M7 5C5.897 5 5 5.897 5 7V17C5 18.103 5.897 19 7 19H17C18.103 19 19 18.103 19 17V7C19 5.897 18.103 5 17 5H7ZM7 17V7H17L17.002 17H7Z';

    return {
      innerValue,
      offIcon,
      onIcon,
    };
  },
});
</script>

<style scoped>
::v-deep.v-input--is-disabled .v-icon svg {
  color: var(--v-gray_third-base) !important;
}

::v-deep:not(.v-input--is-disabled) .v-icon:not(.primary--text) svg {
  color: var(--v-gray_first-base) !important;
}

::v-deep .v-label {
  color: var(--v-gray_first-base) !important;
}

.checkbox-background ::v-deep .v-icon svg {
  background-color: var(--elog-error_bg) !important;
  border-radius: 0.5rem;
}
</style>
