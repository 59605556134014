import logger from '@/logger';

import { clientDeviceDeleteMigration } from './clientDeviceDeleteMigration';
import gatewayAmendmentIdMigration from './gatewayAmendmentIdMigration';
import offlineSyncMetaMigration from './offlineSyncMetaMigration';
import { reportsToProtocolsMigration } from './reportsToProtocolsMigration';

export const OFFLINE_MIGRATIONS_NAME = 'elog:offline-migrations';

const migrations: Map<string, () => Promise<void>> = new Map();
migrations.set('gateway-amendment-id', gatewayAmendmentIdMigration);
migrations.set('offline-sync-meta', offlineSyncMetaMigration);
migrations.set('reports-to-protocols', reportsToProtocolsMigration);
migrations.set('client-device-delete', clientDeviceDeleteMigration);

export default async function executeOfflineMigrations(): Promise<boolean> {
  logger.info(`Start checking for offline migrations...`);

  const executedMigrations = JSON.parse(localStorage.getItem(OFFLINE_MIGRATIONS_NAME) || '[]') as string[];
  const migrationsToExecute = Array.from(migrations.keys()).filter((key) => !executedMigrations.includes(key));

  if (migrationsToExecute.length === 0) {
    logger.info(`No new migrations were found.`);
    return true;
  }
  const start = Date.now();
  logger.info('Migrations to execute: ', migrationsToExecute);
  for (const key of migrationsToExecute) {
    logger.info(`Execute migration ${key}`);
    try {
      const migration = migrations.get(key);
      if (migration) {
        await migration();
      }
      executedMigrations.push(key);
      localStorage.setItem(OFFLINE_MIGRATIONS_NAME, JSON.stringify(executedMigrations));
      logger.info(`Migration ${key} successfully executed`);
    } catch (error) {
      logger.error(`Executing migration ${key} failed with reason: ${error}`, error);
      return false;
    }
  }
  logger.info('Migrations executed. (took %sms)', Date.now() - start);
  return true;
}
