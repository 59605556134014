<template>
  <div v-if="!loadingFile" class="mx-2 my-1 flex flex-row">
    <a class="flex flex-row" :href="downloadLink" target="_blank" @click="onClick($event)">
      <Icon :name="fileIcon" class="w-6 my-auto" />
      <div class="flex flex-col pl-4">
        <span class="text-sm text-primary underline underline-primary">{{ fileName }}</span>
        <span class="text-xs text-gray_first">{{ fileSizeString }}</span>
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { File, RecordFile, ServiceTypes } from '@anschuetz-elog/common';
import Vue, { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from '#/components/Icon.vue';
import useGet from '#/compositions/useGet';
import i18n from '#/i18n';
import { IconName } from '#/icons';
import store from '#/store';
import { download, formatFileSize } from '#/utilities';

export default defineComponent({
  name: 'FileInformation',

  components: {
    Icon,
  },

  props: {
    fileId: {
      type: String as PropType<File['_id'] | RecordFile['_id']>,
      required: true,
    },

    service: {
      type: String as PropType<Extract<keyof ServiceTypes, 'file' | 'record-file'>>,
      required: true,
    },
  },

  setup(props) {
    const fileId = toRef(props, 'fileId');
    const service = toRef(props, 'service');

    const { data: file, isLoading: loadingFile } = useGet(service.value, fileId);

    function isRecordFile(file: File | RecordFile): file is RecordFile {
      return (file as RecordFile).fileSignature !== undefined;
    }

    const fileProperties = computed(() => {
      if (!file.value) {
        return undefined;
      }
      if (isRecordFile(file.value)) {
        return {
          id: file.value._id,
          filename: file.value.filename,
          size: Number(file.value.filesize),
          mimeType: file.value.mimeType,
        };
      }
      return {
        id: file.value._id,
        filename: file.value.filename,
        size: file.value.size,
        mimeType: file.value.mimeType,
      };
    });

    const fileSizeString = computed<string>(() => {
      if (fileProperties.value === undefined) {
        return '';
      }
      return formatFileSize(fileProperties.value.size);
    });

    const fileName = computed<string>(() => fileProperties.value?.filename || '');

    const fileIcon = computed<IconName>(() => {
      const filetype = fileProperties.value?.mimeType || '';

      switch (true) {
        case /json/.test(filetype):
          return 'mime-type/json';
        case /pdf/.test(filetype):
          return 'mime-type/pdf';
        case /spreadsheet|excel/.test(filetype):
          return 'mime-type/xls';
        case /image/.test(filetype):
          return 'mime-type/image';
        case /audio|x-cdf/.test(filetype):
          return 'mime-type/audio';
        case /video/.test(filetype):
          return 'mime-type/video';
        case /html/.test(filetype):
          return 'mime-type/html';
        default:
          return 'mime-type/file';
      }
    });

    const downloadLink = computed(() =>
      fileProperties.value ? `api/v1/rest/${service.value}/${fileProperties.value.id}?download` : '',
    );

    const isConnected = computed<boolean>(() => {
      return store.getters.websocket.isConnected;
    });

    function onClick(event: MouseEvent) {
      if (!isConnected.value) {
        event.preventDefault();
        Vue.toasted.error(i18n.tc('file_upload.not_available_offline'), {
          position: 'bottom-center',
          duration: 3000,
        });
        return;
      }
      void download(downloadLink.value);
      event.preventDefault();
    }

    return {
      loadingFile,
      fileSizeString,
      file,
      fileIcon,
      fileName,
      downloadLink,
      onClick,
    };
  },
});
</script>
