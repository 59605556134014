<template>
  <ProtocolEntry v-if="edit">
    <div>
      <span v-if="title" class="font-600 text-xs text-gray_second mb-1.5">{{ title }}</span>
      <div class="flex space-x-2">
        <LatitudeField
          :value="position.latitude || undefined"
          :mark="invalid"
          data-test="position-latitude"
          @input="latitude = $event"
        />
        <LongitudeField
          :value="position.longitude || undefined"
          :mark="invalid"
          data-test="position-longitude"
          @input="longitude = $event"
        />
      </div>
    </div>
  </ProtocolEntry>
  <DetailsListEntry
    v-else
    :title="title || $tc('protocol.position')"
    :content="readModeDisplayValue"
    :mark-as-unknown="markAsUnknown"
  />
</template>

<script lang="ts">
import { Coordinate, PositionTransformer } from '@anschuetz-elog/common';
import { computed, defineComponent, ref, toRef, watch } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import LatitudeField from '#/components/LatitudeField.vue';
import LongitudeField from '#/components/LongitudeField.vue';
import ProtocolEntry from '#/components/ProtocolEntry.vue';

export default defineComponent({
  name: 'PositionField',
  components: {
    DetailsListEntry,
    ProtocolEntry,
    LatitudeField,
    LongitudeField,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
    },
    markAsUnknown: {
      type: Boolean,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string | undefined): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean): boolean => true,
  },
  setup(props, context) {
    const value = toRef(props, 'value');

    const invalid = ref(false);
    const position = ref(PositionTransformer.parseCoordinates(value.value));
    const lastValue = ref<string | undefined>(value.value);

    watch(value, () => {
      if (value.value !== lastValue.value) {
        position.value = PositionTransformer.parseCoordinates(value.value);
      }
    });

    watch(position, () => {
      const positionString = PositionTransformer.positionToString(position.value);
      invalid.value = positionString === false;
      if (positionString !== false) {
        lastValue.value = positionString;
        context.emit('input', positionString);
      }
      context.emit('invalid', invalid.value);
    });

    const latitude = computed({
      get() {
        return position.value.latitude;
      },
      set(newLatitude: Coordinate | null) {
        position.value = { longitude: position.value.longitude, latitude: newLatitude };
      },
    });

    const longitude = computed({
      get() {
        return position.value.longitude;
      },
      set(newLongitude: Coordinate | null) {
        position.value = { latitude: position.value.latitude, longitude: newLongitude };
      },
    });

    const readModeDisplayValue = computed(() => {
      if (value.value) {
        return PositionTransformer.format(value.value);
      }
      return '';
    });

    return {
      invalid,
      position,
      latitude,
      longitude,
      readModeDisplayValue,
    };
  },
});
</script>
