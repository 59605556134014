import { throttle } from 'lodash';

import store from '#/store';

export function observeUserActivity(): void {
  // use throttle to only update last user activity every second
  const updateUserActionFunction = throttle((): void => {
    if (store.getters.auth.isAuthenticated) {
      void store.dispatch.auth.loadSession();
    }
  }, 10 * 1000);

  window.addEventListener('mousemove', updateUserActionFunction, false);
  window.addEventListener('mousedown', updateUserActionFunction, false);
  window.addEventListener('keypress', updateUserActionFunction, false);
  window.addEventListener('DOMMouseScroll', updateUserActionFunction, false);
  window.addEventListener('mousewheel', updateUserActionFunction, false);
  window.addEventListener('touchmove', updateUserActionFunction, false);
  window.addEventListener('MSPointerMove', updateUserActionFunction, false);
}
