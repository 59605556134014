import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/watch-orders',
    name: 'orders',
    component: () => import('#/views/order/Orders.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.ORDER } },
  },
  {
    path: '/watch-order/new',
    name: 'order-create',
    component: () => import('#/views/order/OrderCreate.vue'),
    props: true,
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.ORDER } },
  },
  {
    path: '/watch-order/:orderId/edit',
    name: 'order-edit',
    component: () => import('#/views/order/OrderEdit.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.ORDER } },
  },
  {
    path: '/watch-order/:orderId/details',
    name: 'order-details',
    component: () => import('#/views/order/OrderDetails.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.ORDER } },
  },
];

export default routes;
