<template>
  <DetailsListEntry v-if="!edit" class="ma-0" :title="element.label" :content="valuesWithUnit" />
  <Form
    v-else
    v-model="data"
    :schema="schema"
    class="grid grid-cols-2 gap-2"
    :edit="edit"
    @valid="$emit('invalid', !$event)"
  />
</template>

<script lang="ts">
import { CombinedFieldElement, logger, Schema, SubElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import Vue, { computed, defineComponent, PropType, toRef } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import i18n from '#/i18n';

import Form from './Form.vue';

export default defineComponent({
  name: 'FormCombinedField',

  components: { DetailsListEntry, Form },

  props: {
    element: {
      type: Object as PropType<CombinedFieldElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const parts = computed(() => element.value.parts);

    const valuesWithUnit = computed(() => {
      if (!data.value) {
        return '';
      }
      const [left, right] = parts.value;
      let leftValue = data.value[left.name];
      let rightValue = data.value[right.name];
      const leftUnit = (left.type === 'number' || left.type === 'autocalculation') && left.unit ? ` ${left.unit}` : '';
      const rightUnit =
        (right.type === 'number' || right.type === 'autocalculation') && right.unit ? ` ${right.unit}` : '';

      if (left.type === 'autocomplete' && left.showItemLabel) {
        leftValue = left.items.find((item) => item.value === leftValue)?.label || leftValue;
      }
      if (right.type === 'autocomplete' && right.showItemLabel) {
        rightValue = right.items.find((item) => item.value === rightValue)?.label || rightValue;
      }
      return `${leftValue || ''}${leftUnit}/${rightValue || ''}${rightUnit}`;
    });

    function getValue(part: SubElement, value?: string): string | number | undefined {
      if (!value) {
        return undefined;
      }
      if (part.type === 'number' && !Number.isNaN(Number(value))) {
        return Number(value);
      }
      return value;
    }

    function parseValue(value?: string | number): string {
      if (typeof value === 'number') {
        return `${value}`;
      }
      if (value?.includes('/')) {
        // replace "/" characters as they are used to divide the two parts
        value = value?.replace('/', '|');
        // notify user
        Vue.toasted.error(i18n.tc('protocol.cant_use_slash'), {
          position: 'bottom-center',
          duration: 3000,
        });
      }
      return value || '';
    }

    const data = computed<Record<string, string | number | undefined> | undefined>({
      get: () => {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string') {
          logger.error(
            'Value does not fit to combined element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }

        if (v === undefined) {
          return undefined;
        }

        const [leftPart, rightPart] = parts.value;
        const [leftValue, rightValue] = v.split('/');

        return {
          [leftPart.name]: getValue(leftPart, leftValue),
          [rightPart.name]: getValue(rightPart, rightValue),
        };
      },
      set: (newData) => {
        if (!newData) {
          newData = {};
        }
        const [leftPart, rightPart] = parts.value;
        const newValue = `${parseValue(newData[leftPart.name])}/${parseValue(newData[rightPart.name])}`;
        const valueToUpdate = newValue === '/' ? undefined : newValue;
        emit('input', { ...value.value, [element.value.name]: valueToUpdate });
      },
    });

    const schema = computed<Schema>(() => {
      let [leftPart, rightPart] = parts.value;
      if (data.value) {
        if (data.value[leftPart.name] && !(rightPart.validation || []).some(([rule]) => rule === 'required')) {
          rightPart = { ...rightPart, validation: [['required'], ...(rightPart.validation || [])] };
        }
        if (data.value[rightPart.name] && !(leftPart.validation || []).some(([rule]) => rule === 'required')) {
          leftPart = { ...leftPart, validation: [['required'], ...(leftPart.validation || [])] };
        }
      }
      return [leftPart, rightPart];
    });

    return { data, schema, valuesWithUnit };
  },
});
</script>
