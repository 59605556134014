<template>
  <Fragment>
    <div class="border-t-8 border-gray_fifth w-full pt-2 font-600 text-gray_second text-xl">
      {{ element.label }}
    </div>
    <table class="w-full border-collapse table-auto white gray_first--text">
      <tr>
        <th class="p-0 text-10px text-gray_second text-left leading-8 first:pl-2 border-b border-t">
          {{ $tc('form.sounding.sounding_spot') }}
        </th>
        <th class="p-0 text-10px text-gray_second text-left leading-8 first:pl-2 border-b border-t">
          {{ $tc('form.sounding.sounding_type') }}
        </th>
        <th class="p-0 text-10px text-gray_second text-left leading-8 first:pl-2 border-b border-t">
          {{ $tc('form.sounding.sounded_value') }}
        </th>
      </tr>
      <tr v-if="soundingSpotsLoading || !manualSounding">
        <td colspan="3" class="text-center pt-2">
          <v-progress-circular class="mx-auto gray_first--text" indeterminate size="32" />
        </td>
      </tr>
      <template v-else-if="manualSounding.length > 0">
        <tr v-for="(sounding, index) of manualSounding" :key="index">
          <td class="w-1 p-1 first:pl-2 leading-8 border-b">{{ sounding.soundingSpot.name }}</td>
          <td class="w-1 p-1 first:pl-2 leading-8 border-b">{{ sounding.soundingSpot.typeOfSounding }}</td>
          <td class="w-2 p-1 first:pl-2 leading-8 border-b">
            <NumberField
              v-if="edit"
              :value="sounding.value"
              :suffix="sounding.soundingSpot.unit"
              @input="setSoundingValue(index, $event)"
              @invalid="onValidation(sounding.soundingSpot._id, !$event)"
            />
            <span v-else>{{ `${sounding.value} ${sounding.soundingSpot.unit}` }}</span>
          </td>
        </tr>
      </template>
      <tr v-else>
        <td colspan="3" class="ml-2 text-center text-gray_first">
          {{ edit ? $tc('form.sounding.no_sounding_spots') : $tc('form.sounding.no_soundings') }}
        </td>
      </tr>
    </table>
  </Fragment>
</template>

<script lang="ts">
import { ManualSounding, ManualSoundingItem, SoundingElement, SoundingSpot } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, ref, toRef, watch } from 'vue';

import NumberField from '#/components/NumberField.vue';
import useFind from '#/compositions/useFind';
import useValidation from '#/compositions/useValidation';
import logger from '#/logger';

export default defineComponent({
  name: 'FormSounding',

  components: { NumberField },

  props: {
    element: {
      type: Object as PropType<SoundingElement>,
      required: true,
    },
    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    invalid: {
      type: Boolean,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean) => true,
  },
  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const edit = toRef(props, 'edit');
    const { onValidation } = useValidation(emit);

    const data = computed<ManualSounding | undefined>({
      get: () => {
        const v = value.value[element.value.name];
        if (v !== undefined && (!Array.isArray(v) || !v.every((e) => typeof e === 'object'))) {
          logger.error(
            'Value does not fit to sounding element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set: (newData) => {
        if (!newData) {
          newData = [];
        }
        emit('input', { ...value.value, [element.value.name]: newData });
      },
    });

    const soundingSpots = ref<SoundingSpot[]>();

    const soundingSpotQuery = computed(() => {
      if (soundingSpots.value) {
        return undefined;
      }
      const baseQuery = { location: element.value.location };
      if (data.value === undefined) {
        return { query: { ...baseQuery, successor: { $exists: false } } };
      }
      return {
        query: {
          ...baseQuery,
          _id: { $in: data.value.map(({ soundingSpot }) => soundingSpot._id) },
          $disableSoftDelete: true,
        },
      };
    });

    const {
      data: loadedSoundingSpots,
      isLoading: soundingSpotsLoading,
      unload: unloadSoundingSpots,
    } = useFind('sounding-spot', soundingSpotQuery);

    watch([soundingSpotsLoading], () => {
      if (!soundingSpotsLoading.value && !soundingSpots.value) {
        soundingSpots.value = loadedSoundingSpots.value;
        unloadSoundingSpots();
      }
    });

    type ManualSoundingItemViewModel = {
      soundingSpot: SoundingSpot;
      value: number | undefined;
    };

    const manualSounding = computed<ManualSoundingItemViewModel[] | undefined>({
      get() {
        if (!soundingSpots.value) {
          return undefined;
        }
        if (data.value === undefined && edit.value) {
          return soundingSpots.value
            .map((soundingSpot) => ({
              soundingSpot,
              value: undefined,
            }))
            .sort((sounding1, sounding2) => sounding1.soundingSpot.name.localeCompare(sounding2.soundingSpot.name));
        }
        const spots = soundingSpots.value;
        return (data.value || [])
          .map((sounding) => ({
            soundingSpot: spots.find(({ _id: spotId }) => spotId === sounding.soundingSpot._id),
            value: sounding.value,
          }))
          .filter((sounding): sounding is ManualSoundingItemViewModel => sounding.soundingSpot !== undefined)
          .filter((sounding) => edit.value || sounding.value !== undefined);
      },
      set(newManualSounding) {
        if (newManualSounding === undefined) {
          return;
        }
        data.value = newManualSounding
          .map((sounding) => ({
            soundingSpot: sounding.soundingSpot ? SoundingSpot.createRef(sounding.soundingSpot._id) : undefined,
            value: sounding.value,
          }))
          .filter((sounding): sounding is ManualSoundingItem => sounding.soundingSpot !== undefined);
      },
    });

    function setSoundingValue(index: number, newValue?: string | number): void {
      if (manualSounding.value === undefined) {
        return;
      }
      manualSounding.value = [
        ...manualSounding.value.slice(0, index),
        { ...manualSounding.value[index], value: newValue as number },
        ...manualSounding.value.slice(index + 1),
      ];
    }

    return { manualSounding, onValidation, soundingSpotsLoading, setSoundingValue, soundingSpotQuery, soundingSpots };
  },
});
</script>
