<template>
  <div>
    <ProtocolHeading>{{ heading }}</ProtocolHeading>
    <ProtocolContainer :one-column-layout="oneColumnLayout">
      <template #aside-start>
        <AutomaticEntriesContentBox
          :class="{ crossed: protocol.deleted }"
          :author="protocol.author._id"
          :created-timestamp="protocol.createdTimestamp"
          :timestamp="protocol.timestamp"
          :position="protocol.position"
          :sog-cog.sync="protocol.sogCog"
          :reason-for-change="protocol.reasonForChange"
          :no-reason-for-change="!protocol.predecessor"
        />
      </template>
      <template #aside-end>
        <ProtocolFilesBox v-if="!protocolType?.isReport" :protocol="protocol" readonly />
        <BooksInfo v-if="!protocolType?.isReport" :protocol="protocol" readonly />
        <TimeZoneContentBox
          v-if="protocolType?.showPassedTimeZones"
          :class="{ crossed: protocol.deleted }"
          :report="protocol"
        />
        <NoonReportDispatchBox v-if="protocolType?.showDispatchBox" :noon-report-id="protocol._id" />
        <HistoryInfo :protocol="protocol" readonly />
      </template>
      <template v-if="isGenericType">
        <GenericProtocolContent :protocol="protocol" :deleted="protocol.deleted" type="entries" />
        <GenericProtocolContent :protocol="protocol" :deleted="protocol.deleted" type="extensions" />
      </template>
      <template v-else-if="isEncryptedProtocol">
        <Box class="flex items-center" title-centered :title="$tc('encrypted_content')">
          <span class="m-2">{{ $tc('encrypted_protocol_no_access') }}</span>
        </Box>
      </template>
      <template v-else-if="checklistType">
        <ChecklistContentBox :checklist="protocol" :deleted="protocol.deleted" :checklist-type="checklistType" />
      </template>
      <template v-else-if="protocolType">
        <ProtocolContentBox
          v-for="contentType in protocolType.contentTypes"
          :key="contentType.ref._id"
          :protocol="protocol"
          :deleted="protocol.deleted"
          :content-type-config="contentType"
          mark-unknown-properties
        />
      </template>
      <template v-if="checklistType">
        <RemarksBox :checklist="protocol" />
      </template>
    </ProtocolContainer>
  </div>
</template>

<script lang="ts">
import { FIXED_PROTOCOL_TYPE_IDS, Protocol } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, toRef } from 'vue';

import BooksInfo from '#/components/BooksInfo.vue';
import Box from '#/components/layout/Box.vue';
import AutomaticEntriesContentBox from '#/components/protocol/AutomaticEntriesContentBox.vue';
import ChecklistContentBox from '#/components/protocol/ChecklistContentBox.vue';
import GenericProtocolContent from '#/components/protocol/GenericProtocolContent.vue';
import HistoryInfo from '#/components/protocol/HistoryInfo.vue';
import ProtocolContainer from '#/components/protocol/ProtocolContainer.vue';
import ProtocolContentBox from '#/components/protocol/ProtocolContentBox.vue';
import ProtocolFilesBox from '#/components/protocol/ProtocolFilesBox.vue';
import ProtocolHeading from '#/components/protocol/ProtocolHeading.vue';
import RemarksBox from '#/components/protocol/RemarksBox.vue';
import NoonReportDispatchBox from '#/components/report/noon-report/NoonReportDispatchBox.vue';
import TimeZoneContentBox from '#/components/report/noon-report/TimeZoneContentBox.vue';
import useGet from '#/compositions/useGet';
import { isProtocolEncrypted } from '#/helpers/ProtocolUtilities';
import i18n from '#/i18n';

export default defineComponent({
  name: 'ProtocolDetails',

  components: {
    AutomaticEntriesContentBox,
    ChecklistContentBox,
    BooksInfo,
    ProtocolContentBox,
    ProtocolContainer,
    HistoryInfo,
    ProtocolHeading,
    GenericProtocolContent,
    ProtocolFilesBox,
    RemarksBox,
    TimeZoneContentBox,
    Box,
    NoonReportDispatchBox,
  },

  props: {
    protocol: {
      type: Object as PropType<Protocol>,
      required: true,
    },

    oneColumnLayout: {
      type: Boolean,
    },
  },

  setup(props) {
    const protocol = toRef(props, 'protocol');

    const isEncryptedProtocol = computed(() => isProtocolEncrypted(protocol.value));

    const isGenericType = computed(() =>
      '_id' in protocol.value.type ? protocol.value.type._id === FIXED_PROTOCOL_TYPE_IDS.GENERIC : false,
    );

    const { data: protocolType } = useGet(
      'protocolType',
      computed(() =>
        isGenericType.value ? undefined : '_id' in protocol.value.type ? protocol.value.type._id : undefined,
      ),
    );

    const { data: checklistType } = useGet(
      'checklistType',
      computed(() => (!('_id' in protocol.value.type) ? protocol.value.type.ref._id : undefined)),
    );

    const heading = computed(() =>
      isGenericType.value ? i18n.tc('protocol.generic_protocol.title') : protocolType.value?.name || '',
    );

    return { protocolType, heading, isGenericType, checklistType, isEncryptedProtocol };
  },
});
</script>

<style scoped>
.crossed ::v-deep .listContent {
  text-decoration: line-through;
}
</style>
