import { AbstractEntity } from '@anschuetz-elog/common';
import { FeathersService, Paginated, Params, Service } from '@feathersjs/feathers';

const PAGINATION_SIZE = 20;

export async function find<T extends AbstractEntity>(
  service: FeathersService<unknown, Service<T>>,
  params?: Params,
): Promise<T[]>;
export async function find<T extends AbstractEntity>(
  service: FeathersService<unknown, Service<T>>,
  params?: Params,
  onDataReceived?: (items: T[]) => void,
  stop?: () => boolean,
): Promise<T[] | null>;
export async function find<T extends AbstractEntity>(
  service: FeathersService<unknown, Service<T>>,
  params?: Params,
  onDataReceived?: (items: T[]) => void,
  stop: () => boolean = () => false,
): Promise<T[] | null> {
  let loadedItemsChunk: Paginated<T> | null = null;
  let skip = 0;
  let items: T[] = [];
  if (onDataReceived) {
    onDataReceived(items);
  }
  while (loadedItemsChunk === null || loadedItemsChunk.total > skip) {
    if (stop()) {
      return null;
    }
    const result = (await service.find({
      ...params,
      query: { $limit: PAGINATION_SIZE, $skip: skip, ...(params?.query || {}) },
    })) as unknown as Paginated<T> | T[];
    if (Array.isArray(result)) {
      loadedItemsChunk = { data: result, total: 0, limit: PAGINATION_SIZE, skip: 0 };
    } else {
      loadedItemsChunk = result;
    }
    items = loadedItemsChunk.data.reduce((items, item) => {
      const previousItems = items.filter((_item) => _item._id !== item._id);
      return [...previousItems, item];
    }, items);
    if (onDataReceived) {
      onDataReceived([...items]);
    }
    if (typeof loadedItemsChunk.skip === 'string') {
      skip = loadedItemsChunk.skip;
    } else {
      skip += PAGINATION_SIZE;
    }
  }
  return items;
}
