import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { Route, RouteConfig } from 'vue-router';
import { DefaultProps } from 'vue/types/options';

import { applyAuthorization, prefixRoutes } from '#/utilities';

import newProtocolRoutes from './newProtocol';

const protocolRoutes: RouteConfig[] = [
  {
    path: '',
    component: () => import('#/views/protocol/BookGuard.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
    children: [
      {
        path: '',
        name: 'protocols',
        component: () => import('#/views/protocol/Protocols.vue'),
        props: true,
        meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      {
        path: 'approval/first',
        name: 'protocols-approval-first',
        component: () => import('#/views/protocol/ProtocolsApproval.vue'),
        props: (route: Route): DefaultProps => ({ ...route.params, approvalStage: 1 }),
        meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.FIRST_APPROVAL } },
      },
      {
        path: 'approval/second',
        name: 'protocols-approval-second',
        component: () => import('#/views/protocol/ProtocolsApproval.vue'),
        props: (route: Route): DefaultProps => ({ ...route.params, approvalStage: 2 }),
        meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.SECOND_APPROVAL } },
      },
      {
        path: 'new',
        name: 'protocol-new',
        component: () => import('#/views/protocol/ProtocolTypeSelector.vue'),
        props: true,
        meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      {
        path: ':protocolId',
        name: 'protocol',
        component: () => import('#/views/protocol/ProtocolDetails.vue'),
        props: true,
        meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      {
        path: ':protocolId/edit',
        name: 'protocol-edit',
        component: () => import('#/views/protocol/ProtocolEdit.vue'),
        props: true,
        meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      {
        path: ':protocolId/delete',
        name: 'protocol-delete',
        component: () => import('#/views/protocol/ProtocolDelete.vue'),
        props: true,
        meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      ...prefixRoutes(
        'new',
        applyAuthorization({ action: 'create', subject: AUTH_RESOURCES.PROTOCOL }, newProtocolRoutes),
      ),
    ],
  },
];

export default prefixRoutes('/book/:bookId/protocol', protocolRoutes);
