<template>
  <div :class="['textField', mark ? 'marked' : '']">
    <span v-if="title" class="titleWrapper" :title="titleHover">{{ title }}</span>
    <div v-else-if="blankTitle" class="blankTitleWrapper" />
    <div class="d-flex">
      <v-text-field
        v-if="!multiline || password || (type !== 'text' && type !== 'time')"
        v-model="innerValue"
        v-bind="$attrs"
        class="textInput"
        autocomplete="off"
        :placeholder="placeholder"
        hide-details
        color="gray_first"
        outlined
        dense
        no-resize
        :type="password ? 'password' : type"
      >
        <template #prepend-inner>
          <Icon v-if="markAsUnknown" class="unknownPropIcon" name="symbol/warningMarker" color="warning" />
        </template>
      </v-text-field>
      <v-textarea
        v-else
        v-model="innerValue"
        v-bind="$attrs"
        class="textInput"
        autocomplete="off"
        :height="height"
        :placeholder="placeholder"
        :rows="3"
        hide-details
        color="gray_first"
        outlined
        dense
        no-resize
        :type="type"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRef } from 'vue';

import Icon from './Icon.vue';

export default defineComponent({
  name: 'TextField',

  components: { Icon },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: '',
    },
    titleHover: {
      type: String,
      default: undefined,
    },
    blankTitle: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
    },
    password: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'text',
    },
    mark: {
      type: Boolean,
    },
    markAsUnknown: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const innerValue = computed<string>({
      get() {
        return value.value;
      },
      set(val: string) {
        context.emit('input', val);
      },
    });

    return { innerValue };
  },
});
</script>

<style scoped>
.titleWrapper {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  text-align: left;
}

.blankTitleWrapper {
  height: 24px;
}

.textField .textInput {
  font-size: 16px;
  background: var(--v-white-base);
}

.textField >>> input {
  padding-left: 12px;
  padding-right: 12px;
}

.textField >>> textarea {
  padding-left: 12px;
}

.textField.marked .textInput {
  background-color: var(--v-error_bg-base);
}

.textField >>> .v-text-field__suffix {
  padding-right: 8px;
}

.unknownPropIcon {
  width: 24px;
  margin-right: 4px;
}
</style>
