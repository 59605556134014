<template>
  <Fragment>
    <div class="border-t-8 border-gray_fifth w-full pt-2 font-600 text-gray_second text-xl">
      {{ element.label }}
    </div>
    <draggable v-model="forms" handle=".draggable-btn">
      <div
        v-for="(item, index) of forms"
        :key="index"
        class="flex flex-col pb-2"
        :data-test="`${element.name}-${index}`"
      >
        <div class="w-full mt-2 font-300 text-gray_second text-m">
          {{
            $tc('form.group.element_label', undefined, {
              label: element.repeatableLabel,
              position: index + 1,
            })
          }}
        </div>
        <div class="flex items-start">
          <Icon
            v-if="edit && element.allowSorting && (data || []).length > 1"
            name="symbol/reOrder"
            color="primary"
            class="mt-8 mx-2 draggable-btn hover:cursor-move"
          />
          <Form
            :value="item.data"
            :schema="item.schema"
            :edit="edit"
            :parent-schema="schema"
            :parent-value="value"
            :peer-dependency-schema="peerDependencySchema"
            :peer-dependency-value="peerDependencyValue"
            class="content flex-grow"
            :class="{ 'grid grid-cols-2 gap-2': item.schema.length > 1 }"
            @input="updateItem(index, $event)"
            @valid="onValidation(`${index}`, $event)"
          />
          <Icon
            v-if="edit"
            name="action/delete"
            color="primary"
            class="mt-8 mx-2 cursor-pointer"
            @click.native="removeItem(index)"
          />
        </div>
      </div>
    </draggable>
    <div v-if="element.repeatable" class="border-b-8 border-gray_fifth">
      <div v-if="(data || []).length === 0">{{ noItemsLabel }}</div>
      <Button
        v-if="edit && element.allowSorting && (data || []).length > 1"
        class="w-full flex justify-center mt-2 mb-2 bg-white"
        outlined
        :hidden="(data || []).length <= 1"
        @click="reverseOrder"
      >
        <span>{{ $t('form.group.reverse_order') }}</span>
      </Button>
      <Button
        v-if="edit"
        class="w-full flex justify-center mt-2 mb-2 bg-white"
        :data-test="`btn-add-${element.name}`"
        outlined
        @click="addItem"
      >
        <Icon name="circle/plus" color="primary" />
        <span class="ml-2">{{ addLabel }}</span>
      </Button>
    </div>
  </Fragment>
</template>

<script lang="ts">
import { GroupElement, Schema, SubElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';
import draggable from 'vuedraggable';

import Button from '#/components/buttons/Button.vue';
import Icon from '#/components/Icon.vue';
import useValidation from '#/compositions/useValidation';
import i18n from '#/i18n';
import logger from '#/logger';

import Form from './Form.vue';

export default defineComponent({
  name: 'FormGroup',

  components: { Button, Icon, Form, draggable },

  props: {
    element: {
      type: Object as PropType<GroupElement>,
      required: true,
    },

    schema: {
      type: Array as PropType<Schema>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },

    peerDependencySchema: {
      type: Array as PropType<Schema>,
      default: () => [],
    },

    peerDependencyValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const groupSchema = computed(() => element.value.children || []);

    const { onValidation } = useValidation(emit);

    const data = computed<Record<string, unknown>[] | undefined>({
      get: () => {
        const v = value.value[element.value.name];
        if (v !== undefined && (!Array.isArray(v) || !v.every((e) => typeof e === 'object'))) {
          logger.error(
            'Value does not fit to group element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return [];
        }

        if (v === undefined) {
          return [];
        }

        return v;
      },
      set: (newData) => {
        if (!newData) {
          newData = [];
        }
        emit('input', { ...value.value, [element.value.name]: newData });
      },
    });

    function getSchema(itemData: Record<string, unknown>, index: number): SubElement[] {
      if (Array.isArray(groupSchema.value)) {
        return groupSchema.value;
      }
      return groupSchema.value(itemData, index);
    }

    const forms = computed<{ data: Record<string, unknown>; schema: SubElement[] }[]>({
      get() {
        return (
          data.value?.map((itemData, index) => ({
            data: itemData,
            schema: getSchema(itemData, index),
          })) || []
        );
      },
      set(newForms) {
        data.value = newForms.map((newData) => newData.data);
      },
    });

    const addLabel = computed(() => {
      return i18n.tc('form.group.add_more_label', undefined, {
        label: element.value.repeatableLabel,
      });
    });

    const noItemsLabel = computed(() => {
      return i18n.tc('form.group.no_items', undefined, {
        label: element.value.repeatableLabel,
      });
    });

    function addItem() {
      data.value = [...(data.value || []), {}];
    }

    function updateItem(index: number, newData?: Record<string, unknown>) {
      const existingData = data.value || [];
      data.value = [...existingData.slice(0, index), newData || {}, ...existingData.slice(index + 1)];
    }

    function removeItem(index: number) {
      const existingData = data.value || [];
      const newData = [...existingData.slice(0, index), ...existingData.slice(index + 1)];
      if (!element.value.repeatable) {
        // if the element is not repeatable which means the group items are fixed we at least provide a clearing/reset functionality
        newData.splice(index, 0, {});
      }
      data.value = newData;
      // reset validation flag of last element because it was shifted one position ahead
      onValidation(`${existingData.length - 1}`, true);
    }

    function reverseOrder() {
      const existingData = data.value || [];
      data.value = [...existingData].reverse();
    }

    return {
      data,
      forms,
      groupSchema,
      addLabel,
      noItemsLabel,
      addItem,
      updateItem,
      removeItem,
      onValidation,
      reverseOrder,
    };
  },
});
</script>
