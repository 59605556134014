<template>
  <v-app
    id="app"
    class="app"
    :class="{
      dark: isNightModeActive,
    }"
  >
    <div class="flex h-full antialiased gray_fifth text-gray_first z-10">
      <AppBar />
      <div class="flex items-start w-full !max-w-app h-auto mt-13.5 mx-auto mb-17.5">
        <div class="flex flex-col">
          <UpdateBar v-if="isUpdatingServiceWorker" />
          <template v-else>
            <ActiveStep />
            <WatchOrders v-if="isAuthenticated" class="mx-auto mt-4" />
            <router-view class="flex flex-col w-full" />
          </template>
        </div>
      </div>
    </div>

    <div
      v-if="installationWizard.isInstallationWizardActive"
      class="absolute top-1 left-1/2 flex z-1001 justify-center gap-4"
    >
      <Button
        class="-mt-1 -ml-1/1"
        color="secondary_light"
        @click="installationWizard.isInstallationWizardActive = false"
      >
        <Icon name="symbol/cancel" color="white" class="pr-2" />
        {{ $tc('installation_wizard.exit_installation_wizard') }}
      </Button>
    </div>

    <template v-if="installationWizard.isInstallationWizardActive">
      <!-- displaying green borders around whole app in the order top, left, right, bottom -->
      <div class="fixed w-full h-1 bg-secondary_light z-20 left-0 top-0" />
      <div class="fixed h-full w-1 bg-secondary_light z-20 left-0 top-0" />
      <div class="fixed h-full w-1 bg-secondary_light z-20 right-0 top-0" />
      <div class="fixed w-full h-1 bg-secondary_light z-20 left-0 bottom-0" />
    </template>
  </v-app>
</template>

<script lang="ts">
import { SystemStatus } from '@anschuetz-elog/common';
import { Button, store as coreStore, Icon, useRoute, useRouter, utilities } from '@anschuetz-elog/frontend-core';
import { computed, defineComponent, getCurrentInstance, onBeforeUnmount, onMounted, watch } from 'vue';

import ActiveStep from '@/components/installation-wizard/ActiveStep.vue';
import AppBar from '@/components/layout/AppBar.vue';
import WatchOrders from '@/components/layout/WatchOrders.vue';
import UpdateBar from '@/components/UpdateBar.vue';
import { useInstallationWizard } from '@/compositions/useInstallationWizard';
import store from '@/store';
import useFeathers from '#/compositions/useFeathers';

export default defineComponent({
  name: 'App',
  components: { ActiveStep, AppBar, Button, Icon, UpdateBar, WatchOrders },

  setup() {
    const feathers = useFeathers();
    const route = useRoute();
    const router = useRouter();
    const vm = getCurrentInstance();
    const installationWizard = useInstallationWizard();

    onMounted(() => {
      // register realtime hook
      feathers.service('systemStatus').on('updated', onSystemStatusUpdates.bind(this));

      void loadSystemStatus();
    });

    onBeforeUnmount(() => {
      // un-register realtime hook
      feathers.service('systemStatus').off('updated', onSystemStatusUpdates.bind(this));
    });
    const isUpdatingServiceWorker = computed(() => {
      return store.state.serviceWorker.updating;
    });

    const isAuthenticated = computed(() => {
      return coreStore.getters.auth.isAuthenticated;
    });

    watch(isAuthenticated, () => {
      // check if we need authentication for this page and we got logged out
      if (
        utilities.hasRouteAuthenticationMode(route.value, utilities.AuthenticationMode.AUTHENTICATED_ONLY) &&
        !isAuthenticated.value
      ) {
        coreStore.commit.auth.setLoginRedirectUrl(route.value.path); // save current url to redirect user back after login
        void router.push({ name: 'auth-login' });
      }

      if (!isAuthenticated.value) {
        installationWizard.isInstallationWizardActive = false;
      }
    });

    function onSystemStatusUpdates(systemStatus: SystemStatus) {
      store.commit.systemStatus.updateSystemStatus(systemStatus);
    }

    const loadSystemStatus = async () => {
      if (!isConnected.value) {
        return;
      }

      // the id is ignored here as we only have system-status once
      const systemStatus = await feathers.service('systemStatus').get('ignored-id');
      store.commit.systemStatus.updateSystemStatus(systemStatus);
    };

    const isConnected = computed(() => {
      return coreStore.getters.websocket.isConnected;
    });

    const isNightModeActive = computed(() => {
      if (!vm) {
        return false;
      }
      return vm.proxy.$vuetify.theme.dark;
    });

    watch(isConnected, () => {
      void loadSystemStatus();
    });

    return {
      isAuthenticated,
      isNightModeActive,
      isUpdatingServiceWorker,
      installationWizard,
    };
  },
});
</script>
