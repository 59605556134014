import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '',
    component: () => import('#/views/view/ViewGuard.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
    children: [
      {
        path: '/view/:viewId',
        name: 'view',
        component: () => import('#/views/view/ProtocolView.vue'),
        props: true,
        meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
      },
      {
        path: '/view/:viewId/new-protocol/:baseProtocolId?',
        name: 'view-new-protocol',
        component: () => import('#/views/protocol/ViewProtocolCreate.vue'),
        props: true,
      },
    ],
  },
];

export default routes;
