import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

import { prefixRoutes } from '#/utilities';

const settingsRoutes: RouteConfig[] = [
  // user settings
  {
    path: '/book-configuration',
    name: 'settings-book-configuration',
    component: () => import('#/views/settings/BookConfiguration.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.BOOK_CONFIGURATION } },
  },
  // user settings
  {
    path: 'user',
    name: 'settings-users',
    component: () => import('#/views/settings/user/Users.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.USER }, externallyConfigurable: true },
  },
  {
    path: 'user/new',
    name: 'settings-user-new',
    component: () => import('#/views/settings/user/UserCreate.vue'),
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.USER }, externallyConfigurable: true },
  },
  {
    path: 'user/:userId',
    name: 'settings-user',
    component: () => import('#/views/settings/user/User.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.USER }, externallyConfigurable: true },
  },
  {
    path: 'user/:userId/edit',
    name: 'settings-user-edit',
    component: () => import('#/views/settings/user/UserEdit.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.USER }, externallyConfigurable: true },
  },
  {
    path: 'wizard',
    name: 'settings-wizard',
    component: () => import('#/views/settings/Wizard.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/books',
    name: 'settings-wizard-books',
    component: () => import('#/views/settings/wizard/WizardBooks.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/books/new',
    name: 'settings-wizard-book-create',
    component: () => import('#/views/settings/wizard/WizardBookCreate.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/books/edit/:bookId',
    name: 'settings-wizard-book-edit',
    component: () => import('#/views/settings/wizard/WizardBookEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/groups',
    name: 'settings-wizard-groups',
    component: () => import('#/views/settings/wizard/WizardGroups.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/groups/new',
    name: 'settings-wizard-group-create',
    component: () => import('#/views/settings/wizard/WizardGroupCreate.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/groups/edit/:protocolTypeGroupId',
    name: 'settings-wizard-group-edit',
    component: () => import('#/views/settings/wizard/WizardGroupEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/types',
    name: 'settings-wizard-types',
    component: () => import('#/views/settings/wizard/WizardTypes.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/types/new/:protocolTypeId?',
    name: 'settings-wizard-type-create',
    component: () => import('#/views/settings/wizard/WizardTypeCreate.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/types/edit/:protocolTypeId',
    name: 'settings-wizard-type-edit',
    component: () => import('#/views/settings/wizard/WizardTypeEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/content-types',
    name: 'settings-wizard-content-types',
    component: () => import('#/views/settings/wizard/WizardContentTypes.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/content-types/new/:protocolContentTypeId?',
    name: 'settings-wizard-content-type-create',
    component: () => import('#/views/settings/wizard/WizardContentTypeCreate.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/content-types/edit/:protocolContentTypeId',
    name: 'settings-wizard-content-type-edit',
    component: () => import('#/views/settings/wizard/WizardContentTypeEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/equipment-lists',
    name: 'settings-wizard-equipment-list',
    component: () => import('#/views/settings/wizard/WizardEquipmentLists.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/equipment-list/new/:equipmentListId?',
    name: 'settings-wizard-equipment-list-create',
    component: () => import('#/views/settings/wizard/WizardEquipmentListCreate.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/equipment-list/edit/:equipmentListId',
    name: 'settings-wizard-equipment-list-edit',
    component: () => import('#/views/settings/wizard/WizardEquipmentListEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/plausibility-checks',
    name: 'settings-wizard-plausibility-check',
    component: () => import('#/views/settings/wizard/WizardPlausibilityChecks.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/plausibility-check/new/:plausibilityCheckId?',
    name: 'settings-wizard-plausibility-check-create',
    component: () => import('#/views/settings/wizard/WizardPlausibilityCheckCreate.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/plausibility-check/edit/:plausibilityCheckId',
    name: 'settings-wizard-plausibility-check-edit',
    component: () => import('#/views/settings/wizard/WizardPlausibilityCheckEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/views',
    name: 'settings-wizard-views',
    component: () => import('#/views/settings/wizard/WizardViews.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/view/new/:viewId?',
    name: 'settings-wizard-view-create',
    component: () => import('#/views/settings/wizard/WizardViewCreate.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/view/edit/:viewId',
    name: 'settings-wizard-view-edit',
    component: () => import('#/views/settings/wizard/WizardViewEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/etmal',
    name: 'settings-wizard-etmal',
    component: () => import('#/views/settings/wizard/WizardEtmal.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/etmal/new-calculation',
    name: 'settings-wizard-etmal-calculation-create',
    component: () => import('#/views/settings/wizard/WizardEtmalCalculationCreate.vue'),
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
  {
    path: 'wizard/etmal/edit-calculation/:etmalCalculationId',
    name: 'settings-wizard-etmal-calculation-edit',
    component: () => import('#/views/settings/wizard/WizardEtmalCalculationEdit.vue'),
    props: true,
    meta: { authorization: { action: 'manage', subject: AUTH_RESOURCES.WIZARD }, ptcMasterOnly: true },
  },
];

const routes: RouteConfig[] = [
  ...prefixRoutes('/settings', settingsRoutes),
  {
    path: '/audit-log',
    name: 'audit-log',
    component: () => import('#/views/settings/AuditLogs.vue'),
    // meta: { authorization: { action: 'read', subject: AuditLog.name } },
  },
];

export default routes;
