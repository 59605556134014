import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/sounding-spots',
    name: 'sounding-spots',
    component: () => import('#/views/sounding-spots/SoundingSpots.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.SOUNDING_SPOT } },
  },
  {
    path: '/sounding-spots/:location',
    name: 'sounding-spots-location',
    component: () => import('#/views/sounding-spots/SoundingSpotsLocation.vue'),
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.SOUNDING_SPOT } },
    props: true,
  },

  {
    path: '/sounding-spots/:location/create',
    name: 'sounding-spots-create',
    component: () => import('#/views/sounding-spots/SoundingSpotCreate.vue'),
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.SOUNDING_SPOT } },
    props: true,
  },

  {
    path: '/sounding-spots/:location/edit/:soundingSpotId',
    name: 'sounding-spots-edit',
    component: () => import('#/views/sounding-spots/SoundingSpotEdit.vue'),
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.SOUNDING_SPOT } },
    props: true,
  },
];

export default routes;
