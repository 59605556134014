<template>
  <DetailsListEntry v-if="!edit" class="ma-0" :title="element.label" :content="readModeModel" />
  <AutoComplete
    v-else
    v-model="model"
    :title="element.label"
    :placeholder="element.placeholder"
    :mark="invalid"
    :items="items"
    :loading="element.itemsLoading"
    :multiple="element.multiple"
    :data-test="`autocomplete-${element.name}`"
  />
</template>

<script lang="ts">
import { AutoCompleteElement, logger } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef, watch } from 'vue';

import { AutoCompleteItems } from '#/components/AutoComplete.types';
import AutoComplete from '#/components/AutoComplete.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';

export default defineComponent({
  name: 'FormAutoComplete',

  components: {
    AutoComplete,
    DetailsListEntry,
  },

  props: {
    element: {
      type: Object as PropType<AutoCompleteElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const items = computed((): AutoCompleteItems => {
      return (element.value.items || []).map((item) => ({
        value: item.value,
        text: item.label,
      }));
    });

    const model = computed<(string | number | boolean)[] | string | number | boolean | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string' && typeof v !== 'number' && typeof v !== 'boolean') {
          logger.error(
            'Value does not fit to autocomplete element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          emit('input', { ...value.value, [element.value.name]: undefined });
          return undefined;
        }
        if (v !== undefined && element.value.multiple) {
          if (typeof v !== 'string') {
            logger.error('Passing a non-string value to a multiple autocomplete element is not supported.');
            return undefined;
          }
          if (v === '') {
            return [];
          }
          return `${v}`.split(',').map<string | number | boolean>((value) => {
            // map to number or boolean if items are numbers or booleans
            return element.value.storeItemValuesAsNumber ? Number(value) : value;
          });
        }
        return v;
      },
      set(newElementValue) {
        if (element.value.multiple && Array.isArray(newElementValue)) {
          emit('input', { ...value.value, [element.value.name]: newElementValue.join(',') });
          return;
        }
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    watch(
      [model, element],
      () => {
        const v = model.value;
        if (v !== undefined && !element.value.itemsLoading) {
          if (Array.isArray(v)) {
            const filteredValues = v.filter((_v) => items.value.find((item) => item.value === _v));
            if (filteredValues.length !== v.length) {
              model.value = filteredValues;
            }
            return;
          }

          if (!items.value.find((item) => item.value === v)) {
            model.value = undefined;
            return;
          }
        }
      },
      { deep: true, immediate: true },
    );

    const readModeModel = computed(() => {
      const v = model.value;
      if (element.value.showItemLabel) {
        if (element.value.multiple && Array.isArray(v)) {
          return v.map((_v) => items.value.find((i) => i.value === _v)?.text).join(', ');
        }
        return items.value.find((i) => i.value === v)?.text;
      }
      if (element.value.multiple && Array.isArray(v)) {
        return v.join(', ');
      }
      return v;
    });

    return { model, items, readModeModel };
  },
});
</script>
