<template>
  <Modal
    v-if="show"
    :is-open="show"
    :disable-confirm="!fileInfos.length"
    :max-width="600"
    :title="title"
    :loading="uploading"
    @cancel="closeMultiFileUploader"
    @confirm="closeMultiFileUploader"
  >
    <vue-dropzone
      v-show="show"
      id="multi-file-upload"
      class="uploader bg-white"
      :class="{ '!p-0 !min-h-0 mb-2 dz-started': fileInfos.length > 0 }"
      use-custom-slot
      :options="dropzoneOptions"
      @vdropzone-files-added="fileAdded($event)"
      @vdropzone-success="onSuccess"
      @vdropzone-processing="onProcessing"
      @vdropzone-error="onUploadError"
      @vdropzone-canceled="onUploadError($event, 'canceled')"
      @vdropzone-upload-progress="updateUploadProgress"
      @vdropzone-sending="onSending"
    >
      <div class="dropzone-content">
        <h3 class="dropzone-title text-primary">{{ $t('file_upload.dropzone_drag_and_drop') }}</h3>
        <div class="dropzone-subtitle">{{ $t('file_upload.dropzone_or_click_to_upload') }}</div>
      </div>
    </vue-dropzone>

    <div v-if="fileInfos.length > 0">
      <div v-for="(item, index) in fileInfos" :key="index" class="flex w-full flex-wrap">
        <span class="font-medium mr-2">{{ index + 1 }} </span>
        <span> {{ item.name }} </span>
        <span class="mx-5 flex justify-end"> {{ item.size }} </span>
        <v-progress-linear
          v-if="!item.error"
          :indeterminate="item.progress === undefined"
          :value="item.progress"
          color="primary"
          class="w-full text-white"
          height="25"
        >
          {{ item.progress }}
        </v-progress-linear>
        <div v-if="item.error" class="w-full bg-error_bg text-error p-2 border-error border-1 rounded">
          {{ item.error }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<script lang="ts">
// eslint-disable-next-line no-restricted-imports
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import {
  addRecordFileToFormData,
  addSignature,
  File,
  RecordFile,
  Ref,
  ServiceTypes,
  signFile,
  User,
} from '@anschuetz-elog/common';
import Dropzone from 'dropzone';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';
import vueDropzone from 'vue2-dropzone';

import Modal from '#/components/Modal.vue';
import useFeathers from '#/compositions/useFeathers';
import i18n from '#/i18n';
import store from '#/store';
import { formatFileSize, getDropzoneOptions } from '#/utilities';

export default defineComponent({
  name: 'FileUpload',

  components: { vueDropzone, Modal },

  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    service: {
      type: String as PropType<Extract<keyof ServiceTypes, 'file' | 'record-file'>>,
      required: true,
    },
  },

  emits: {
    close: (): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'files-uploaded': <T extends File | RecordFile>(_fileIds: Ref<T>[]) => true,
  },

  setup(props, context) {
    const feathers = useFeathers();
    const service = toRef(props, 'service');

    const selectedFiles = ref<Dropzone.DropzoneFile[]>([]);
    const processing = ref<string[]>([]);
    const uploaded = ref<Record<string, File | RecordFile>>({});
    const progress = ref<Record<string, number>>({});
    const errors = ref<Record<string, string>>({});
    const recordFiles = ref<Record<string, RecordFile>>({});
    function fileAdded(files: Dropzone.DropzoneFile[]): void {
      selectedFiles.value.push(...files);
    }

    function closeMultiFileUploader() {
      uploaded.value = {};
      selectedFiles.value = [];
      processing.value = [];
      errors.value = {};
      progress.value = {};
      context.emit('close');
    }

    function onSuccess(dropzoneFile: Dropzone.DropzoneFile, file: [File] | RecordFile) {
      if (dropzoneFile.upload) {
        const { uuid } = dropzoneFile.upload;
        processing.value = processing.value.filter((otherUuid) => otherUuid !== uuid);
        uploaded.value = { ...uploaded.value, [uuid]: Array.isArray(file) ? file[0] : file };
      }
      context.emit(
        'files-uploaded',
        Object.values(uploaded.value).map((file) => File.createRef(file._id)),
      );
    }

    function acceptFile(file: Dropzone.DropzoneFile, done: (error?: string | Error) => void): void {
      if (service.value !== 'record-file') {
        done();
        return;
      }
      const reader = new FileReader();
      const user = store.state.auth.user;
      const key = store.state.auth.key;

      reader.onload = () => {
        if (!user || !key) {
          done(`cannot find user`);
          return;
        }
        if (!file.upload) {
          done(`cannot except files`);
          return;
        }
        if (!reader.result) {
          done('No file content to sign');
          return;
        }
        if (typeof reader.result !== 'string') {
          done('File content must be parsed as string');
          return;
        }
        const fileSignature = signFile(reader.result, user.keyId, key);

        const recordFile = new RecordFile({
          author: User.createRef(user._id),
          filename: file.name,
          mimeType: file.type,
          filesize: `${file.size}`,
          fileSignature: fileSignature.value,
        });

        addSignature(feathers, recordFile, user, key);

        recordFiles.value = { ...recordFiles.value, [file.upload.uuid]: recordFile };
        done();
      };
      reader.readAsBinaryString(file);
    }

    const baseDropzoneOptions = getDropzoneOptions(`/api/v1/rest/${props.service}`);

    const dropzoneOptions = computed<Dropzone.DropzoneOptions>(() => ({
      ...baseDropzoneOptions.value,
      autoProcessQueue: true,
      maxFilesize: 10, // 10mb
      parallelUploads: 3,
      createImageThumbnails: false,
      previewTemplate: '<span></span>', // hack to disable previews
      accept: acceptFile,
    }));

    function onSending(file: Dropzone.DropzoneFile, xhr: XMLHttpRequest, formData: FormData): void {
      if (!file.upload || service.value !== 'record-file') {
        return;
      }
      const recordFile = recordFiles.value[file.upload.uuid];
      addRecordFileToFormData(recordFile, formData);
    }

    function updateUploadProgress(file: Dropzone.DropzoneFile): void {
      if (!file.upload) {
        return;
      }
      progress.value = { ...progress.value, [file.upload.uuid]: Math.round(file.upload.progress * 100) / 100 };
    }

    const fileInfos = computed(() => {
      return selectedFiles.value.map((file) => ({
        name: file.name,
        size: i18n.tc('file_upload.size', undefined, { size: formatFileSize(file.size) }),
        progress:
          !file.upload || !Object.keys(progress.value).includes(file.upload.uuid)
            ? undefined
            : progress.value[file.upload.uuid],
        error: !file.upload ? undefined : errors.value[file.upload.uuid],
        uploading: !file.upload ? false : processing.value.includes(file.upload.uuid),
        uploaded: !file.upload ? false : Object.keys(uploaded.value).includes(file.upload.uuid),
      }));
    });

    function onUploadError(files: Dropzone.DropzoneFile | Dropzone.DropzoneFile[], error: string | Error): void {
      // to prevent dialog from processing if there is some error
      processing.value = [];
      if (!Array.isArray(files)) {
        onUploadError([files], error);
        return;
      }
      const errorMessage = typeof error === 'string' ? error : error.message;
      const newErrors: Record<string, string> = {};
      files.forEach((file) => {
        if (file.upload) {
          newErrors[file.upload.uuid] = errorMessage;
        }
      });
      errors.value = { ...errors.value, ...newErrors };
    }

    function onProcessing(file: Dropzone.DropzoneFile): void {
      if (!file.upload) {
        return;
      }
      processing.value = [...processing.value, file.upload.uuid];
    }

    const uploading = computed(() => processing.value.length > 0);

    return {
      fileAdded,
      closeMultiFileUploader,
      fileInfos,
      dropzoneOptions,
      uploading,
      onSuccess,
      selectedFiles,
      updateUploadProgress,
      onUploadError,
      onProcessing,
      onSending,
    };
  },
});
</script>

<style scoped>
.uploader ::v-deep .dropzone-title {
  margin-top: 0;
}

.uploader.dz-started ::v-deep .dz-message {
  display: block;
}
</style>
