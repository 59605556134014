import { sentryConfigGenerator } from '@anschuetz-elog/common';
import { dynamicConfig } from '@anschuetz-elog/frontend-core';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import LoglevelSentryPlugin from '@toruslabs/loglevel-sentry';
import Vue from 'vue';

import pkg from '@/../package.json';
import { CustomOfflineIntegration } from '@/lib';
import logger from '@/logger';
import useFeathers from '#/compositions/useFeathers';

export function load(): void {
  const dsn = dynamicConfig('SENTRY_DSN');
  const environment = dynamicConfig('SENTRY_ENVIRONMENT');
  const offline = dynamicConfig('OFFLINE_LOGGING');

  const feathers = useFeathers();
  const sentryConfig = sentryConfigGenerator(
    dsn || '',
    environment || '',
    offline || 'false',
    import.meta.env.VITE_SENTRY_RELEASE ? `${pkg.name.replace('/', '-')}-${import.meta.env.VITE_SENTRY_RELEASE}` : '',
    [new VueIntegration({ Vue, attachProps: true })],
    [new CustomOfflineIntegration(feathers)],
  );
  if (sentryConfig) {
    Sentry.init(sentryConfig);
    const sentry = new LoglevelSentryPlugin(Sentry);
    sentry.install(logger);
    logger.info('Sentry reporting enabled (DSN: %s, offline: %s)', dsn, offline || 'false');
  } else {
    logger.info('Sentry reporting not enabled');
  }
}
