import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/vessel',
    component: () => import('#/views/vessel/Vessel.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.VESSEL_PARTICULARS } },
    children: [
      {
        path: ':vesselId?',
        name: 'vessel',
        component: () => import('#/views/vessel/VesselDetails.vue'),
        props: true,
      },
      {
        path: 'edit',
        name: 'vessel-edit',
        component: () => import('#/views/vessel/VesselEdit.vue'),
        props: true,
        meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.VESSEL_PARTICULARS } },
      },
    ],
  },
];

export default routes;
