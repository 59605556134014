<template>
  <div class="positionRelative">
    <span v-if="title" class="titleWrapper">{{ title }}</span>
    <v-autocomplete
      v-model="innerValue"
      v-bind="$attrs"
      class="bg-white"
      :class="{ autoComplete: true, marked: mark }"
      dense
      flat
      auto-select-first
      :items="items"
      :loading="loading"
      hide-details
      outlined
      :placeholder="placeholder"
      :multiple="multiple"
      :chips="multiple"
      :deletable-chips="multiple"
      :clearable="!multiple"
    >
      <template #append>
        <Icon class="expand-icon" name="arrow/chevronDown" />
      </template>
    </v-autocomplete>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from '#/components/Icon.vue';

import { AutoCompleteItems, AutoCompleteValue } from './AutoComplete.types';

export default defineComponent({
  name: 'AutoComplete',

  components: { Icon },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    items: {
      type: Array as PropType<AutoCompleteItems>,
      required: true,
    },
    value: {
      type: [Array, String, Number, Boolean] as PropType<AutoCompleteValue>,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '',
    },
    mark: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: AutoCompleteValue): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const innerValue = computed({
      get() {
        return value.value;
      },
      set(newValue: AutoCompleteValue) {
        context.emit('input', newValue);
      },
    });

    return { innerValue };
  },
});
</script>

<style scoped>
.positionRelative {
  position: relative;
}

.titleWrapper {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  text-align: left;
}

.autoComplete.marked {
  background-color: var(--v-error_bg-base) !important;
}

.autoComplete >>> input {
  padding-left: 12px !important;
}

.autoComplete ::v-deep .v-input__control > div:first-child {
  border-bottom: none;
  border-radius: 5px;
}

/* workaround for https://github.com/vuetifyjs/vuetify/issues/11553 */
::v-deep .autoComplete.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 32px;
}

/* workaround for https://github.com/vuetifyjs/vuetify/issues/8642 */
::v-deep .v-chip {
  white-space: normal;
  height: auto !important;
  padding-top: 6px;
  padding-bottom: 6px;
}

.autoComplete.v-select.v-select--is-menu-active .expand-icon {
  transform: rotate(180deg);
}

::v-deep .v-list-item__action {
  /* workaround to hide checkboxes before items for multi-selection */
  display: none;
}
</style>
