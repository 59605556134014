import {
  Amendment,
  addSignature as baseAddSignature,
  getDataToSign as baseGetDataToSign,
  Protocol,
  Record,
  SignableData,
} from '@anschuetz-elog/common';

import useFeathers from '#/compositions/useFeathers';
import store from '#/store';

export function getProtocolDataToSign(data: Protocol): Record | Amendment {
  if (!store.state.auth.user) {
    throw new Error('No user logged in');
  }
  const feathers = useFeathers();
  return baseGetDataToSign(feathers, data, store.state.auth.user);
}

export function addSignature(data: SignableData): void {
  if (!store.state.auth.user || !store.state.auth.key) {
    throw new Error('No user logged in');
  }

  const feathers = useFeathers();
  baseAddSignature(
    feathers,
    data,
    store.state.auth.user,
    store.state.auth.key,
    store.state.clientDevice.clientDeviceId,
  );
}
