<template>
  <div :class="`border border-${color} rounded-[2px] bg-white`">
    <div v-if="!expanded" class="flex p-1" :class="{ 'cursor-pointer': collapsable }" @click="toggleExpanded">
      <Icon name="action/search" data-test="icon-search" :color="color" />
    </div>
    <v-text-field
      v-else
      v-model="searchText"
      solo
      flat
      hide-details
      no-resize
      autofocus
      class="text-field min-w-[200px] m-0 p-0"
      :placeholder="placeholder || $tc('search')"
      is-label-active="true"
    >
      <template #prepend-inner>
        <div class="flex p-1 m-auto" :class="{ 'cursor-pointer': collapsable }" @click="toggleExpanded">
          <Icon name="action/search" :color="color" />
        </div>
      </template>
      <template #append>
        <div :class="`cursor-pointer border-l border-${color} p-1`" @click="searchText = ''">
          <Icon name="symbol/cancel" :color="color" />
        </div>
      </template>
    </v-text-field>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef } from 'vue';

import Icon from '#/components/Icon.vue';

export default defineComponent({
  name: 'SearchTextField',

  components: {
    Icon,
  },

  props: {
    value: {
      type: String,
      default: '',
    },

    collapsable: {
      type: Boolean,
    },

    placeholder: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string): boolean => true,
    collapsed: (): boolean => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const collapsable = toRef(props, 'collapsable');

    const expanded = ref(!collapsable.value);

    const searchText = computed({
      get() {
        return value.value;
      },
      set(newValue: string) {
        emit('input', newValue);
      },
    });

    function toggleExpanded() {
      if (!collapsable.value) {
        return;
      }
      expanded.value = !expanded.value;
      if (!expanded.value) {
        emit('collapsed');
      }
    }

    return { searchText, expanded, toggleExpanded };
  },
});
</script>

<style scoped>
.text-field ::v-deep .v-input__control {
  min-height: unset !important;
}
.text-field ::v-deep .v-input__slot {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
