<template>
  <ChangeHistory
    :history="history"
    :active-object-id="protocol._id"
    :edit="edit || deletion"
    :is-loading="loadingProtocols"
    @history-item-clicked="openProtocolDetails"
  />
</template>

<script lang="ts">
import { Approval, Protocol } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, ref, toRef } from 'vue';

import { ChangeHistoryData } from '#/components/ChangeHistory.types';
import ChangeHistory from '#/components/ChangeHistory.vue';
import useFeathers from '#/compositions/useFeathers';
import useFind from '#/compositions/useFind';
import { useRoute, useRouter } from '#/compositions/useRouter';
import i18n from '#/i18n';
import { getHistoryOfObject, getLatestObject, loadSuccessorsAndPredecessors } from '#/lib/history';
import { compareTimestamps, getRoleLabel } from '#/utilities';

export default defineComponent({
  name: 'HistoryInfo',
  components: { ChangeHistory },

  props: {
    protocol: {
      type: Object as PropType<Protocol>,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    deletion: {
      type: Boolean,
    },
  },

  setup(props) {
    const feathers = useFeathers();

    const protocol = toRef(props, 'protocol');
    const edit = toRef(props, 'edit');
    const deletion = toRef(props, 'deletion');

    const router = useRouter();
    const route = useRoute();

    const protocols = ref<Protocol[]>([]);
    const loadingProtocols = ref(false);

    const loadProtocols = async () => {
      loadingProtocols.value = true;
      protocols.value = await loadSuccessorsAndPredecessors(
        [protocol.value],
        feathers.service('protocol'),
        edit.value || deletion.value,
      );
      loadingProtocols.value = false;
    };
    void loadProtocols();

    const { data: approvals } = useFind(
      'approval',
      computed(() => {
        if (loadingProtocols.value) {
          return undefined;
        }
        return {
          query: {
            protocols: { $elemMatch: { 'data._id': { $in: protocols.value.map((p) => p.gatewayAmendmentId) } } },
          },
        };
      }),
    );

    function getType(p: Protocol): string {
      if (p.deleted || (p._id === protocol.value._id && deletion.value)) {
        return i18n.tc('history.deletion');
      }

      if (p.predecessor) {
        return i18n.tc('history.amendment');
      }

      return i18n.tc('history.original');
    }

    function getTypeForApproval(approval: Approval): string {
      return i18n.tc('protocol.approval.history_type', undefined, {
        role: getRoleLabel(feathers.get('localCache').getRoleById(approval.role._id), feathers.get('localCache')),
        stage: i18n.tc(`protocol.approval.stage_${approval.stage}`),
      });
    }

    const editHistory = computed(() => {
      const startProtocol = getLatestObject(protocols.value, protocol.value);
      return getHistoryOfObject(protocols.value, startProtocol).map<ChangeHistoryData>((p) => ({
        id: p._id,
        author: p.author,
        type: getType(p),
        timestamp: p.createdTimestamp,
      }));
    });

    const approvalHistory = computed(() => {
      return approvals.value.map<ChangeHistoryData>((approval) => ({
        id: approval._id,
        author: approval.approver,
        type: getTypeForApproval(approval),
        timestamp: approval.timestamp,
        notClickable: true,
      }));
    });

    const history = computed(() => {
      return [...editHistory.value, ...approvalHistory.value].sort((a, b) => {
        return compareTimestamps(a.timestamp, b.timestamp);
      });
    });

    function openProtocolDetails(id: string): void {
      if (route.value.params.bookId) {
        void router.push({
          name: 'protocol',
          params: {
            protocolId: id,
            bookId: route.value.params.bookId,
          },
        });
      } else {
        void router.push({
          name: 'report',
          params: {
            protocolId: id,
            protocolTypeId: route.value.params.protocolTypeId,
          },
        });
      }
    }

    return {
      loadingProtocols,
      openProtocolDetails,
      history,
      approvals,
      protocols,
    };
  },
});
</script>
