<template>
  <div class="box bg-white" :class="{ 'sub-box': subBox }">
    <div
      v-if="hasTitle"
      class="box-title gray_fourth gray_first--text relative"
      :class="{ 'title-centered': titleCentered }"
    >
      <button v-if="isExpandable" class="absolute left-1 p-2" @click.stop="$emit('expansion-click')">
        <Icon v-if="!contentHidden" name="arrow/chevronUp" />
        <Icon v-else name="arrow/chevronDown" />
      </button>
      <slot name="title">{{ title }}</slot>
    </div>
    <slot v-if="!contentHidden" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from '#/components/Icon.vue';

export default defineComponent({
  name: 'Box',
  components: { Icon },
  props: {
    title: {
      type: String as PropType<string | null>,
      default: null,
    },
    titleCentered: {
      type: Boolean,
    },
    subBox: {
      type: Boolean,
    },
    isExpandable: {
      type: Boolean,
    },
    contentHidden: {
      type: Boolean,
    },
  },

  emits: {
    'expansion-click': (): boolean => true,
  },

  setup(props, context) {
    const title = toRef(props, 'title');
    const hasTitle = computed(() => !!context.slots['title'] || !!title.value);

    return { hasTitle };
  },
});
</script>

<style scoped>
.box {
  display: flex;
  flex-flow: column;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  margin: 16px 8px;
}

.box.sub-box {
  margin: 0.5em;
  box-shadow: none;
}

.box-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 42px;
  height: 42px;
  width: 100%;
  border-bottom-width: 0.5px;
  border-bottom-style: solid;
  border-bottom-color: var(--v-gray_third-base) !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 40px;
  background: var(--borders-and-headers);
}

.box.sub-box .box-title {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
}

.title-centered {
  text-align: center;
}
</style>
