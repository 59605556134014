<template>
  <Icon :name="iconName" class="ais" :color="isAISAvailable ? 'success' : 'error'" />
</template>

<script lang="ts">
import { Icon, IconName } from '@anschuetz-elog/frontend-core';
import { computed, defineComponent } from 'vue';

import store from '@/store';

export default defineComponent({
  name: 'AISIcon',

  components: { Icon },

  setup() {
    const isAISAvailable = computed(() => {
      if (store.state.systemStatus.systemStatus !== null) {
        if (store.state.systemStatus.systemStatus.aisAvailable !== null) {
          return store.state.systemStatus.systemStatus.aisAvailable;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    const iconName = computed<IconName>(() => `ais/${isAISAvailable.value ? 'on' : 'off'}`);

    return { isAISAvailable, iconName };
  },
});
</script>
