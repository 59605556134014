import { getCurrentInstance, Ref, ref } from 'vue';
import Router, { Route } from 'vue-router';

export function useRouter(): Router {
  const instance = getCurrentInstance();
  if (instance) {
    return (instance.proxy as unknown as { $router: Router }).$router;
  }
  return undefined as unknown as Router;
}

export function useRoute(): Ref<Route> {
  const router = useRouter();

  const route = ref<Route>(router.currentRoute);
  router.afterEach((to) => (route.value = to));
  return route;
}
