<template>
  <Box :title="$tc('history.history')" title-centered>
    <div class="content white gray_first--text">
      <v-data-table
        class="table"
        :headers="headers"
        :items="groupedHistoryData"
        hide-default-footer
        disable-pagination
        mobile-breakpoint="0"
        group-by="date"
        disable-sort
        :loading="isLoading"
      >
        <template #[`group.header`]="props">
          <td class="group-header" :colspan="headers.length">
            <span>{{ props.group }}</span>
          </td>
        </template>
        <template #item="{ item }">
          <tr
            :class="{ primary_bg: isActive(item), clickable: isClickable(item) }"
            @click.stop.prevent="onClickHistoryItem(item)"
          >
            <td>
              {{ item.time }}
            </td>
            <td :class="{ 'clickable-text': isClickable(item), 'full-width': true }">
              {{ item.type }}
              <span v-if="isActive(item)">{{ activeTypeInfo }}</span>
            </td>
            <td>
              {{ item.author }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </Box>
</template>

<script lang="ts">
import moment from 'moment';
import { computed, defineComponent, PropType, toRef } from 'vue';
import { DataTableHeader } from 'vuetify';

import Box from '#/components/layout/Box.vue';
import { MOMENT_WEEKDAY_DATE } from '#/config/time';
import i18n from '#/i18n';
import { format, getUserFullName, timeStr } from '#/utilities';

import { ChangeHistoryData, GroupedChangeHistoryData } from './ChangeHistory.types';

export default defineComponent({
  name: 'ChangeHistory',

  components: {
    Box,
  },

  props: {
    history: {
      type: Array as PropType<ChangeHistoryData[]>,
      required: true,
    },
    activeObjectId: {
      type: String,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'history-item-clicked': (itemId: string): boolean => true,
  },

  setup(props, context) {
    const activeObjectId = toRef(props, 'activeObjectId');
    const edit = toRef(props, 'edit');
    const history = toRef(props, 'history');

    const headers = computed<DataTableHeader[]>(() => [
      {
        text: i18n.tc('history.time'),
        align: 'start',
        sortable: false,
        value: 'time',
      },
      {
        text: i18n.tc('history.type'),
        value: 'type',
      },
      {
        text: i18n.tc('history.author'),
        value: 'author',
      },
    ]);

    function isActive(item: GroupedChangeHistoryData): boolean {
      return activeObjectId.value === item.id;
    }

    function isClickable(item: GroupedChangeHistoryData): boolean {
      return !edit.value && !item.notClickable && !isActive(item);
    }

    const groupedHistoryData = computed<GroupedChangeHistoryData[]>(() => {
      return history.value.map((historyItem) => {
        const timestamp = moment(historyItem.timestamp).utc().format();
        return {
          id: historyItem.id,
          type: historyItem.type,
          notClickable: historyItem.notClickable,
          date: format(timestamp, MOMENT_WEEKDAY_DATE),
          time: timeStr(timestamp),
          author: getUserFullName(historyItem.author),
        };
      });
    });

    const activeTypeInfo = computed<string>(() => {
      if (edit.value) {
        return i18n.tc('history.editing');
      }
      return i18n.tc('history.current');
    });

    function onClickHistoryItem(item: GroupedChangeHistoryData) {
      if (isClickable(item)) {
        context.emit('history-item-clicked', item.id);
      }
    }

    return {
      activeTypeInfo,
      groupedHistoryData,
      headers,
      isActive,
      isClickable,
      onClickHistoryItem,
    };
  },
});
</script>

<style scoped>
.table {
  width: 100%;
  border-collapse: collapse;
}

.table ::v-deep th {
  height: 32px;
  color: var(--v-gray_second-base) !important;
}

.table > div > table > tbody > * {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.group-header {
  background: var(--v-gray_fourth-base);
  color: var(--v-gray_second-base);
}

.table ::v-deep td {
  font-size: 0.875rem;
  height: 32px;
  color: var(--v-gray_first-base);
}

.clickable {
  cursor: pointer;
}

.clickable-text {
  color: var(--main-theme-color-dark);
  text-decoration: underline;
}

.content {
  display: flex;
  flex-flow: column;
  font-size: 16px;
}

.content::v-deep .theme--light.v-label--is-disabled {
  color: var(--v-gray_first-base);
}

.full-width {
  width: 100%;
}
</style>
