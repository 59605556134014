import down from './down.svg';
import off from './off.svg';
import on from './on.svg';
import up from './up.svg';

export default {
  down,
  off,
  on,
  up,
};
