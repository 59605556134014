import check from './check.svg';
import nightModeOn from './nightModeOn.svg';
import plus from './plus.svg';
import X from './X.svg';

export default {
  check,
  nightModeOn,
  plus,
  X,
};
