<template>
  <v-dialog :value="isOpen" content-class="!max-h-7/10" persistent :max-width="maxWidth" @keydown.esc="$emit('cancel')">
    <v-card class="bg-gray_fifth" :data-test="dataTestId">
      <v-card-title v-if="title" class="title">{{ title }}</v-card-title>
      <v-card-text>
        <slot />
      </v-card-text>
      <v-card-actions v-if="!noAction" class="actions bg-gray_fifth">
        <v-spacer v-if="viewOnly" />
        <ActionButton
          class="actionButton"
          icon="symbol/cancel"
          :color="color"
          :disabled="loading"
          :data-test="dataTestId ? `${dataTestId}-modal-cancel` : undefined"
          @click="$emit('cancel')"
        />
        <template v-if="!viewOnly">
          <v-spacer />
          <ActionButton
            icon="symbol/check"
            :color="color"
            :text="confirmText"
            :is-loading="loading"
            :disabled="loading || disableConfirm"
            :data-test="dataTestId ? `${dataTestId}-modal-confirm` : undefined"
            @click="$emit('confirm')"
          />
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import ActionButton from '#/components/layout/ActionButton.vue';

export default defineComponent({
  name: 'Modal',

  components: {
    ActionButton,
  },

  props: {
    isOpen: {
      type: Boolean,
    },
    title: {
      type: String,
      default: undefined,
    },
    dataTestId: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
    },
    disableConfirm: {
      type: Boolean,
    },
    maxWidth: {
      type: Number,
      default: 400,
    },
    viewOnly: {
      type: Boolean,
    },
    color: {
      type: String,
      default: 'primary',
    },
    confirmText: {
      type: String,
      default: undefined,
    },
    noAction: {
      type: Boolean,
    },
  },

  emits: {
    cancel: (): boolean => true,
    confirm: (): boolean => true,
  },
});
</script>

<style scoped>
.title {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: keep-all;
  color: var(--v-gray_first-base);
}

.actions {
  padding: 10px 24px 16px;
  position: sticky;
  bottom: 0;
}
</style>
