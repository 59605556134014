<template>
  <div class="textField" :class="{ marked: mark }">
    <span class="titleWrapper">{{ $t('protocol.posLon') }}</span>
    <div class="border d-flex align-center rounded py-1 px-3 gray_first--text bg-white">
      <input
        ref="degreesRef"
        v-model="degrees"
        autofill="off"
        class="textInput text-right"
        data-test="input-longitude-degrees"
        type="text"
        @click="degreesRef?.select()"
      />
      <span class="mr-1">°</span>
      <input
        ref="minutesRef"
        v-model="minutes"
        data-test="input-longitude-minutes"
        autofill="off"
        class="textInput text-right"
        type="text"
        @click="minutesRef?.select()"
      />
      <span>.</span>
      <input
        ref="secondsRef"
        v-model="seconds"
        autofill="off"
        class="textInput"
        data-test="input-longitude-seconds"
        type="text"
        @click="secondsRef?.select()"
      />
      <span class="mr-1">'</span>
      <v-switch
        ref="directionRef"
        v-model="direction"
        class="direction mt-0 primary--text"
        dense
        hide-details
        false-value="E"
        true-value="W"
        :label="direction"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { Coordinate } from '@anschuetz-elog/common';
import { ComponentPublicInstance, computed, defineComponent, PropType, ref, toRef } from 'vue';

export default defineComponent({
  name: 'LongitudeField',
  props: {
    value: {
      type: Object as PropType<Coordinate | null>,
      default: null,
    },
    mark: {
      type: Boolean,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Coordinate | null): boolean => true,
  },
  setup(props, context) {
    const value = toRef(props, 'value');

    const secondsRef = ref<HTMLInputElement>();
    const minutesRef = ref<HTMLInputElement>();
    const degreesRef = ref<HTMLInputElement>();
    const directionRef = ref<ComponentPublicInstance>();

    const degrees = computed({
      get() {
        if (!value.value) {
          return '';
        }
        return value.value.degrees;
      },
      set(newDegrees: string) {
        emitLatitude(newDegrees, minutes.value, seconds.value, direction.value);
        if (newDegrees.length >= 3) {
          minutesRef.value?.select();
        }
      },
    });

    const minutes = computed({
      get() {
        if (!value.value) {
          return '';
        }
        return value.value.minutes;
      },
      set(newMinutes: string) {
        emitLatitude(degrees.value, newMinutes, seconds.value, direction.value);
        if (newMinutes.length >= 2) {
          secondsRef.value?.select();
        }
      },
    });

    const seconds = computed({
      get() {
        if (!value.value) {
          return '';
        }
        return value.value.seconds;
      },
      set(newSeconds: string) {
        emitLatitude(degrees.value, minutes.value, newSeconds, direction.value);
        if (newSeconds.length >= 3 && directionRef.value) {
          directionRef.value.$el.querySelector('input')?.select();
        }
      },
    });

    const direction = computed({
      get() {
        if (!value.value) {
          return 'E';
        }
        return value.value.direction as 'E' | 'W';
      },
      set(newDirection: 'E' | 'W') {
        emitLatitude(degrees.value, minutes.value, seconds.value, newDirection);
      },
    });

    function emitLatitude(degrees: string, minutes: string, seconds: string, direction: 'E' | 'W'): void {
      if (!degrees && !minutes && !seconds) {
        context.emit('input', null);
      } else {
        context.emit('input', {
          degrees,
          minutes,
          seconds,
          direction,
        });
      }
    }

    return { degrees, direction, minutes, seconds, degreesRef, minutesRef, directionRef, secondsRef };
  },
});
</script>

<style scoped>
.border {
  border-style: solid;
  border-width: 1px;
}

.titleWrapper {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  text-align: left;
}

.textField .textInput {
  font-size: 16px;
  background: var(--v-white-base);
  outline: none;
  flex: 1 1 auto;
  width: 100%;
  padding: 4px 0 2px;
}

.textField.marked .textInput {
  background-color: var(--v-error_bg-base);
}

.direction {
  min-width: 60px;
  max-width: 60px;
}
</style>
