import {
  Application,
  Book,
  BookKeyBundle,
  findAndLoadAll,
  isAccessToBookRole,
  isHeadOfBookRole,
  logger,
  User,
  UserKey,
} from '@anschuetz-elog/common';
import jsrsasign from 'jsrsasign';
import { differenceBy } from 'lodash';
import moment from 'moment';

import { addSignature } from './SigningUtils';

export const PASSPHRASE_ENCRYPTION_ALGORITHM = undefined as unknown as string; // TODO: awesome typescript

export function getDecryptedPassphrase(bookKeyBundle: BookKeyBundle, user: User, privateKey?: string): string {
  if (!privateKey) {
    throw new Error('Can not decrypt without private key');
  }
  const encryptedPassphrase = bookKeyBundle.keys.find((key) => key.user._id === user._id)?.passphrase;
  if (!encryptedPassphrase) {
    throw new Error(`Could not find passphrase for user ${user.name}`);
  }

  const passphrase = jsrsasign.KJUR.crypto.Cipher.decrypt(
    encryptedPassphrase,
    jsrsasign.KEYUTIL.getKey(privateKey) as jsrsasign.RSAKey,
    PASSPHRASE_ENCRYPTION_ALGORITHM,
  );

  return passphrase;
}

export function generatePassphrase(): string {
  return jsrsasign.KJUR.crypto.Util.getRandomHexOfNbytes(64);
}

export async function rotateBookKeyBundlePassphrase(
  newPassphrase: string,
  bookKeyBundle: BookKeyBundle,
  author: User,
  feathers: Application,
): Promise<{ bookKeyBundle: BookKeyBundle; newBookKeyBundle: BookKeyBundle }> {
  const newKeys = await Promise.all(
    bookKeyBundle.keys.map(async (key) => {
      const user = await feathers.service('user').get(key.user._id);
      if (!user.encryptionPublicKey) {
        return undefined;
      }
      return {
        ...key,
        passphrase: jsrsasign.KJUR.crypto.Cipher.encrypt(
          newPassphrase,
          jsrsasign.KEYUTIL.getKey(user.encryptionPublicKey) as jsrsasign.RSAKey,
          PASSPHRASE_ENCRYPTION_ALGORITHM,
        ),
      };
    }),
  );

  const now = new Date();
  bookKeyBundle.endTime = now.toISOString();

  const newBookKeyBundle = new BookKeyBundle({
    author: User.createRef(author._id),
    book: bookKeyBundle.book,
    keys: newKeys.filter((key): key is UserKey => key !== undefined),
    startTime: now.toISOString(),
    endTime: new Date(now.getTime() + 1000 * 60 * 60 * 24 * 90).toISOString(), // now + 30 days
  });

  return {
    bookKeyBundle,
    newBookKeyBundle,
  };
}

export function addUserToBookKeyBundle(
  admin: User,
  adminPrivateKey: string | undefined | null,
  user: User,
  bookKeyBundle: BookKeyBundle,
): { bookKeyBundle: BookKeyBundle } {
  if (!user.encryptionPublicKey || !adminPrivateKey) {
    // if user has no encryption key yet we can't add him
    return { bookKeyBundle };
  }
  const passphrase = getDecryptedPassphrase(bookKeyBundle, admin, adminPrivateKey);

  // skip if user is already in bookKeyBundle
  if (bookKeyBundle.keys.find((key) => key.user._id === user._id)) {
    return { bookKeyBundle };
  }

  bookKeyBundle.keys.push({
    user,
    passphrase: jsrsasign.KJUR.crypto.Cipher.encrypt(
      passphrase,
      jsrsasign.KEYUTIL.getKey(user.encryptionPublicKey) as jsrsasign.RSAKey,
      PASSPHRASE_ENCRYPTION_ALGORITHM,
    ),
    keyId: user.keyId,
  });

  return {
    bookKeyBundle,
  };
}

export async function removeUserFromBookKeyBundle(
  user: User,
  bookKeyBundle: BookKeyBundle,
  author: User,
  feathers: Application,
): Promise<{ bookKeyBundle: BookKeyBundle; newBookKeyBundle?: BookKeyBundle }> {
  const newPassphrase = generatePassphrase();

  bookKeyBundle.keys = bookKeyBundle.keys.filter((key) => key.user._id !== user._id);

  if (moment(bookKeyBundle.endTime).isBefore()) {
    return { bookKeyBundle };
  }

  return await rotateBookKeyBundlePassphrase(newPassphrase, bookKeyBundle, author, feathers);
}

export async function updateUserBookKeyBundles(
  app: Application,
  author: User,
  adminPrivateKey?: string | null,
  previousUser?: User,
  currentUser?: User,
): Promise<void> {
  const oldBookKeyBundles: BookKeyBundle[] = [];
  const newBookKeyBundles: BookKeyBundle[] = [];

  const user = previousUser || currentUser;
  if (!user) {
    throw new Error('previousUser or currentUser needs to be passed');
  }
  const oldRoles = previousUser?.roles || [];
  const newRoles = currentUser?.roles || [];

  const allBookKeyBundles: BookKeyBundle[] = [];
  await findAndLoadAll<BookKeyBundle>(app, 'book-key-bundle', (chunk) => {
    allBookKeyBundles.push(...chunk);
  });

  for await (const [i, roles] of [oldRoles, newRoles].entries()) {
    const changeBookKeyBundles = i === 0 ? oldBookKeyBundles : newBookKeyBundles;
    for await (const role of roles) {
      if (isAccessToBookRole(role) || isHeadOfBookRole(role)) {
        const books = (await app.service('book').find({
          query: {
            $disableSoftDelete: true,
            $or: [{ 'accessToBookRole._id': role._id }, { 'secondApprovalRole._id': role._id }],
          },
          paginate: false,
        })) as Book[];
        if (books.length !== 1) {
          logger.error(`Could not find book for role ${role.name}`, books);
          throw new Error(`Could not find book for role ${role.name}`);
        }
        const book = books[0];
        if (!book.confidential) {
          continue;
        }
        const bookKeyBundles = allBookKeyBundles.filter((bookKeyBundle) => bookKeyBundle.book._id === book._id);
        changeBookKeyBundles.push(...bookKeyBundles);
      }

      if (role.name === 'admin' || role.name === 'master') {
        changeBookKeyBundles.push(...allBookKeyBundles);
        break;
      }
    }
  }

  const bookKeyBundlesToAdd = differenceBy(newBookKeyBundles, oldBookKeyBundles, '_id');
  const bookKeyBundlesToRemove = differenceBy(oldBookKeyBundles, newBookKeyBundles, '_id');

  for await (const bookKeyBundle of bookKeyBundlesToAdd) {
    // do not add user to book key bundle if user is already part of it
    if (bookKeyBundle.keys.some((key) => key.user._id === user._id)) {
      continue;
    }

    const { bookKeyBundle: updatedBookKeyBundle } = addUserToBookKeyBundle(
      author,
      adminPrivateKey,
      user,
      bookKeyBundle,
    );
    addSignature(updatedBookKeyBundle);
    await app.service('book-key-bundle').update(updatedBookKeyBundle._id, updatedBookKeyBundle);
  }

  // do not remove user from book key bundles if user was just added
  for await (const bookKeyBundle of bookKeyBundlesToRemove) {
    if (bookKeyBundlesToAdd.some((b) => b._id === bookKeyBundle._id)) {
      continue;
    }

    const { bookKeyBundle: updatedBookKeyBundle, newBookKeyBundle } = await removeUserFromBookKeyBundle(
      user,
      bookKeyBundle,
      author,
      app,
    );
    addSignature(updatedBookKeyBundle);
    await app.service('book-key-bundle').update(updatedBookKeyBundle._id, updatedBookKeyBundle);
    if (newBookKeyBundle) {
      addSignature(newBookKeyBundle);
      await app.service('book-key-bundle').create(newBookKeyBundle);
    }
  }
}

export async function createNewBookKeyBundle(app: Application, admin: User, book: Book): Promise<BookKeyBundle> {
  const now = new Date();

  let users = (await app.service('user').find({ paginate: false })) as User[];
  users = users.filter((user) =>
    user.roles.some(
      (role) =>
        role.name === 'admin' ||
        role.name === 'master' ||
        role._id === book.accessToBookRole._id ||
        role._id === book.secondApprovalRole._id,
    ),
  );

  const passphrase = generatePassphrase();
  const bookKeyBundle = new BookKeyBundle({
    author: User.createRef(admin._id),
    book: Book.createRef(book._id),
    startTime: now.toISOString(),
    endTime: new Date(now.getTime() + 1000 * 60 * 60 * 24 * 30).toISOString(), // now + 30 days
    keys: users
      .map((user) => {
        if (!user.encryptionPublicKey) {
          return undefined;
        }
        return {
          user: User.createRef(user._id),
          passphrase: jsrsasign.KJUR.crypto.Cipher.encrypt(
            passphrase,
            jsrsasign.KEYUTIL.getKey(user.encryptionPublicKey) as jsrsasign.RSAKey,
            PASSPHRASE_ENCRYPTION_ALGORITHM,
          ),
          keyId: user.keyId,
        };
      })
      .filter((key): key is UserKey => key !== undefined),
  });
  addSignature(bookKeyBundle);
  return app.service('book-key-bundle').create(bookKeyBundle);
}
