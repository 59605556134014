import PouchDB from 'pouchdb-browser';

const PAGE_SIZE = 100000000;

type Protocol = { _id: string; gatewayAmendmentId: string };
type Approval = { _id: string };

type ApprovalMeta = { _id: `approval-${string}`; approvalId: string; flagsApplied: boolean };
type GatewayAmendmentMeta = { _id: `gatewayAmendment-${string}`; protocolId: string };

/**
 * This migration is there to initialize the offline-sync-meta pouch db by all already synced protocols and approvals.
 * Have a look at '../sync-meta.ts' for all the required information.
 */
export default async function offlineSyncMetaMigration(): Promise<void> {
  const syncMetaDb = new PouchDB<ApprovalMeta | GatewayAmendmentMeta>('offline-sync-meta');

  let skip = 0;
  let lastTotal = 1;
  const protocolDb = new PouchDB<Protocol>('protocol');
  while (lastTotal > skip) {
    const protocols = await protocolDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skip,
    });
    await syncMetaDb.bulkDocs(
      protocols.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Protocol> => doc !== undefined)
        .map(
          (protocol): GatewayAmendmentMeta => ({
            _id: `gatewayAmendment-${protocol.gatewayAmendmentId}`,
            protocolId: protocol._id,
          }),
        ),
    );
    lastTotal = protocols.total_rows;
    skip += PAGE_SIZE;
  }

  let skipApprovals = 0;
  let lastTotalApprovals = 1;
  const approvalDb = new PouchDB<Approval>('approval');
  while (lastTotalApprovals > skipApprovals) {
    const approvals = await approvalDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skipApprovals,
    });
    await syncMetaDb.bulkDocs(
      approvals.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Protocol> => doc !== undefined)
        .map(
          (approval): ApprovalMeta => ({
            _id: `approval-${approval._id}`,
            approvalId: approval._id,
            flagsApplied: false,
          }),
        ),
    );
    lastTotalApprovals = approvals.total_rows;
    skipApprovals += PAGE_SIZE;
  }
}
