import { Application, ErrorLog } from '@anschuetz-elog/common';
import { addGlobalEventProcessor, getCurrentHub } from '@sentry/core';
import { Event, Integration } from '@sentry/types';

export default class CustomOfflineIntegration implements Integration {
  private app: Application;

  constructor(app: Application) {
    this.app = app;
  }

  /**
   * @inheritDoc
   */
  public readonly name: string = CustomOfflineIntegration.id;

  /**
   * @inheritDoc
   */
  public static id = 'CustomOfflineIntegration';

  /**
   * @inheritDoc
   */
  public setupOnce(): void {
    addGlobalEventProcessor((event: Event) => {
      const self = getCurrentHub().getIntegration(CustomOfflineIntegration);
      if (self) {
        this._storeEvent(event);
        return event;
      }
      return event;
    });
  }

  private async _storeEvent(event: Event): Promise<void> {
    await this.app.service('error-log').create(new ErrorLog(event));
  }
}
