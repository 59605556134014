import { SyncState } from '@anschuetz-elog/common';
import { defineModule } from 'direct-vuex';

import logger from '@/logger';

const LS_LAST_SYNC_KEY = 'offline:websocket';

export interface OfflineState {
  lastSync: SyncState;
  isSyncPending: boolean;
}

export default defineModule({
  namespaced: true,

  state: (): OfflineState => {
    let lastSync: SyncState = {};
    const localStorageLastSync = localStorage.getItem(LS_LAST_SYNC_KEY);
    try {
      if (localStorageLastSync !== null) {
        lastSync = JSON.parse(localStorageLastSync) as SyncState;
      }
    } catch (error) {
      logger.error('Error at parsing last sync info from local storage: %s. Last sync info is reset.', error);
    }
    return {
      lastSync,
      isSyncPending: false,
    };
  },

  getters: {},

  mutations: {
    setLastSync(state, lastSync: SyncState): void {
      state.lastSync = lastSync;
      localStorage.setItem(LS_LAST_SYNC_KEY, JSON.stringify(state.lastSync));
    },
    setIsSyncPending(state: OfflineState, isSyncPending: boolean): void {
      state.isSyncPending = isSyncPending;
    },
  },

  actions: {},
});
