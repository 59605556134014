<template>
  <div class="searchAndFilter gray_fourth gray_first--text">
    <div class="iconAndTitle">
      <Icon :name="icon" class="icon" />
      <span class="title ml-2">{{ title }}</span>
    </div>

    <v-menu v-if="displayFilter" left offset-y :close-on-click="true" :close-on-content-click="false">
      <template #activator="{ on, attrs }">
        <div class="filter cursor-pointer pa-1" v-bind="attrs" v-on="on">
          <v-badge v-if="filterCount > 0" color="primary" :content="filterCount" overlap>
            <Icon name="action/filter" class="filterIcon customButtons" color="primary" />
          </v-badge>
          <Icon v-else name="action/filter" color="primary" class="filterIcon customButtons" />
        </div>
      </template>
      <v-list>
        <v-list-group v-if="users.length > 0" no-action color="gray_first">
          <template #prependIcon>
            <Icon name="person/user" />
          </template>
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="gray_first--text">{{ $t('author') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template #appendIcon>
            <Icon class="expand-icon" name="arrow/chevronDown" />
          </template>

          <v-list-item
            :class="{ 'font-italic': true, primary_bg: authorToFilter === null }"
            @click="emitAuthorIdForFiltering(null)"
          >
            <v-list-item-title class="gray_first--text">{{ $t('all_authors') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(user, index) in users"
            :key="index"
            :class="{ primary_bg: authorToFilter === user._id }"
            @click="emitAuthorIdForFiltering(user._id)"
          >
            <v-list-item-title class="gray_first--text">{{ user.userName }}</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-group v-if="voyages.length > 0" no-action color="gray_first">
          <template #prependIcon>
            <Icon name="logbook/plannedTrack" />
          </template>
          <template #activator>
            <v-list-item-content>
              <v-list-item-title class="gray_first--text">{{ $t('voyage.voyage') }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template #appendIcon>
            <Icon class="expand-icon" name="arrow/chevronDown" />
          </template>

          <v-list-item
            :class="{ 'font-italic': true, primary_bg: voyageToFilter === null }"
            @click="emitVoyageIdForFiltering(null)"
          >
            <v-list-item-title class="gray_first--text">{{ $t('all_voyage') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="(voyage, index) in transformedVoyages"
            :key="index"
            :class="{ primary_bg: voyageToFilter === voyage.id }"
            two-line
            @click="emitVoyageIdForFiltering(voyage.id)"
          >
            <v-list-item-content>
              <v-list-item-title class="gray_first--text">{{ voyage.label }}</v-list-item-title>
              <v-list-item-subtitle class="gray_first--text">{{ voyage.validity }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-menu>

    <SearchTextField v-if="!hideTextSearch" v-model="searchText" collapsable @collapsed="searchText = ''" />

    <DateRangePicker v-if="dateRangePicker" :date-range="dateRange" @filter-dates="updateFilterDates" />
  </div>
</template>

<script lang="ts">
import { Voyage } from '@anschuetz-elog/common';
import moment from 'moment';
import { computed, defineComponent, PropType, toRef, watch } from 'vue';

import DateRangePicker from '#/components/DateRangePicker.vue';
import SearchTextField from '#/components/SearchTextField.vue';
import useFeathers from '#/compositions/useFeathers';
import { useFilters } from '#/compositions/useFilters';
import { MOMENT_FORMATS } from '#/config/time';
import i18n from '#/i18n';
import { IconName } from '#/icons';

import Icon from './Icon.vue';

export default defineComponent({
  name: 'SearchAndFilter',
  components: { Icon, DateRangePicker, SearchTextField },

  props: {
    icon: {
      type: String as PropType<IconName>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    dateRangePicker: {
      type: Boolean,
    },
    startWithAllDates: {
      type: Boolean,
    },
    hideTextSearch: {
      type: Boolean,
    },
    withUserFilter: {
      type: Boolean,
    },
    voyages: {
      type: Array as PropType<Voyage[]>,
      default: () => [],
    },
    componentNameForFilter: {
      type: String,
      default: undefined,
    },
  },
  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'filter-dates': (_value: Date[]): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'filter-by-voyage': (_value: string | null): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'filter-by-author': (_value: string | null): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'search-text': (_value: string): boolean => true,
  },

  setup(props, context) {
    const feathers = useFeathers();

    const withUserFilter = toRef(props, 'withUserFilter');
    const voyages = toRef(props, 'voyages');
    const componentNameForFilter = toRef(props, 'componentNameForFilter');

    const { dateRange, authorToFilter, voyageToFilter, searchText } = useFilters(componentNameForFilter, {
      startWith: props.startWithAllDates ? 'all_dates' : undefined,
    });

    const users = computed(() => {
      if (!withUserFilter.value) {
        return [];
      }
      return feathers.get('localCache').getUsers();
    });

    const displayFilter = computed(() => {
      return users.value.length > 0 || voyages.value.length > 0;
    });

    const filterCount = computed(() => {
      let count = 0;
      if (authorToFilter.value) count++;
      if (voyageToFilter.value) count++;
      return count;
    });

    watch(
      dateRange,
      () => {
        context.emit(
          'filter-dates',
          dateRange.value.map((d) => d.toDate()),
        );
      },
      { immediate: true },
    );

    function updateFilterDates(dates: moment.Moment[]) {
      dateRange.value = dates;
    }

    function emitVoyageIdForFiltering(voyageId: string | null): void {
      context.emit('filter-by-voyage', voyageId);
      voyageToFilter.value = voyageId;
      const voyage = voyages.value.find((voyage) => voyage._id === voyageId);
      if (voyage) {
        updateFilterDates([moment(voyage.startTime), moment(voyage.endTime)]);
      }
    }

    function emitAuthorIdForFiltering(authorId: string | null): void {
      context.emit('filter-by-author', authorId);
      authorToFilter.value = authorId;
    }

    watch(searchText, () => {
      context.emit('search-text', searchText.value);
    });

    function createTimeStringFromVoyage(voyage: Voyage): string {
      const startTime = moment(voyage.startTime).format(MOMENT_FORMATS.dateTime);
      const endTime = voyage.endTime
        ? moment(voyage.endTime).format(MOMENT_FORMATS.dateTime)
        : i18n.tc('crew_list.still_valid');

      return `${startTime} - ${endTime}`;
    }
    const transformedVoyages = computed(() => {
      return voyages.value.map((voyage) => {
        return {
          id: voyage._id,
          label: i18n.tc('voyage.label', undefined, { voyageNumber: voyage.voyageNumber || '' }),
          validity: createTimeStringFromVoyage(voyage),
        };
      });
    });
    return {
      voyageToFilter,
      authorToFilter,
      searchText,
      emitAuthorIdForFiltering,
      emitVoyageIdForFiltering,
      filterCount,
      displayFilter,
      transformedVoyages,
      dateRange,
      updateFilterDates,
      users,
    };
  },
});
</script>

<style scoped>
.searchAndFilter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--v-gray_third-base) !important;
  padding: 3px 0;
  padding-left: 8px;
}

.searchAndFilter > * {
  margin: 3px 8px 3px auto;
}

.iconAndTitle {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.title {
  height: 32px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter {
  display: flex;
  align-items: center;
  border: 1px solid var(--v-primary-base) !important;
  border-radius: 2px;
  background-color: var(--v-white-base);
}

::v-deep .v-list-group__header.v-list-item--active .expand-icon {
  transform: rotate(180deg);
}
</style>
