import { Params } from '@feathersjs/feathers';
import { useFind as baseUseFind, ServiceModel, ServiceTypes, UseFind } from '@geprog/use-feathers';
import { Ref } from 'vue';

import useFeathers, { ClientApplication } from './useFeathers';

const useFind = baseUseFind<ClientApplication>(useFeathers());

export default <T extends keyof ServiceTypes<ClientApplication>, M = ServiceModel<ClientApplication, T>>(
  serviceName: T,
  params?: Ref<Params | null | undefined>,
  options?: Partial<{
    disableUnloadingEventHandlers: boolean;
    loadAllPages: boolean;
  }>,
): UseFind<M> => {
  return useFind<T, M>(serviceName, params, { loadAllPages: true, ...options });
};

// TODO: Within Typescript 4.7 the following should be possible which is more safe
// const useFind = baseUseFind<ClientApplication>(useFeathers());
// export default <T extends keyof ServiceTypes<ClientApplication>, M = ServiceModel<ClientApplication, T>>(
//   serviceName: Parameters<typeof useFind<T, M>>[0],
//   params?: Parameters<typeof useFind<T, M>>[1],
//   options?: Parameters<typeof useFind<T, M>>[2],
// ): ReturnType<typeof useFind<T, M>> => {
//   return useFind<T, M>(serviceName, params, { loadAllPages: true, ...options });
// };
