import { SystemStatus } from '@anschuetz-elog/common';
import { defineModule } from 'direct-vuex';

import { Debug } from '@/lib';

const debug = Debug('store:systemStatus');

export interface SystemStatusState {
  systemStatus: SystemStatus | null;
}

export default defineModule({
  namespaced: true,

  state: (): SystemStatusState => ({
    systemStatus: null,
  }),

  mutations: {
    updateSystemStatus(state, systemStatus: SystemStatus) {
      debug('updateSystemStatus', systemStatus);
      state.systemStatus = systemStatus;
    },
  },

  actions: {},
});
