import 'windi.css';
import '@/registerServiceWorker';
import './pages';

import { install as FrontendCorePlugin, loadDynamicConfig } from '@anschuetz-elog/frontend-core';
import Vue, { VNode } from 'vue';

import App from '@/App.vue';
import { connect } from '@/compositions/useFeathers';
import { load as backupLoad } from '@/lib/backup';
import { load as checkBackendIntegrity } from '@/lib/checkBackendIntegrity';
import { load as colorModeLoad } from '@/lib/colorMode';
import { load as healthStatusLoad } from '@/lib/health-status';
import { load as offlineLoad } from '@/lib/offline';
import { load as sentryLoad } from '@/lib/sentry';
import router from '@/router';
import store from '@/store';
import useFeathers from '#/compositions/useFeathers';

import logger from './logger';
import initServiceWorker from './registerServiceWorker';

logger.info('Loading eLog...');

const vueOptions = {
  router,
  store: store.original,
};
Vue.use(FrontendCorePlugin, vueOptions);

initServiceWorker();

Vue.use((): void => {
  const feathers = useFeathers();
  connect(feathers, true);
  loadDynamicConfig();
  sentryLoad();
  offlineLoad();
  healthStatusLoad();
  backupLoad();
  colorModeLoad();
  checkBackendIntegrity();
});

Vue.config.productionTip = false;

new Vue({
  ...vueOptions,
  render: (h): VNode => h(App),
}).$mount('#app');
