<template>
  <component :is="element.component" v-if="element.type === undefined" :class="element.class">
    {{ element.children }}
  </component>
  <ProtocolLinkContainer
    v-else
    :value="value"
    :element="element"
    :edit="edit"
    :link="link"
    @input="$emit('input', $event)"
  >
    <div
      :class="{
        'col-span-2': element.type === 'position' || element.type === 'combined' || element.type === 'group',
        anomalous: !!anomalyMessage,
      }"
    >
      <FormTextField
        v-if="element.type === 'text'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormTextAreaField
        v-else-if="element.type === 'textarea'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormNumberField
        v-else-if="element.type === 'number'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormDateTime
        v-else-if="element.type === 'datetime'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormPositionField
        v-else-if="element.type === 'position'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormAutoComplete
        v-else-if="element.type === 'autocomplete'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormCheckbox
        v-else-if="element.type === 'checkbox'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormRadioButton
        v-else-if="element.type === 'radio'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormCombinedField
        v-else-if="element.type === 'combined'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormComboBox
        v-else-if="element.type === 'combobox'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormEquipmentItem
        v-else-if="element.type === 'equipmentitem'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormGroup
        v-else-if="element.type === 'group'"
        :value="value"
        :element="element"
        :schema="schema"
        :edit="edit"
        :invalid="!isValid"
        :peer-dependency-schema="peerDependencySchema"
        :peer-dependency-value="peerDependencyValue"
        @input="$emit('input', $event)"
        @valid="onFieldValueInvalid(!$event)"
      />
      <FormObject
        v-else-if="element.type === 'object'"
        :value="value"
        :element="element"
        :edit="edit"
        :custom-validations="subCustomValidation"
        :invalid="!isValid"
        :allow-clear="element.allowClear"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormProtocolLink
        v-else-if="element.type === 'protocollink'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
        @protocollink:takeover-data="$emit('protocollink:takeover-data', $event)"
      />
      <FormCrewMember
        v-else-if="element.type === 'crewmember'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormAutoCalculation
        v-else-if="element.type === 'autocalculation'"
        :value="value"
        :element="element"
        :edit="edit"
        :parent-value="parentValue"
        :peer-dependency-value="peerDependencyValue"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @invalid="onFieldValueInvalid"
      />
      <FormSounding
        v-else-if="element.type === 'sounding'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @valid="onFieldValueInvalid(!$event)"
      />
      <FormTime
        v-else-if="element.type === 'time'"
        :value="value"
        :element="element"
        :edit="edit"
        :invalid="!isValid"
        @input="$emit('input', $event)"
        @valid="onFieldValueInvalid(!$event)"
      />
      <Fragment v-else />
      <div v-if="anomalyMessage" class="text-warning text-xs my-1 font-medium">{{ anomalyMessage }}</div>
      <div v-if="validationMessage" class="whitespace-pre-line">{{ validationMessage }}</div>
    </div>
  </ProtocolLinkContainer>
</template>

<script lang="ts">
import {
  autocalculation,
  ComponentElement,
  Element,
  Protocol,
  ProtocolContentData,
  Schema,
} from '@anschuetz-elog/common';
import { inRange, isInteger } from 'lodash';
import moment from 'moment';
import { computed, defineComponent, PropType, toRef, watch } from 'vue';

import useValidation from '#/compositions/useValidation';
import { MOMENT_TIME, MOMENT_TIME_WITH_SECONDS } from '#/config/time';
import i18n from '#/i18n';

import FormAutoCalculation from './FormAutoCalculation.vue';
import FormAutoComplete from './FormAutoComplete.vue';
import FormCheckbox from './FormCheckbox.vue';
import FormCombinedField from './FormCombinedField.vue';
import FormComboBox from './FormComboBox.vue';
import FormCrewMember from './FormCrewMember.vue';
import FormDateTime from './FormDateTime.vue';
import FormEquipmentItem from './FormEquipmentItem.vue';
import FormGroup from './FormGroup.vue';
import FormNumberField from './FormNumberField.vue';
import FormObject from './FormObject.vue';
import FormPositionField from './FormPositionField.vue';
import FormProtocolLink from './FormProtocolLink.vue';
import FormRadioButton from './FormRadioButton.vue';
import FormSounding from './FormSounding.vue';
import FormTextAreaField from './FormTextAreaField.vue';
import FormTextField from './FormTextField.vue';
import FormTime from './FormTime.vue';
import ProtocolLinkContainer from './ProtocolLinkContainer.vue';
import { applyAnomalyEvaluationFunction, CustomValidationFunction, ProtocolLinkConfigProp } from './utils';

export default defineComponent({
  name: 'FormInputElement',

  components: {
    FormAutoComplete,
    FormCheckbox,
    FormCombinedField,
    FormComboBox,
    FormEquipmentItem,
    FormGroup,
    FormNumberField,
    FormObject,
    FormPositionField,
    FormProtocolLink,
    FormSounding,
    FormTextField,
    FormTextAreaField,
    FormDateTime,
    ProtocolLinkContainer,
    FormCrewMember,
    FormAutoCalculation,
    FormTime,
    FormRadioButton,
  },

  props: {
    value: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    element: {
      type: Object as PropType<Element | ComponentElement>,
      required: true,
    },

    schema: {
      type: Array as PropType<Schema>,
      required: true,
    },

    parentValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    peerDependencySchema: {
      type: Array as PropType<Schema>,
      default: () => [],
    },

    peerDependencyValue: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default: () => ({}),
    },

    edit: {
      type: Boolean,
    },

    links: {
      type: Array as PropType<ProtocolLinkConfigProp[]>,
      default: () => [],
    },

    previousData: {
      type: Array as PropType<ProtocolContentData[]>,
      default: () => [],
    },

    customValidations: {
      type: Object as PropType<Record<string, CustomValidationFunction | Record<string, CustomValidationFunction>>>,
      default: () => ({}),
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    valid: (_valid: boolean) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    'protocollink:takeover-data': (_linkedProtocol: Protocol) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const links = toRef(props, 'links');
    const customValidations = toRef(props, 'customValidations');
    const previousData = toRef(props, 'previousData');
    const peerDependencyValue = toRef(props, 'peerDependencyValue');

    const { onValidation, isValid, isElementValid, resetValidation, getParams } = useValidation(emit);

    const link = computed(() => {
      return links.value.find(
        (link) => element.value.type !== undefined && link.source.elementId === element.value._id,
      );
    });

    const anomalyMessage = computed<string | undefined>(() => {
      if (!element.value.type || !element.value.anomalyCheck) {
        return undefined;
      }
      if (element.value.type === 'number' || element.value.type === 'autocalculation') {
        const elementValue = value.value[element.value.name];
        if (typeof elementValue !== 'number') {
          return undefined;
        }

        const { pastEntries, evaluationFunction, deviation } = element.value.anomalyCheck;

        const requiredPastEntries: number[] = [];

        let entriesCounter = 0;
        let protocolCounter = 0;
        while (entriesCounter < pastEntries && protocolCounter < previousData.value.length) {
          let previousElementValue = previousData.value[protocolCounter][element.value.name];

          if (typeof previousElementValue === 'number') {
            requiredPastEntries.push(previousElementValue);
            entriesCounter = entriesCounter + 1;
          }
          protocolCounter = protocolCounter + 1;
        }

        const referenceValue = applyAnomalyEvaluationFunction(evaluationFunction, requiredPastEntries);
        if (!referenceValue) {
          return undefined;
        }

        const deviationValue = (referenceValue * deviation) / 100;

        let diff = elementValue - referenceValue;
        if (!(Math.abs(diff) > deviationValue)) {
          return undefined;
        }
        return i18n.tc('protocol.anomaly_message', undefined, {
          value: referenceValue,
          percentage: deviation,
          function: i18n.tc(`settings.wizard.content_type_form.anomaly_check.${evaluationFunction}`),
        });
      } else {
        return undefined;
      }
    });

    function onFieldValueInvalid(invalid: boolean) {
      onValidation('value', !invalid);
    }

    function checkIfDuration(durationInput: string): boolean {
      if (!durationInput.includes(':')) return false; // invalid duration

      const durationArray = durationInput.split(':'); // splitting hh:mm into hh and mm

      const hours = durationArray[0];
      const hourCorrect = hours.length >= 2 && isInteger(Number(hours)) && Number(hours) >= 0; // first condition checks if the string is at least double digit and a number

      const minutes = durationArray[1];
      const minuteCorrect = minutes.length === 2 && isInteger(Number(minutes)) && inRange(Number(minutes), 0, 60); // minute range 0 - 59

      if (durationArray.length === 3) {
        // it is of format hh:mm:ss
        const seconds = durationArray[2];
        const secondCorrect = seconds.length === 2 && isInteger(Number(seconds)) && inRange(Number(seconds), 0, 60); // second rage range 0 - 59

        return hourCorrect && minuteCorrect && secondCorrect;
      }
      return hourCorrect && minuteCorrect;
    }

    watch(
      [element, value, isValid, customValidations],
      () => {
        if (element.value.type === undefined) {
          return;
        }
        const elementValue = value.value[element.value.name];

        resetValidation(['value']);
        const valueValid = isElementValid('value');
        if (valueValid) {
          (element.value.validation || []).forEach((rule) => {
            let valid = true;
            let params: Record<string, unknown> = {};
            switch (rule[0]) {
              case 'required':
                if (typeof elementValue === 'string') {
                  valid = !!elementValue.trim();
                } else if (typeof elementValue === 'boolean' && rule[1] === 'allowFalse') {
                  valid = true;
                } else if (typeof elementValue !== 'number') {
                  valid = !!elementValue;
                }
                break;
              case 'min': {
                const isNumber = typeof elementValue === 'number';
                params = { min: rule[1] };
                if (isNumber) {
                  const result = autocalculation.execute(`${rule[1]}`, {
                    ...value.value,
                    ...peerDependencyValue.value,
                  });
                  if (typeof result === 'number') {
                    valid = elementValue >= result;
                    params = { min: result };
                  } else {
                    // if the max formula can not be evaluated we define this value as value to not see weird error messages
                    valid = true;
                    params = {};
                  }
                } else {
                  valid = !isNumber;
                }
                break;
              }
              case 'max': {
                const isNumber = typeof elementValue === 'number';
                params = { max: rule[1] };
                if (isNumber) {
                  const result = autocalculation.execute(`${rule[1]}`, {
                    ...value.value,
                    ...peerDependencyValue.value,
                  });
                  if (typeof result === 'number') {
                    valid = elementValue <= result;
                    params = { max: result };
                  } else {
                    // if the max formula can not be evaluated we define this value as value to not see weird error messages
                    valid = true;
                    params = {};
                  }
                } else {
                  valid = !isNumber;
                }
                break;
              }
              case 'time':
                valid =
                  typeof elementValue !== 'string' ||
                  elementValue.trim().length === 0 ||
                  moment(elementValue, MOMENT_TIME_WITH_SECONDS).format(MOMENT_TIME_WITH_SECONDS) === elementValue ||
                  moment(elementValue, MOMENT_TIME).format(MOMENT_TIME) === elementValue ||
                  checkIfDuration(elementValue);
                break;
            }
            onValidation(rule[0], valid, params);
          });
          Object.entries(customValidations.value).forEach(([ruleName, validation]) => {
            if (typeof validation === 'function') {
              const { valid, errorMessage } = validation(elementValue);
              onValidation(ruleName, valid, { errorMessage });
            }
          });
        }
      },
      { immediate: true },
    );

    const subCustomValidation = computed<Record<string, Record<string, CustomValidationFunction>>>(() =>
      Object.entries(customValidations.value).reduce((allValidation, [objectName, validation]) => {
        if (typeof validation === 'object') {
          return { ...allValidation, [objectName]: validation };
        }
        return allValidation;
      }, {}),
    );

    const validationMessage = computed(() => {
      if (isValid.value || element.value.type === undefined) {
        return null;
      }
      if (!isElementValid('value') && !['object', 'group', 'sounding', 'combined'].includes(element.value.type)) {
        return i18n.tc(`form.validation.${element.value.type}`, undefined, {
          format: 'DD-MMM-YYYY HH:mm',
          ...element.value,
        });
      }
      if (!isElementValid('required')) {
        return i18n.tc('form.validation.required');
      }
      if (
        // check if both min and max constraints are set:
        // this is the case when we get params for both constraints
        Object.keys(getParams('min')).length > 0 &&
        Object.keys(getParams('max')).length > 0 &&
        (!isElementValid('min') || !isElementValid('max'))
      ) {
        const params = { ...getParams('min'), ...getParams('max') };
        if (params.min === params.max) {
          return i18n.tc('form.validation.exact', undefined, { exact: params.min });
        }
        return i18n.tc('form.validation.between', undefined, params);
      } else if (!isElementValid('min')) {
        return i18n.tc('form.validation.min', undefined, getParams('min'));
      } else if (!isElementValid('max')) {
        return i18n.tc('form.validation.max', undefined, getParams('max'));
      }
      if (!isElementValid('time')) {
        return i18n.tc('form.validation.time');
      }
      return Object.keys(customValidations.value).reduce<null | string>((message, ruleName) => {
        if (message === null && !isElementValid(ruleName)) {
          const errorMessage = getParams(ruleName)['errorMessage'];
          return typeof errorMessage === 'string' ? errorMessage : null;
        }
        return message;
      }, null);
    });

    return { link, onFieldValueInvalid, subCustomValidation, validationMessage, isValid, anomalyMessage };
  },
});
</script>
<style scoped>
.anomalous ::v-deep .v-input {
  background: var(--elog-warning_bg);
}
</style>
