<template>
  <div class="form-entry">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormEntry',
});
</script>

<style scoped>
.form-entry {
  margin: 0 8px 16px;
  text-align: left;
}
</style>
