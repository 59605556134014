import { RouteConfig } from 'vue-router';

import ChecklistRoutes from './checklist';
import ChecklistTypeRoutes from './checklistType';
import CrewRoutes from './crewList';
import EquipmentListRoutes from './equipmentList';
import OrderRoutes from './order';
import ProtocolRoutes from './protocol';
import ReportRoutes from './report';
import ResponsibilityRoutes from './responsibility';
import SettingsRoutes from './settings';
import SoundingRoutes from './sounding';
import VesselRoutes from './vessel';
import ViewRoutes from './views';
import VoyageRoutes from './voyage';

const routes: RouteConfig[] = [
  ...CrewRoutes,
  ...EquipmentListRoutes,
  ...ResponsibilityRoutes,
  ...OrderRoutes,
  ...ProtocolRoutes,
  ...ReportRoutes,
  ...SettingsRoutes,
  ...VesselRoutes,
  ...ViewRoutes,
  ...VoyageRoutes,
  ...SoundingRoutes,
  ...ChecklistTypeRoutes,
  ...ChecklistRoutes,
];

export { routes };
