import audio from './audio.svg';
import file from './file.svg';
import html from './html.svg';
import image from './image.svg';
import json from './json.svg';
import pdf from './pdf.svg';
import video from './video.svg';
import xls from './xls.svg';

export default {
  audio,
  file,
  html,
  image,
  json,
  pdf,
  video,
  xls,
};
