import PouchDB from 'pouchdb-browser';

const PAGE_SIZE = 1000;

/**
 * This migration copies all reports from the reports database to the protocols database.
 * Also rewrites all offline queue requests for reports to protocols.
 */
export async function reportsToProtocolsMigration(): Promise<void> {
  let skip = 0;
  let lastTotal = 1;
  const reportDb = new PouchDB('report');
  const protocolDb = new PouchDB('protocol');
  while (lastTotal > skip) {
    const reports = await reportDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skip,
    });
    await protocolDb.bulkDocs(
      reports.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Record<string, unknown>> => doc !== undefined),
    );
    lastTotal = reports.total_rows;
    skip += PAGE_SIZE;
  }

  skip = 0;
  lastTotal = 1;
  const offlineQueueDb = new PouchDB('offline-queue');
  while (lastTotal > skip) {
    const offlineQueue = await offlineQueueDb.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      limit: PAGE_SIZE,
      skip: skip,
    });
    await offlineQueueDb.bulkDocs(
      offlineQueue.rows
        .map((row) => row.doc)
        .filter((doc): doc is PouchDB.Core.ExistingDocument<Record<string, unknown>> => doc !== undefined)
        .map((doc) => {
          if (doc.service === 'report') {
            doc.service = 'protocol';
          }
          return doc;
        }),
    );
    lastTotal = offlineQueue.total_rows;
    skip += PAGE_SIZE;
  }

  await reportDb.destroy();
}
