<template>
  <Fragment>
    <div v-if="element.label" class="flex flex-row w-full pt-2 font-600 text-gray_second text-xl">
      <span class="flex"> {{ element.label }}</span>
      <Icon
        v-if="allowClear"
        name="action/delete"
        color="primary"
        class="flex-shrink-0 cursor-pointer mt-1 ml-3"
        @click.native="data = undefined"
      />
    </div>
    <div class="flex flex-col pb-2" :data-test="`object-${element.name}`">
      <Form
        v-model="data"
        :schema="schema"
        :edit="edit"
        class="flex-grow"
        :custom-validations="customValidations"
        @valid="$emit('invalid', !$event)"
      />
    </div>
  </Fragment>
</template>

<script lang="ts">
import { logger, ObjectElement } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from '#/components/Icon.vue';

import Form from './Form.vue';
import { CustomValidationFunction } from './utils';

export default defineComponent({
  name: 'FormObject',

  components: { Form, Icon },

  props: {
    element: {
      type: Object as PropType<ObjectElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    customValidations: {
      type: Object as PropType<Record<string, Record<string, CustomValidationFunction>>>,
      default: () => ({}),
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },

    allowClear: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const schema = computed(() => element.value.children || []);

    const data = computed<Record<string, unknown> | undefined>({
      get: () => {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'object') {
          logger.error(
            'Value does not fit to object element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }

        if (v === undefined || v === null) {
          return undefined;
        }

        return v as Record<string, unknown>;
      },
      set: (newData) => {
        emit('input', { ...value.value, [element.value.name]: newData });
      },
    });

    return { data, schema };
  },
});
</script>
