import Vue from 'vue';
import VueI18n from 'vue-i18n';

import en from './en.json';

Vue.use(VueI18n);

const getI18n = (locale = 'en'): VueI18n =>
  new VueI18n({
    locale,
    fallbackLocale: 'en',
    messages: {
      en,
    },
  });

export default getI18n();
