export const MOMENT_DATE = 'DD-MMM-YYYY';
export const MOMENT_TIME = 'HH:mm';
export const MOMENT_TIME_MIN_SEC = 'mm:ss';
export const MOMENT_TIME_WITH_SECONDS = 'HH:mm:ss';
export const MOMENT_DATE_TIME = `${MOMENT_DATE} ${MOMENT_TIME}`;
export const MOMENT_DATE_TIME_WITH_SECONDS = `${MOMENT_DATE} ${MOMENT_TIME_WITH_SECONDS}`;
export const MOMENT_WEEKDAY_DATE = 'ddd, Do MMM';
export const MOMENT_WEEKDAY_DATE_YEAR = 'ddd, Do MMM YYYY';
export const MOMENT_FILE_TIMESTAMP = 'YYYYMMDD_HHmmSS';
export const MOMENT_DATE_TIME_WITH_TIMEZONE = `${MOMENT_DATE}T${MOMENT_TIME_WITH_SECONDS}.SSSZ`;

export const MOMENT_FORMATS = {
  date: MOMENT_DATE,
  time: MOMENT_TIME,
  timeMinSec: MOMENT_TIME_MIN_SEC,
  timeSeconds: MOMENT_TIME_WITH_SECONDS,
  dateTime: MOMENT_DATE_TIME,
  dateTimeSeconds: MOMENT_DATE_TIME_WITH_SECONDS,
  weekdayDate: MOMENT_WEEKDAY_DATE,
  fileTimestamp: MOMENT_FILE_TIMESTAMP,
  dateTimeSecondsTimezone: MOMENT_DATE_TIME_WITH_TIMEZONE,
};
