<template>
  <ProtocolEntry v-if="edit">
    <NumberField
      v-model="sog"
      :title="$tc('protocol.sog')"
      :placeholder="$tc('protocol.sog_placeholder')"
      :mark="invalid"
      suffix="kn"
      data-test="text-sog"
    />
    <NumberField
      v-model="cog"
      :title="$tc('protocol.cog')"
      :placeholder="$tc('protocol.cog_placeholder')"
      :mark="invalid"
      suffix="°"
      data-test="text-cog"
    />
  </ProtocolEntry>
  <DetailsListEntry v-else :title="$tc('protocol.sogCog')" :content="readModeDisplayValue" />
</template>

<script lang="ts">
import { SogCogTransformer } from '@anschuetz-elog/common';
import { computed, defineComponent, ref, toRef, watch } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import NumberField from '#/components/NumberField.vue';
import ProtocolEntry from '#/components/ProtocolEntry.vue';

export default defineComponent({
  name: 'SogCogField',

  components: {
    DetailsListEntry,
    ProtocolEntry,
    NumberField,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    edit: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string | undefined): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const sogCogRef = ref(SogCogTransformer.parseSogCog(value.value));

    const invalidRef = ref<boolean>(false);
    const invalid = computed(() => invalidRef.value);
    watch(value, () => {
      sogCogRef.value = SogCogTransformer.parseSogCog(value.value);
    });

    watch(sogCogRef, () => {
      const sogCogString = SogCogTransformer.sogCogToString(sogCogRef.value);
      invalidRef.value = sogCogString === false;
      if (sogCogString !== false) {
        context.emit('input', sogCogString);
      }
      context.emit('invalid', invalidRef.value);
    });

    const sog = computed<string | undefined>({
      get() {
        return sogCogRef.value.sog !== null ? `${sogCogRef.value.sog}` : undefined;
      },
      set(newSog) {
        sogCogRef.value = { ...sogCogRef.value, sog: newSog !== undefined ? `${newSog}` : null };
      },
    });

    const cog = computed<string | undefined>({
      get() {
        return sogCogRef.value.cog !== null ? `${sogCogRef.value.cog}` : undefined;
      },
      set(newCog) {
        sogCogRef.value = { ...sogCogRef.value, cog: newCog !== undefined ? `${newCog}` : null };
      },
    });

    const readModeDisplayValue = computed(() => {
      if (value.value) {
        return SogCogTransformer.format(value.value);
      }
      return '';
    });

    return {
      invalid,
      sog,
      cog,
      readModeDisplayValue,
      sogCogRef,
    };
  },
});
</script>
