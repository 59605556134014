import jsPDF from 'jspdf';
import { CellData } from 'jspdf-autotable';

import { GeneralInformationForAuditLogPDFTable, GeneralInformationForProtocolsPDFTable } from '#/export/pdf/types';

import * as style from './style';

export const drawTableBorder = (
  doc: jsPDF,
  opts: { x: number; y: number; width: number; color: number[] | string },
): void => {
  const { x, y, width, color } = opts;
  doc.setLineWidth(style.LINE_AND_BORDER_WIDTH);
  if (Array.isArray(color)) {
    doc.setDrawColor(color[0], color[1], color[2]);
  } else {
    doc.setDrawColor(color);
  }
  doc.line(x, y, x + width, y);
};

export const drawSeparationLine = (
  doc: jsPDF,
  opts: { y: number; color: number[]; skipNewPageCheck?: boolean },
): number => {
  const y = opts.skipNewPageCheck ? opts.y : checkForNewPage(doc, opts.y);
  doc.setLineWidth(style.LINE_AND_BORDER_WIDTH);
  doc.setDrawColor(opts.color[0], opts.color[1], opts.color[2]);
  doc.line(style.SEPARATION_LINE_MARGIN_LEFT, y, style.SEPARATION_LINE_LENGTH, y);
  return y;
};

export const checkForNewPage = (doc: jsPDF, y: number): number => {
  if (y > style.DOCUMENT_HEIGHT - style.TABLE_DATA_MARGIN_BOTTOM - 20) {
    doc.addPage();
    return style.TABLE_DATA_Y_NEW_PAGE;
  }
  return y;
};

export const drawLineThrough = (doc: jsPDF, cellData: CellData): void => {
  // extract content and only draw line if content is there
  const content = typeof cellData.raw === 'string' ? cellData.raw : cellData.raw.content;
  if (content.length) {
    // define styling
    doc.setLineWidth(style.LINE_AND_BORDER_WIDTH);
    doc.setDrawColor(style.TABLE_DATA_DELETED_COLOR);
    const {
      x,
      y,
      styles: { cellPadding },
    } = cellData;
    // calculate number of lines and line height based on cell content
    const lines = doc.splitTextToSize(content, Math.ceil(cellData.width - 2 * cellPadding)) as string[];
    const lineHeight = (cellData.contentHeight - 2 * cellPadding) / lines.length;
    // draw each line horizontally centered
    for (let i = 0; i < lines.length; i++) {
      doc.line(
        x + cellPadding,
        y + cellPadding + lineHeight * (i + 0.5),
        x + cellPadding + doc.getTextWidth(lines[i]),
        y + cellPadding + lineHeight * (i + 0.5),
      );
    }
  }
};

export const writeTableTitle = (doc: jsPDF, opts: { y: number; title: string }): void => {
  doc.setFont(style.TABLE_DATA_TITLE_FONT_TYPE);
  doc.setTextColor(style.TABLE_DATA_TITLE_FONT_COLOR);
  doc.setFillColor(style.TABLE_DATA_TITLE_BACKGROUND_COLOR);
  doc.rect(style.CONTENT_MARGIN_LEFT, opts.y, style.TABLE_DATA_WIDTH, style.TABLE_DATA_TITLE_HEIGHT, 'F');
  doc.setFontSize(style.TABLE_DATA_TITLE_FONT_SIZE);
  doc.text(
    opts.title,
    style.CONTENT_MARGIN_LEFT + style.TABLE_DATA_HEADER_PADDING_LEFT,
    opts.y + style.TABLE_DATA_TITLE_HEIGHT / 2,
    {
      baseline: 'middle',
    },
  );
};

export const drawMarks = (doc: jsPDF): void => {
  style.FOLDING_MARKS_Y.forEach((markY) => {
    doc.setLineWidth(style.FOLDING_MARKS_WIDTH);
    doc.setDrawColor(style.FOLDING_MARKS_COLOR[0], style.FOLDING_MARKS_COLOR[1], style.FOLDING_MARKS_COLOR[2]);
    doc.line(0, markY, style.FOLDING_MARKS_LENGTH, markY);
  });
  doc.setLineWidth(style.PERFORATE_MARK_WIDTH);
  doc.setDrawColor(style.PERFORATE_MARK_COLOR[0], style.PERFORATE_MARK_COLOR[1], style.PERFORATE_MARK_COLOR[2]);
  doc.line(0, style.PERFORATE_MARK_Y, style.PERFORATE_MARK_LENGTH, style.PERFORATE_MARK_Y);
};

export const getAppliedFilter = (
  generalInfo: GeneralInformationForProtocolsPDFTable | GeneralInformationForAuditLogPDFTable,
): string => {
  return `${
    generalInfo.filter.dateRange[0] !== generalInfo.filter.dateRange[1]
      ? `${generalInfo.filter.dateRange[0]} - ${generalInfo.filter.dateRange[1]}`
      : generalInfo.filter.dateRange[0]
        ? generalInfo.filter.dateRange[0]
        : ''
  }${generalInfo.filter.searchText ? `, ${generalInfo.filter.searchText}` : ''}`;
};
