import { defineModule } from 'direct-vuex';

import logger from '@/logger';

export interface ServiceWorkerState {
  updating: boolean;
}

export default defineModule({
  namespaced: true,

  state: (): ServiceWorkerState => ({
    updating: false,
  }),

  getters: {},

  mutations: {
    setUpdating(state: ServiceWorkerState, updating: boolean): void {
      logger.info('Setting new updating flag for service worker in store: %s', updating);
      state.updating = updating;
    },
  },

  actions: {},
});
