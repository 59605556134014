import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

import { prefixRoutes } from '#/utilities';

const reportRoutes: RouteConfig[] = [
  {
    path: '',
    name: 'reports',
    component: () => import('#/views/protocol/Protocols.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
  },
  {
    path: 'new',
    name: 'report-new',
    component: () => import('#/views/protocol/ProtocolCreate.vue'),
    props: true,
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.PROTOCOL } },
  },
  {
    path: ':protocolId',
    name: 'report',
    component: () => import('#/views/protocol/ProtocolDetails.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.PROTOCOL } },
  },
  {
    path: ':protocolId/edit',
    name: 'report-edit',
    component: () => import('#/views/protocol/ProtocolEdit.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.PROTOCOL } },
  },
  {
    path: ':protocolId/delete',
    name: 'report-delete',
    component: () => import('#/views/protocol/ProtocolDelete.vue'),
    props: true,
    meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.PROTOCOL } },
  },
];

export default prefixRoutes('/reports/:protocolTypeId', reportRoutes);
