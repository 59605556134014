import { createDirectStore } from 'direct-vuex';
import Vue from 'vue';
import Vuex from 'vuex';

import modules from './modules';

Vue.use(Vuex);

const { store } = createDirectStore({
  modules: {
    ...modules,
  },
});

// Export the direct-store instead of the classic Vuex store.
export default store;
