<template>
  <v-btn
    class="actionButton"
    :class="{ [color]: true, disabled }"
    :loading="isLoading"
    :disabled="disabled || isLoading"
    @click="doClick"
  >
    <slot>
      <Icon v-if="internalIcon" :name="internalIcon" color="white" />
      <span v-if="text" class="text">{{ text }}</span>
    </slot>
  </v-btn>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';
import { Location } from 'vue-router';

import Icon from '#/components/Icon.vue';
import { useRouter } from '#/compositions/useRouter';
import { IconName } from '#/icons';

export default defineComponent({
  name: 'ActionButton',

  components: { Icon },

  props: {
    icon: {
      type: String as PropType<IconName>,
      default: undefined,
    },

    backIconStyling: {
      type: Boolean,
    },

    disabled: {
      type: Boolean,
    },

    to: {
      type: Object as PropType<Location | null>,
      default: null,
    },

    text: {
      type: String,
      default: '',
    },

    isLoading: {
      type: Boolean,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },

  setup(props, context) {
    const icon = toRef(props, 'icon');
    const backIconStyling = toRef(props, 'backIconStyling');
    const to = toRef(props, 'to');
    const disabled = toRef(props, 'disabled');

    const router = useRouter();

    const internalIcon = computed<IconName | false>(() => {
      if (icon.value) {
        return icon.value;
      }
      if (backIconStyling.value) {
        if (to.value?.name === 'home') {
          return 'action/home';
        }
        return 'arrow/cornerLeftUp';
      }

      return false;
    });

    function doClick(): void {
      if (disabled.value) {
        return;
      }
      if (to.value) {
        void router.push(to.value);
        return;
      }

      context.emit('click');
    }

    return { internalIcon, doClick };
  },
});
</script>

<style scoped>
.v-btn.actionButton {
  position: relative;
  display: flex;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: 54px;
  border-radius: 4px;
  color: var(--v-white-base);
  cursor: pointer;
}

.v-btn.actionButton.disabled {
  background: var(--v-gray_fourth-base) !important;
  cursor: not-allowed !important;
}

@media only screen and (max-width: 599.9px) {
  /** odd number to fit to windicss screen sizes */
  .v-btn.actionButton {
    box-shadow: none;
    min-width: 0;
  }
}

.v-btn.actionButton::before {
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: inherit;
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  content: '';
}

.v-btn.actionButton:hover::before {
  opacity: 0.1;
}

.text {
  margin-left: 8px;
}
</style>
