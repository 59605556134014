import {
  AbstractEntity,
  Application,
  OfflineServiceKeys,
  SYNC_CHECKPOINT_INTERVAL,
  SyncCompleteResponse,
  SyncErrorResponse,
  SyncStateResponse,
} from '@anschuetz-elog/common';
import { i18n } from '@anschuetz-elog/frontend-core';
import { Service } from '@feathersjs/feathers';
import { v4 as uuidV4 } from 'uuid';
import Vue from 'vue';

import { connect } from '@/compositions/useFeathers';
import logger from '@/logger';
import store from '@/store';
import { resetApp } from '@/utilities';
import useFeathers, { createFeathersClient } from '#/compositions/useFeathers';

import { applyApprovals } from './approval-mapper';
import { cleanDatabase } from './databases';
import { ErrorName, ignoreError } from './error';
import { OfflineQueueActionError, pushOfflineQueue } from './offline-queue';
import { trackSyncMetaData } from './sync-meta';
import { RegisteredOfflineServiceMap } from './types';

async function errorHandler<T extends AbstractEntity>(
  operation: () => Promise<T | T[]>,
  serviceName: string,
  method: string,
  item: T | T[],
  errorToIgnore: ErrorName,
): Promise<void> {
  try {
    await operation();
  } catch (error) {
    if (ignoreError(error, errorToIgnore)) {
      return;
    }
    logger.error(
      'Error in service %s (method %s) at handling a synchronized element %s: %s',
      serviceName,
      method,
      Array.isArray(item) ? item.map((i) => i._id).join(',') : item._id,
      error,
    );
  }
}

async function syncDatabases(services: RegisteredOfflineServiceMap): Promise<void> {
  if (Object.keys(services).length === 0) {
    return;
  }

  store.commit.offline.setIsSyncPending(true);

  await pushOfflineQueue();

  await cleanDatabase('alert');

  const syncClient = createFeathersClient();
  connect(syncClient);
  let errorAlreadyHandled = false;
  const syncId = uuidV4();
  const feathers = useFeathers();
  try {
    trackSyncMetaData(syncClient, { whileSyncPending: true });
    const serviceFinisher: Record<string, () => void> = {};
    (Object.keys(services) as OfflineServiceKeys[]).map((serviceName) => {
      const service = syncClient.service(serviceName);
      const offlineService = feathers.service(serviceName) as unknown as Service<AbstractEntity>;
      let itemsToCreate: AbstractEntity[] = [];
      service.on('created', (item) => {
        itemsToCreate.push(item);
        if (itemsToCreate.length > SYNC_CHECKPOINT_INTERVAL) {
          const items = [...itemsToCreate];
          itemsToCreate = [];
          void errorHandler(
            () => offlineService.create(items, { onlineEvent: true }),
            serviceName,
            'create',
            items,
            'conflict',
          );
        }
      });
      service.on('updated', (item) => {
        void errorHandler(
          () => offlineService.update(item._id, item, { onlineEvent: true }),
          serviceName,
          'update',
          item,
          'conflict',
        );
      });
      service.on('patched', (item) => {
        void errorHandler(
          () => offlineService.patch(item._id, item, { onlineEvent: true }),
          serviceName,
          'patch',
          item,
          'conflict',
        );
      });
      service.on('removed', (item) => {
        void errorHandler(
          () => offlineService.remove(item._id, { onlineEvent: true }),
          serviceName,
          'remove',
          item,
          'not_found',
        );
      });
      serviceFinisher[serviceName] = () => {
        if (itemsToCreate.length > 0) {
          const items = [...itemsToCreate];
          itemsToCreate = [];
          void errorHandler(
            () => offlineService.create(items, { onlineEvent: true }),
            serviceName,
            'create',
            items,
            'conflict',
          );
        }
      };
    });

    syncClient
      .service('sync')
      .on('syncstate', (response: SyncStateResponse) => {
        if (response.syncId !== syncId) {
          return;
        }
        const newSyncState = {
          ...store.state.offline.lastSync,
          [response.syncStateKey]: response.syncStateValue,
        };
        if (response.syncStateKey === 'gateway') {
          ['protocol', 'report', 'approval', 'record-file', 'voyage', 'crewList', 'book-key-bundle'].forEach(
            (service) => {
              if (serviceFinisher[service]) {
                serviceFinisher[service]();
              }
            },
          );
        } else if (serviceFinisher[response.syncStateKey]) {
          serviceFinisher[response.syncStateKey]();
        }
        store.commit.offline.setLastSync(newSyncState);
      })
      .on('complete', (response: SyncCompleteResponse) => {
        if (response.syncId !== syncId) {
          return;
        }
        void finishSync(feathers, syncClient, services);
        if (Object.keys(response.errors).length > 0) {
          Vue.toasted.error(
            i18n.t('general.partial_sync_error', {
              services: Object.keys(response.errors).join(', '),
            }) as string,
            {
              duration: 8000,
            },
          );
        }
      })
      .on('error', (response: SyncErrorResponse) => {
        if (response.syncId !== syncId) {
          return;
        }
        errorAlreadyHandled = true;
        void finishSync(feathers, syncClient, services);
        void handleSyncError(response.error, services, i18n.tc('general.sync_error'));
      });
    await syncClient.service('sync').update(syncId, store.state.offline.lastSync);
  } catch (error) {
    if (!errorAlreadyHandled) {
      syncClient.emit('disconnect');
      store.commit.offline.setIsSyncPending(false);
      throw error;
    }
  }
}

async function handleSyncError(
  error: unknown,
  services: RegisteredOfflineServiceMap,
  dialogMessage: string,
): Promise<void> {
  logger.error('Error at synchronizing app: %s', error);
  let dialog;
  try {
    // open dialog and ask user to proceed
    if (error instanceof OfflineQueueActionError) {
      dialogMessage = i18n.tc('general.sync_offline_queue_error');
    }
    dialog = await Vue.dialog.confirm(dialogMessage, {
      loader: true,
      customClass: 'dialog',
      okText: i18n.tc('general.try_again'),
      cancelText: i18n.tc('about.reset_app'),
    });
  } catch (err) {
    // Clicked on cancel, so we reset the app
    await resetApp();
    return;
  }
  //  clicked on okText, so we try again
  dialog.close();
  void safeDatabaseSync(services);
}

async function finishSync(
  normalClient: Application,
  syncClient: Application,
  services: RegisteredOfflineServiceMap,
): Promise<void> {
  syncClient.emit('disconnect');
  try {
    await applyApprovals(normalClient);
  } catch (error) {
    void handleSyncError(error, services, '');
  }
  store.commit.offline.setIsSyncPending(false);
}

/**
 *  Try to sync the database
 *    if an error occurs print the error and ask the user to:
 *      - try again
 *      - reset the app
 *
 */
export async function safeDatabaseSync(services: RegisteredOfflineServiceMap): Promise<void> {
  try {
    await syncDatabases(services);
  } catch (err) {
    void handleSyncError(err, services, i18n.tc('general.sync_error'));
  }
}
