<template>
  <DetailsListEntry
    class="ma-0"
    :custom-content-styles="{ 'py-2': edit, 'bg-error_bg': invalid }"
    :title="element.label"
    :content="modelToDisplay"
    :suffix="model ? element.unit : undefined"
    data-test="show-output"
  />
</template>

<script lang="ts">
import { autocalculation, AutoCalculationElement } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, ref, toRef, watch } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import i18n from '#/i18n';

export default defineComponent({
  name: 'FormAutoCalculation',

  components: {
    DetailsListEntry,
  },

  props: {
    element: {
      type: Object as PropType<AutoCalculationElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    parentValue: {
      type: Object as PropType<Record<string, unknown>>,
      default: () => ({}),
    },

    peerDependencyValue: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },

  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');
    const parentValue = toRef(props, 'parentValue');
    const peerDependencyValue = toRef(props, 'peerDependencyValue');

    const formula = ref<string>(element.value.formula);

    const model = computed(() => {
      try {
        return autocalculation.execute(formula.value, {
          ...value.value,
          ...parentValue.value,
          ...peerDependencyValue.value,
        });
      } catch {
        return undefined;
      }
    });

    watch(
      model,
      () => {
        emit('input', {
          ...value.value,
          [element.value.name]: model.value,
        });
      },
      { immediate: true },
    );

    watch(value, () => {
      if (value.value[element.value.name] !== model.value) {
        // it can happen that multiple autocalculation elements want to update their value
        // in parallel which leads to discarded values because the "value" prop
        // does not get updated so fast. That's why we are checking constantly if the
        // formula result is still the correct one in the value. If not then we
        // emit the formula result again.
        emit('input', {
          ...value.value,
          [element.value.name]: model.value,
        });
      }
    });

    const modelToDisplay = computed(() =>
      model.value === undefined ? i18n.tc('form.auto_calculation.not_computable') : model.value,
    );

    return { modelToDisplay, model };
  },
});
</script>
