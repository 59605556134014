import { OfflineServiceKeys } from '@anschuetz-elog/common';
import PouchDB from 'pouchdb-browser';

import logger from '@/logger';

type BaseEntity = { _id: string };

export type Database<Content extends BaseEntity> = PouchDB.Database<Content>;

export const OFFLINE_QUEUE_NAME = 'offline-queue';
export const OFFLINE_SYNC_META = 'offline-sync-meta';

type AllowedDatabases = OfflineServiceKeys | typeof OFFLINE_QUEUE_NAME | typeof OFFLINE_SYNC_META;

export const databases = new Map<AllowedDatabases, Database<BaseEntity>>();

export function loadDB<Content extends BaseEntity>(dbName: AllowedDatabases): Database<Content> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const database = new PouchDB<Content>(dbName, { auto_compaction: true });
  (database as unknown as { _remote: boolean })._remote = false;
  databases.set(dbName, database);
  return database;
}

export function getDB<Content extends BaseEntity>(dbName: AllowedDatabases): Database<Content> | undefined {
  return databases.get(dbName) as Database<Content>;
}

async function destroyDB(dbName: AllowedDatabases): Promise<void> {
  const db = getDB(dbName);
  await db?.destroy();
  databases.delete(dbName);
}

export async function clearDatabases(): Promise<void> {
  try {
    await Promise.all(
      Array.from(databases.keys()).map(async (db) => {
        try {
          await destroyDB(db);
        } catch (error) {
          logger.error('Error at deleting database %s', db, error);
        }
      }),
    );
  } catch (error) {
    logger.error(error);
  }
}

export async function resetDatabases(): Promise<void> {
  try {
    await Promise.all(
      Array.from(databases.keys()).map(async (db) => {
        try {
          await destroyDB(db);
          loadDB(db);
        } catch (error) {
          logger.error('Error at deleting database %s', db, error);
        }
      }),
    );
  } catch (error) {
    logger.error(error);
  }
}

export async function getDatabasesInfo(): Promise<PouchDB.Core.DatabaseInfo[]> {
  return await Promise.all(Array.from(databases.values()).map((db) => db.info()));
}

export async function cleanDatabase(name: AllowedDatabases): Promise<void> {
  const db = getDB(name);
  if (!db) {
    return;
  }
  const docs = await db.allDocs();
  await Promise.all(docs.rows.map((row) => db.remove(row.id, row.value.rev)));
}
