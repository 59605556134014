<template>
  <Box
    v-if="isInstallationWizardActive && activeInstallationWizardStep && !activeInstallationWizardStep.done"
    :title="activeInstallationWizardStep.title"
    class="border-4 border-secondary_light w-[700px] mx-auto"
    title-centered
  >
    <div class="p-2 flex flex-col items-center">
      <div class="mb-auto" />

      <p class="text-center">
        {{ activeInstallationWizardStep.description }}
      </p>

      <Button
        v-if="!activeInstallationWizardStep.hideDone"
        :text="$tc('done')"
        color="secondary_light"
        @click="finishActiveInstallationWizardStep"
      />
    </div>
  </Box>
</template>

<script lang="ts">
import { Box, Button } from '@anschuetz-elog/frontend-core';
import { storeToRefs } from 'pinia';
import { defineComponent } from 'vue';

import { useInstallationWizard } from '@/compositions/useInstallationWizard';

export default defineComponent({
  name: 'ActiveStep',
  components: { Box, Button },

  setup() {
    const installationWizard = useInstallationWizard();
    const { isInstallationWizardActive, activeInstallationWizardStep } = storeToRefs(installationWizard);

    return {
      isInstallationWizardActive,
      activeInstallationWizardStep,
      finishActiveInstallationWizardStep: installationWizard.finishActiveInstallationWizardStep,
    };
  },
});
</script>
