<template>
  <tr>
    <td />
    <td colspan="100%">
      <div class="expandedWrapper px-2">
        <div v-if="protocol.details.length" class="columnWrapper">
          <span v-t="'details'" class="headerStyle" />
          <br />
          <ProtocolContentTypeDetails :details="protocol.details" />
        </div>
        <div v-if="showExpandedAuthor" class="columnWrapper">
          <span v-t="'author'" class="headerStyle" />
          <br />
          <span :class="{ contentStyle: true, crossed: isProtocolDeleted(protocol) }">{{ protocol.author }}</span>
        </div>
        <div v-if="showExpandedChange && getProtocolChangedValue(protocol) !== '0'" class="columnWrapper">
          <span v-t="'change'" class="headerStyle" />
          <br />
          <div class="changeWrapper">
            <span>{{ getProtocolChangedValue(protocol) }}</span>
          </div>
        </div>
      </div>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import ProtocolContentTypeDetails from '#/components/protocol/ProtocolContentTypeDetails.vue';
import { DataForProtocolDraftTable, DataForProtocolTable } from '#/types';

export default defineComponent({
  name: 'ProtocolExpanded',

  components: { ProtocolContentTypeDetails },

  props: {
    protocol: {
      type: Object as PropType<DataForProtocolTable | DataForProtocolDraftTable>,
      required: true,
    },
    showExpandedAuthor: {
      type: Boolean,
    },
    showExpandedChange: {
      type: Boolean,
    },
  },

  setup() {
    function isDataForProtocolTable(
      protocol: DataForProtocolDraftTable | DataForProtocolTable,
    ): protocol is DataForProtocolTable {
      return (protocol as DataForProtocolTable).unknownPropertiesPresent !== undefined;
    }

    function isProtocolDeleted(protocol: DataForProtocolDraftTable | DataForProtocolTable) {
      return isDataForProtocolTable(protocol) ? protocol.deleted : false;
    }

    function getProtocolChangedValue(protocol: DataForProtocolDraftTable | DataForProtocolTable) {
      return isDataForProtocolTable(protocol) ? protocol.change : undefined;
    }

    return { isDataForProtocolTable, isProtocolDeleted, getProtocolChangedValue };
  },
});
</script>

<style scoped>
.headerStyle {
  text-align: left;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  height: 18px;
  padding: 0px;
}

.expandedWrapper {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 8px;
}

.columnWrapper {
  white-space: normal;
  padding-right: 16px;
}

.changeWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  color: var(--v-gray_second-base);
  font-weight: 600;
  font-size: 14px;
  border-width: 1px;
  border-style: solid;
}
.crossed {
  text-decoration: line-through;
}

.contentStyle {
  text-align: left;
  font-size: 16px;
}
</style>
