import afterArrival from './afterArrival.svg';
import approaching from './approaching.svg';
import arrival from './arrival.svg';
import beforeDeparture from './beforeDeparture.svg';
import checklist from './checklist.svg';
import departure from './departure.svg';
import lineHandling from './lineHandling.svg';
import mooring from './mooring.svg';
import sailingOff from './sailingOff.svg';
import swinging from './swinging.svg';

export default {
  afterArrival,
  arrival,
  approaching,
  beforeDeparture,
  checklist,
  departure,
  lineHandling,
  mooring,
  sailingOff,
  swinging,
};
