import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { AnyAbility } from '@casl/ability';
import { Location } from 'vue-router';

import i18n from '#/i18n';
import { IconName } from '#/icons';
import dynamicConfig from '#/lib/dynamicConfig';

export type Page = {
  id: string;
  text: string;
  icon: IconName;
  hidden?: boolean;
  action: Location | (() => void);
};

export type PageGroup = {
  title: string;
  color: string;
  icon: IconName;
  buttons: Page[];
};

type AdditionalPages = { before: Page[]; after: Page[] };
export type AdditionalPagesHook = (ability: AnyAbility) => AdditionalPages;

let additionalSettingsPagesHook: undefined | AdditionalPagesHook = undefined;
export const registerAdditionalSettingsPages = (hook: AdditionalPagesHook): void => {
  additionalSettingsPagesHook = hook;
};

let additionalInformationPagesHook: undefined | AdditionalPagesHook = undefined;
export const registerAdditionalInformationPages = (hook: AdditionalPagesHook): void => {
  additionalInformationPagesHook = hook;
};

export const getPages = (ability: AnyAbility): PageGroup[] => {
  const isExternalConfigured = !!dynamicConfig('EXTERNAL_CONFIGURATION');
  const isNotPTCEnvironment = !dynamicConfig('PTC_MASTER_ENVIRONMENT');
  const canReadUser = ability.can('read', AUTH_RESOURCES.USER);

  const canReadVesselParticulars = ability.can('read', AUTH_RESOURCES.VESSEL_PARTICULARS);
  const canReadCrewList = ability.can('read', AUTH_RESOURCES.CREW_LIST);
  const canReadResponsibility = ability.can('read', AUTH_RESOURCES.RESPONSIBILITY);
  const canReadVoyage = ability.can('read', AUTH_RESOURCES.VOYAGE);
  const canManageWizard = ability.can('manage', AUTH_RESOURCES.WIZARD);
  const canReadEquipment = ability.can('read', AUTH_RESOURCES.EQUIPMENT);
  const canReadOrders = ability.can('read', AUTH_RESOURCES.ORDER);
  const canReadSoundingSpots = ability.can('read', AUTH_RESOURCES.SOUNDING_SPOT);
  const canManageBookConfig = ability.can('manage', AUTH_RESOURCES.BOOK_CONFIGURATION);
  const canManageChecklists = ability.can('manage', AUTH_RESOURCES.CHECKLIST_TYPE);

  const additionalSettingsPages = additionalSettingsPagesHook
    ? additionalSettingsPagesHook(ability)
    : { before: [], after: [] };

  const additionalInformationPages = additionalInformationPagesHook
    ? additionalInformationPagesHook(ability)
    : { before: [], after: [] };

  return [
    {
      title: i18n.tc('vessel_and_crew'),
      color: 'secondary',
      icon: 'logbook/vesselAndCrew',
      buttons: [
        {
          id: 'vesselParticulars',
          text: i18n.tc('vessel.vessel_particulars'),
          icon: 'logbook/vesselParticulars',
          hidden: !canReadVesselParticulars,
          action: { name: 'vessel' },
        },
        {
          id: 'crewList',
          text: i18n.tc('crew_list.crew_list'),
          icon: 'logbook/crew',
          hidden: !canReadCrewList,
          action: { name: 'crew-lists' },
        },
        {
          id: 'responsibility',
          text: i18n.tc('responsibility'),
          icon: 'logbook/responsibility',
          hidden: !canReadResponsibility,
          action: { name: 'responsibilities' },
        },
        {
          id: 'voyageStatus',
          text: i18n.tc('voyage_status'),
          icon: 'logbook/voyageStatus',
          hidden: !canReadVoyage,
          action: { name: 'voyages' },
        },
        {
          id: 'equipment',
          text: i18n.tc('equipment_label'),
          icon: 'logbook/equipment',
          hidden: !canReadEquipment,
          action: { name: 'equipment' },
        },
        {
          id: 'orders',
          text: i18n.tc('order.tile_button_label'),
          icon: 'logbook/order',
          hidden: !canReadOrders,
          action: { name: 'orders' },
        },
        {
          id: 'soundingSpot',
          text: i18n.tc('sounding_spot.tile_button'),
          icon: 'logbook/soundingSpots/soundings',
          hidden: !canReadSoundingSpots,
          action: { name: 'sounding-spots' },
        },
        {
          id: 'checklists',
          text: i18n.tc('checklist.title'),
          icon: 'action/new-content-type',
          hidden: !canManageChecklists,
          action: { name: 'checklist-types' },
        },
      ],
    },
    {
      title: i18n.tc('settings.settings'),
      color: 'secondary_light',
      icon: 'action/settings',
      buttons: [
        ...additionalSettingsPages.before,
        {
          id: 'settings-users',
          text: i18n.tc('user.management'),
          icon: 'action/userManagement',
          hidden: isExternalConfigured || !canReadUser,
          action: { name: 'settings-users' },
        },

        ...additionalSettingsPages.after,
        {
          id: 'wizard',
          text: i18n.tc('settings.wizard.wizard'),
          icon: 'action/wizard',
          hidden: isNotPTCEnvironment || isExternalConfigured || !canManageWizard,
          action: { name: 'settings-wizard' },
        },
        {
          id: 'book-configuration',
          text: i18n.tc('settings.book_configuration.book_configuration'),
          icon: 'logbook/book',
          hidden: !canManageBookConfig,
          action: { name: 'settings-book-configuration' },
        },
      ],
    },
    {
      title: i18n.tc('information'),
      color: 'gray_second',
      icon: 'action/info',
      buttons: [
        ...additionalInformationPages.before,
        {
          id: 'auditLog',
          text: i18n.tc('auditLog.name'),
          icon: 'logbook/auditLog',
          action: { name: 'audit-log' },
        },
        ...additionalInformationPages.after,
      ],
    },
  ];
};
