import jsPDF from 'jspdf';

import {
  montserratBoldString,
  montserratItalicString,
  montserratMediumString,
  montserratRegularString,
  montserratSemiBoldString,
} from './fontStrings';

export default function addFonts(pdfDocument: jsPDF): jsPDF {
  pdfDocument.addFileToVFS('MontserratRegular.ttf', montserratRegularString);
  pdfDocument.addFont('MontserratRegular.ttf', 'MontserratRegular', 'normal');

  pdfDocument.addFileToVFS('MontserratMedium.ttf', montserratMediumString);
  pdfDocument.addFont('MontserratMedium.ttf', 'MontserratMedium', 'normal');

  pdfDocument.addFileToVFS('MontserratSemiBold.ttf', montserratSemiBoldString);
  pdfDocument.addFont('MontserratSemiBold.ttf', 'MontserratSemiBold', 'normal');

  pdfDocument.addFileToVFS('MontserratBold.ttf', montserratBoldString);
  pdfDocument.addFont('MontserratBold.ttf', 'MontserratBold', 'normal');

  pdfDocument.addFileToVFS('MontserratItalic.ttf', montserratItalicString);
  pdfDocument.addFont('MontserratItalic.ttf', 'MontserratItalic', 'normal');
  return pdfDocument;
}
