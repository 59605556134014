<template>
  <TextField
    v-model="innerValue"
    :title="title"
    :placeholder="placeholder"
    :mark="invalidNumber || mark"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, toRef } from 'vue';

import TextField from '#/components/TextField.vue';

export default defineComponent({
  name: 'NumberField',

  components: { TextField },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String] as PropType<number | string | undefined>,
      default: undefined,
    },
    mark: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: number | string | undefined): boolean => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_value: boolean): boolean => true,
  },

  setup(props, context) {
    const value = toRef(props, 'value');

    const invalidNumber = ref<boolean>(false);

    onMounted(() => {
      context.emit('invalid', Number.isNaN(Number(innerValue.value)));
    });

    const innerValue = computed<string>({
      get() {
        return value.value === undefined ? '' : value.value.toString();
      },
      set(newValue: string) {
        if (newValue.trim() === '') {
          invalidNumber.value = false;
          context.emit('input', undefined);
          context.emit('invalid', false);
          return;
        }
        const numericValue = Number(newValue);
        invalidNumber.value = Number.isNaN(numericValue);
        if (!invalidNumber.value) {
          context.emit('input', numericValue);
        } else {
          context.emit('input', newValue);
        }
        context.emit('invalid', invalidNumber.value);
      },
    });

    return { innerValue, invalidNumber };
  },
});
</script>

<style scoped>
::v-deep input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

::v-deep input[type='number']::-webkit-inner-spin-button,
::v-deep input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
