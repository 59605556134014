import { ActionManager, User } from '@anschuetz-elog/common';
import { defineModule, localActionContext } from 'direct-vuex';
import { throttle } from 'lodash';
import ms from 'ms';
import Vue from 'vue';

import useFeathers from '#/compositions/useFeathers';
import i18n from '#/i18n';
import dynamicConfig from '#/lib/dynamicConfig';
import logger from '#/logger';

const LS_LOGIN_REDIRECT_URL = 'auth:login-redirect-url';
export const LS_AUTH_TOKEN = 'auth:token';
const LS_AUTH_KEY = 'auth:key';
const LS_AUTH_ENCRYPTION_KEY = 'auth:encryption-key';

/** auto logout offset before session timeout for some cleanup tasks e.g. save records as drafts */
const AUTO_LOGOUT_OFFSET = '10s';

export const ONLINE_RECONNECT_STRATEGY = 'onlineReconnect';

export interface AuthState {
  user: User | null;
  key: string | null;
  encryptionKey: string | null;
  sessionExpiredObserver: number | null;
  loginRedirectUrl: string | null; // url of the original location a user request before having to log in
  accessToken: string | null;
}

type BeforeLogoutHandler = () => void | Promise<void>;
const beforeLogoutHandlers: BeforeLogoutHandler[] = [];

export function addBeforeLogoutHandler(handler: BeforeLogoutHandler): void {
  if (!beforeLogoutHandlers.includes(handler)) {
    // avoid double handlers
    beforeLogoutHandlers.push(handler);
  }
}

export function removeBeforeLogoutHandler(handler: BeforeLogoutHandler): void {
  const index = beforeLogoutHandlers.indexOf(handler);
  if (index !== -1) {
    beforeLogoutHandlers.splice(index, 1);
  }
}

const reAuthenticateAtServer = throttle(async (isConnected: boolean, isAuthenticated: boolean) => {
  if (!isConnected || !isAuthenticated) {
    return;
  }
  try {
    await useFeathers().reAuthenticate(true, ONLINE_RECONNECT_STRATEGY);
  } catch (error) {
    // ignore error as most likely not authenticated
  }
}, 10 * 1000);

const auth = defineModule({
  namespaced: true,

  state: (): AuthState => {
    return {
      user: null,
      key: localStorage.getItem(LS_AUTH_KEY) || null,
      encryptionKey: localStorage.getItem(LS_AUTH_ENCRYPTION_KEY) || null,
      sessionExpiredObserver: null,
      loginRedirectUrl: localStorage.getItem(LS_LOGIN_REDIRECT_URL) || null,
      accessToken: localStorage.getItem(LS_AUTH_TOKEN) || null,
    };
  },

  getters: {
    isAuthenticated: (state) => {
      return !!state.user;
    },
    userId: (state) => {
      if (!state.user) {
        throw new Error('Error getting the userId. The user is not set.');
      }
      return state.user._id;
    },
  },

  mutations: {
    setUser(state, user: User | null): void {
      // update abilities whenever user changes
      const ability = ActionManager.getReference().getAbility(user, {
        ptcMaster: dynamicConfig('PTC_MASTER_ENVIRONMENT') || false,
      });
      Vue.prototype.$ability.update(ability.rules);
      if (!user) {
        state.user = null;
        state.key = null;
        state.encryptionKey = null;
        state.accessToken = null;
        localStorage.removeItem(LS_AUTH_KEY);
        localStorage.removeItem(LS_AUTH_ENCRYPTION_KEY);
        return;
      }
      state.user = user;
    },
    setKey(state, key: string | null): void {
      state.key = key;
      // remove key if it was cleared
      if (!key) {
        localStorage.removeItem(LS_AUTH_KEY);
        return;
      }
      localStorage.setItem(LS_AUTH_KEY, key);
    },
    setEncryptionKey(state, encryptionKey: string | null): void {
      state.encryptionKey = encryptionKey;
      // remove authToken if it was cleared
      if (!encryptionKey) {
        localStorage.removeItem(LS_AUTH_ENCRYPTION_KEY);
        return;
      }

      localStorage.setItem(LS_AUTH_ENCRYPTION_KEY, encryptionKey);
    },
    setAccessToken(state, accessToken: string | null): void {
      state.accessToken = accessToken;
    },
    setSessionExpiredObserver(state, sessionExpiredObserver: number | null): void {
      state.sessionExpiredObserver = sessionExpiredObserver;
    },
    setLoginRedirectUrl(state, loginRedirectUrl: string | null): void {
      state.loginRedirectUrl = loginRedirectUrl;

      // remove redirectUrl if it was cleared
      if (!loginRedirectUrl) {
        localStorage.removeItem(LS_LOGIN_REDIRECT_URL);
        return;
      }

      localStorage.setItem(LS_LOGIN_REDIRECT_URL, loginRedirectUrl);
    },
  },

  actions: {
    /**
     * Start a session-expired observer which logs out inactive users.
     */
    startSessionExpiredObserver(context): void {
      const { commit, state, dispatch } = localActionContext(context, auth);
      // if a session-expired observer is already running stop it to be able to start a new one
      if (state.sessionExpiredObserver !== null) {
        clearInterval(state.sessionExpiredObserver);
      }

      // auto logout after session duration
      const sessionExpiredObserver = window.setTimeout(
        () => {
          Vue.toasted.error(i18n.t('general.session_timeout') as string, {
            position: 'bottom-center',
            duration: 3000,
          });
          void dispatch.logout();
        },
        ms(dynamicConfig('SESSION_TIMEOUT')) - ms(AUTO_LOGOUT_OFFSET),
      );

      commit.setSessionExpiredObserver(sessionExpiredObserver);
    },

    stopSessionExpiredObserver(context): void {
      const { commit, state } = localActionContext(context, auth);
      if (state.sessionExpiredObserver !== null) {
        clearInterval(state.sessionExpiredObserver);
        commit.setSessionExpiredObserver(null);
      }
    },

    /**
     * Load user details into store and refresh current user session.
     */
    async loadSession(context): Promise<void> {
      const { commit, dispatch, state } = localActionContext(context, auth);
      const noSessionBefore = state.user === null; // only init the local cache if no user was set before
      try {
        await useFeathers().reAuthenticate();
      } catch (error) {
        commit.setUser(null);
        return;
      }
      // start session-expired observer
      await dispatch.startSessionExpiredObserver();
      if (noSessionBefore && import.meta.env.VITE_APP_MODE !== 'cloud') {
        void useFeathers().get('localCache').init();
      }

      commit.setAccessToken(await useFeathers().authentication.getAccessToken());
      if (import.meta.env.VITE_APP_MODE !== 'cloud') {
        if (!state.key) {
          await dispatch.logout();
          throw new Error('Missing user key');
        }
        // we know we are authenticated now so we try to forward the auth token to the server
        const reAuthenticationPromise = reAuthenticateAtServer(context.rootGetters['websocket/isConnected'], true);
        if (noSessionBefore) {
          await reAuthenticationPromise;
        }
      }
      // finally load and cache the authenticated user
      const user = await dispatch.getUser();
      commit.setUser(user);
    },

    /**
     * Get the currently logged in user or null if no one is logged in
     */
    async getUser(): Promise<User | null> {
      try {
        const result = await useFeathers().get('authentication');
        return result.user;
      } catch (error) {
        return null;
      }
    },

    /**
     * Perform login check of username and password and start user session if successful
     * @param username Username to log in
     * @param password Password to log in
     */
    async login(context, { userName, password }: { userName: string; password: string }): Promise<boolean> {
      const { commit, dispatch } = localActionContext(context, auth);

      const feathers = useFeathers();
      try {
        const result = await feathers.authenticate({
          strategy: 'gateway',
          username: userName,
          password,
        });
        commit.setKey(result.user?.privateKey);
        commit.setEncryptionKey(result.user?.encryptionPrivateKey);
        void feathers.get('localCache').init();
        await dispatch.loadSession();
      } catch (error) {
        // If we got an error, show the login page
        logger.error('Error at login', error);
        commit.setUser(null);
        return false;
      }
      return true;
    },

    /**
     * Stop the current user session and log him out.
     */
    async logout(context): Promise<void> {
      const { commit, dispatch } = localActionContext(context, auth);

      await Promise.all(
        beforeLogoutHandlers.map(async (handler) => {
          removeBeforeLogoutHandler(handler);
          try {
            await handler();
          } catch (error) {
            logger.error('Error happens when execute before logout handler: %s', error);
          }
        }),
      );

      await useFeathers().logout();
      await dispatch.stopSessionExpiredObserver();
      commit.setLoginRedirectUrl(null); // clear login redirect url
      // unset user data
      commit.setUser(null);
      Vue.toasted.info(i18n.t('general.logged_out') as string, {
        position: 'bottom-center',
        duration: 3000,
      });
    },
  },
});

export default auth;
