import initials from './initials.svg';
import user from './user.svg';
import userCheck from './userCheck.svg';
import userMinus from './userMinus.svg';
import userPlus from './userPlus.svg';
import userX from './userX.svg';

export default {
  initials,
  user,
  userCheck,
  userMinus,
  userPlus,
  userX,
};
