import {
  ChecklistType,
  Element,
  EquipmentItemElement,
  FIXED_PROTOCOL_CONTENT_TYPE_IDS,
  FIXED_PROTOCOL_TYPE_IDS,
  GenericProtocolContentData,
  getChecklistSchema,
  getElementValue,
  getEquipmentItemSchema,
  getRepeatedSchema,
  isProtocolLink,
  ManualSounding,
  PositionTransformer,
  Protocol,
  ProtocolContentData,
  ProtocolContentType,
  ProtocolContentTypeConfig,
  ProtocolLink,
  ProtocolLinkConfig,
  ProtocolType,
  Ref,
  Schema,
  SogCogTransformer,
  TypeOfSounding,
} from '@anschuetz-elog/common';
import moment from 'moment';

import { getProtocolLinkValue } from '#/components/form/utils';
import useFeathers from '#/compositions/useFeathers';
import i18n from '#/i18n';
import logger from '#/logger';
import { Line, ProtocolContentTypeDetails } from '#/types';
import { dateTimeStr, format } from '#/utilities';

function equipmentItemDetails(element: EquipmentItemElement, equipmentItemId: string): string {
  const feathers = useFeathers();
  try {
    const item = feathers.get('localCache').getEquipmentItem(equipmentItemId);
    if (!element.showItemData) {
      return item.name;
    }
    const list = feathers.get('localCache').getEquipmentList(element.equipmentList);
    const itemDetails = schemaToDetails(list.itemSchema, item.itemData, []);
    if (itemDetails.length === 0) {
      return item.name;
    }
    return `${item.name}, ${linesAsString(itemDetails)}`;
  } catch (error) {
    return i18n.tc('unknown_equipment_item');
  }
}

export function schemaToDetails(schema: Schema, data: ProtocolContentData, links?: ProtocolLinkConfig[]): Line[] {
  const feathers = useFeathers();

  const values = schema
    .map((element) => {
      if (!element.type || element.ignoreForRecord) {
        return null;
      }
      let value = data[element.name];

      if (value === undefined) {
        return null;
      }
      const link = (links || []).find((link) => link.elementId === element._id);
      if (link) {
        if (typeof value !== 'string') {
          value = (value as { protocol: Ref<Protocol>; value: string | number | undefined }).value || '--';
        }
      } else if (element.type === 'group') {
        const groupDetails = (value as Record<string, unknown>[])
          .map((groupData, i) => {
            const groupSchema =
              element.children && typeof element.children === 'function'
                ? element.children(groupData, i)
                : element.children || [];
            const itemDetails = groupSchema
              .map((groupElement) => {
                if (!groupElement.type || groupElement.ignoreForRecord) {
                  return null;
                }
                let groupValue = groupData[groupElement.name];
                if (groupValue === undefined) {
                  return null;
                }
                if (groupElement.type === 'equipmentitem') {
                  groupValue = equipmentItemDetails(groupElement, groupValue as string);
                } else if (groupElement.type === 'position') {
                  groupValue = PositionTransformer.format(groupValue as string);
                } else if (groupElement.type === 'autocomplete') {
                  if (groupElement.showItemLabel) {
                    groupValue = groupElement.items.find((item) => item.value === groupValue)?.label || groupValue;
                  }
                }
                const details = { label: `${groupElement.label}`, value: groupValue };
                if (
                  (groupElement.type === 'number' || groupElement.type === 'autocalculation') &&
                  groupElement.unit !== undefined
                ) {
                  return { ...details, value: `${groupValue} ${groupElement.unit}` };
                }
                return details;
              })
              .filter((item) => item !== null);
            if (itemDetails.length === 0) {
              return null;
            }
            return { label: `${element.repeatableLabel} ${i + 1}`, value: itemDetails };
          })
          .filter((item) => item !== null);
        return { label: `${element.label}`, value: groupDetails };
      } else if (element.type === 'equipmentitem') {
        value = equipmentItemDetails(element, value as string);
      } else if (element.type === 'position') {
        value = PositionTransformer.format(value as string);
      } else if (element.type === 'protocollink') {
        value = getProtocolLinkValue(value as ProtocolLink);
      } else if (element.type === 'combined') {
        const [left, right] = element.parts;
        let [leftValue, rightValue] = (value as string).split('/');
        if (left.type === 'autocomplete') {
          if (left.showItemLabel) {
            leftValue = left.items.find((item) => item.value === leftValue)?.label || leftValue;
          }
        }
        if (right.type === 'autocomplete') {
          if (right.showItemLabel) {
            rightValue = right.items.find((item) => item.value === rightValue)?.label || rightValue;
          }
        }
        const leftUnit =
          (left.type === 'number' || left.type === 'autocalculation') && left.unit ? ` ${left.unit}` : '';
        const rightUnit =
          (right.type === 'number' || right.type === 'autocalculation') && right.unit ? ` ${right.unit}` : '';
        value = `${leftValue}${leftUnit}/${rightValue}${rightUnit}`;
      } else if (element.type === 'checkbox') {
        value = i18n.tc('form.checkbox.yes');
      } else if (element.type === 'time') {
        value = moment.utc(value as number).format('HH:mm');
      } else if (element.type === 'datetime') {
        value = element.format ? format(value as string, element.format) : dateTimeStr(value as string);
      } else if (element.type === 'sounding') {
        const soundingType: Record<TypeOfSounding, string> = {
          sounding: i18n.tc('form.sounding.sounding_abbreviation'),
          ullage: i18n.tc('form.sounding.ullage_abbreviation'),
        };
        const soundings = (value as ManualSounding)
          .filter((sounding) => sounding.value !== undefined)
          .map<Line>((sounding) => {
            const soundingSpot = feathers.get('localCache').getSoundingSpot(sounding.soundingSpot._id);
            return {
              label: soundingSpot.name,
              value: `${sounding.value} ${soundingSpot.unit} ${soundingType[soundingSpot.typeOfSounding]}`,
            };
          });
        if (soundings.length === 0) {
          return null;
        }
        value = soundings;
      } else if (element.type === 'autocomplete') {
        if (element.showItemLabel) {
          value = element.items.find((item) => item.value === value)?.label || value;
        }
      }
      const details = { label: `${element.label || element.name}`, value: value };
      if ((element.type === 'number' || element.type === 'autocalculation') && element.unit !== undefined) {
        return { ...details, value: `${value} ${element.unit}` };
      }

      return details;
    })
    .filter((text): text is Line => text !== null);
  return values;
}

function getProtocolContentTypeDetails(
  protocol: Protocol,
  contentTypeConfig: ProtocolContentTypeConfig,
  data: ProtocolContentData,
): ProtocolContentTypeDetails[] {
  const feathers = useFeathers();

  let contentType: ProtocolContentType;
  try {
    contentType = feathers.get('localCache').getProtocolContentType(contentTypeConfig.ref._id);
  } catch (error) {
    logger.error('Protocol content type not found: %s', error);
    return [];
  }
  let schema = contentType.schema;
  const details: ProtocolContentTypeDetails = { contentType: contentType.name, details: [] };
  if (contentTypeConfig.equipmentItem) {
    const equipmentItemConfig = contentTypeConfig.equipmentItem;
    let equipmentContentType: ProtocolContentType;
    try {
      equipmentContentType = feathers.get('localCache').getProtocolContentType(equipmentItemConfig.contentType._id);
    } catch (error) {
      logger.error('Protocol content type not found: %s', error);
      details.details.push({ label: i18n.tc('error'), value: i18n.tc('unknown_equipment_item') });
      return [details];
    }
    const equipmentItemElement = equipmentContentType.schema.find(
      (element): element is EquipmentItemElement =>
        element.type === 'equipmentitem' && element._id === equipmentItemConfig.elementId,
    );
    const equipmentItemContent = protocol.contents.find((content) => content.type._id === equipmentContentType._id);
    if (!equipmentItemContent || !equipmentItemElement) {
      return [details];
    }
    const equipmentItemId = equipmentItemContent.data[equipmentItemElement.name];
    if (typeof equipmentItemId !== 'string') {
      return [details];
    }
    try {
      const equipmentItem = feathers.get('localCache').getEquipmentItem(equipmentItemId);
      const equipmentList = feathers.get('localCache').getEquipmentList(equipmentItemElement.equipmentList);
      schema = getEquipmentItemSchema(equipmentItem, equipmentList, contentType);
      const itemElementIdForCount = equipmentList.protocolContentTypes.find(
        (config) => config.ref._id === contentType._id,
      )?.itemElementIdForCount;
      if (itemElementIdForCount) {
        const itemElementForCount = equipmentList.itemSchema.find(
          (itemElement): itemElement is Element =>
            itemElement.type !== undefined && itemElement._id === itemElementIdForCount,
        );
        const count = itemElementForCount ? (equipmentItem.itemData[itemElementForCount.name] as number) : 1;
        if (count !== 1) {
          const repeatedDetails = getRepeatedSchema(schema, count)
            .map((repeatedSchema) => schemaToDetails(repeatedSchema, data, contentTypeConfig.links))
            .filter((lines) => lines.length > 0)
            .map<ProtocolContentTypeDetails>((lines, index) => ({
              contentType: `${details.contentType} (${index + 1})`,
              details: lines,
            }));
          return repeatedDetails;
        }
      }
    } catch (error) {
      logger.error('Error at generating equipment item element details: %s', error);
      details.details.push({ label: i18n.tc('error'), value: i18n.tc('unknown_equipment_item') });
      return [details];
    }
  }
  const values = schemaToDetails(schema, data, contentTypeConfig.links);
  if (values.length === 0) {
    return [];
  }
  return [
    {
      ...details,
      details: values,
    },
  ];
}

function getChecklistTypeDetails(
  checklistType: ChecklistType,
  data: ProtocolContentData,
): ProtocolContentTypeDetails[] {
  const details: ProtocolContentTypeDetails = { contentType: checklistType.name, details: [] };
  const schema = getChecklistSchema(checklistType);
  const values = schemaToDetails(schema, data, []);
  return [
    {
      ...details,
      details: values,
    },
  ];
}

export function getDetails(protocol: Protocol, forPDF?: boolean): ProtocolContentTypeDetails[] | string {
  const feathers = useFeathers();

  if (('_id' in protocol.type ? protocol.type._id : protocol.type.ref._id) === FIXED_PROTOCOL_TYPE_IDS.GENERIC) {
    const data: GenericProtocolContentData | undefined = protocol.contents.find(
      ({ type: { _id: contentTypeId } }) => contentTypeId === FIXED_PROTOCOL_CONTENT_TYPE_IDS.GENERIC,
    )?.data as GenericProtocolContentData;
    if (!data) {
      return [];
    }
    const entries = data.record.Entries.map(({ Type: type, Value: value }) => ({
      label: `${i18n.tc('protocol.generic_protocol.entry', undefined, { type })}`,
      value: value,
    }));
    const extensions = (data.record.Extensions || []).map(({ Name: name, Value: value }) => ({
      label: `${i18n.tc('protocol.generic_protocol.extension', undefined, { name })}`,
      value: value,
    }));
    return [
      { contentType: i18n.tc('protocol.generic_protocol.entries'), details: entries as Line[] },
      { contentType: i18n.tc('protocol.generic_protocol.extensions'), details: extensions as Line[] },
    ];
  }
  let protocolType: ProtocolType | undefined = undefined;
  let checklistType: ChecklistType | undefined = undefined;
  try {
    if ('_id' in protocol.type) {
      protocolType = feathers.get('localCache').getProtocolType(protocol.type._id);
    } else {
      checklistType = feathers.get('localCache').getChecklistType(protocol.type.ref._id);
    }
  } catch (error) {
    return [];
  }
  let detailsPerContentType: ProtocolContentTypeDetails[][] = [];
  if (protocolType) {
    if (protocolType.elementForDetails !== undefined) {
      const contentTypeId = protocolType.elementForDetails.contentType._id;
      const contentType = feathers.get('localCache').getProtocolContentType(contentTypeId);
      let value = getElementValue(protocol, contentType, protocolType.elementForDetails.elementId);

      if (isProtocolLink(value)) {
        value = value.value;
      }
      if (!Array.isArray(value) && typeof value !== 'object') {
        return `${value || ''}`;
      }
    }
    detailsPerContentType = protocolType.contentTypes.map((contentType) => {
      const data = protocol.contents.find(({ type }) => type._id === contentType.ref._id)?.data;
      if (!data) {
        return [];
      }
      return getProtocolContentTypeDetails(protocol, contentType, data);
    });
  } else if (checklistType) {
    const data = protocol.contents.find(({ type }) => type._id === checklistType?._id)?.data || {};
    detailsPerContentType = [getChecklistTypeDetails(checklistType, data)];
  }
  const details = detailsPerContentType.reduce((allDetails, details) => [...allDetails, ...details], []);

  if (protocolType && (protocolType.showCogSogInTable || forPDF) && protocol.sogCog) {
    details.unshift({
      contentType: i18n.tc('protocol.sog_cog_table_label'),
      details: [{ label: i18n.tc('protocol.sog_cog_table_label'), value: SogCogTransformer.format(protocol.sogCog) }],
    });
  }
  if (protocolType && (protocolType.showPositionInTable || forPDF) && protocol.position) {
    details.unshift({
      contentType: i18n.tc('protocol.position'),
      details: [{ label: i18n.tc('protocol.position'), value: PositionTransformer.format(protocol.position) }],
    });
  }
  return details;
}

function indentedData(line: Line, indent = '    '): string {
  let value: string;
  if (Array.isArray(line.value)) {
    value = line.value.map((subLine) => `\n${indent}${indentedData(subLine, indent.concat(indent))}`).join();
  } else {
    value = `${line.value}`;
  }
  return `${line.label}: ${value}`;
}

export function linesAsIndentedString(lines: Line[]): string {
  return lines.map((detail) => indentedData(detail)).join('\n');
}

function lineAsString(line: Line): string {
  let value: string;
  if (Array.isArray(line.value)) {
    value = line.value.map((subLine) => lineAsString(subLine)).join(', ');
  } else {
    value = `${line.value}`;
  }
  return `${line.label}: ${value}`;
}

function linesAsString(lines: Line[]): string {
  return lines.map((line) => lineAsString(line)).join(', ');
}

export function detailsAsString(details: ProtocolContentTypeDetails[] | string): string {
  if (typeof details === 'string') {
    return details;
  }
  return details.map((detail) => `${detail.contentType}: ${linesAsString(detail.details)}`).join(', ');
}
