import {
  AbstractEntity,
  findSuccessor,
  findSuccessorEntity,
  getLatestObjectsWithHistory,
  Protocol,
  VersionedAbstractEntity,
} from '@anschuetz-elog/common';
import { AdapterService } from '@feathersjs/adapter-commons';
import { FeathersService, Service } from '@feathersjs/feathers';

import logger from '#/logger';

import { find } from './feathers-helpers';

type ObjectWithPredecessor = AbstractEntity & {
  predecessor?: { _id: string };
};

export type ObjectWithHistory<T> = {
  data: T;
  history: T[];
};

/**
 *
 * @param protocols list of protocols
 * @param id id of a specific protocol
 */
function getObjectById<T extends ObjectWithPredecessor>(objects: T[], id: string): T | undefined {
  if (objects !== undefined && objects !== null) {
    const protocol = objects.filter((protocol: T) => protocol._id === id)[0];
    return protocol;
  }
}

/**
 * Get the complete history (all predecessor) from a protocol
 * @param protocols list of all protocols
 * @param id starting protocol to get history from
 */
export function getHistoryOfObject<T extends ObjectWithPredecessor>(objects: T[], object: T): T[] {
  const history: T[] = [];
  let currentObject: T | undefined = object;
  history.push(object);

  while (currentObject !== undefined && currentObject.predecessor) {
    currentObject = getObjectById(objects, currentObject.predecessor._id);

    if (currentObject === undefined) {
      break;
    }

    history.push(currentObject);
  }

  return history;
}

export function getLatestObject<T extends ObjectWithPredecessor>(objects: T[], object: T): T {
  let currentObject = object;
  let successor: T | undefined = object;
  do {
    currentObject = successor;
    successor = findSuccessor(objects, currentObject);
  } while (successor !== undefined);
  return currentObject;
}

function getHistoryOfEntity<T extends VersionedAbstractEntity<T>>(entities: T[], entity: T): T[] {
  const predecessorMap: Record<string, T | undefined> = entities.reduce(
    (map, entity) => ({ ...map, ...(entity.successor ? { [entity.successor._id]: entity } : {}) }),
    {},
  );
  const history: T[] = [entity];
  let currentEntity: T | undefined = entity;

  while (currentEntity !== undefined) {
    currentEntity = predecessorMap[currentEntity._id];
    if (currentEntity) {
      history.push(currentEntity);
    }
  }

  return history;
}

export function getEntityHistoryWithPredecessor<T extends VersionedAbstractEntity<T>>(
  entities: T[],
  entity: T,
): (T & { predecessor?: { _id: string } })[] {
  const entityWithHistory = getHistoryOfEntity(entities, entity);

  const history = entityWithHistory.map((entity, index) => {
    if (index === entityWithHistory.length - 1) {
      return entity;
    }
    return { ...entity, predecessor: { _id: entityWithHistory[index + 1]._id } };
  });

  return history;
}

export function getLatestEntity<T extends VersionedAbstractEntity<T>>(entities: T[], entity: T): T {
  let currentEntity = entity;
  let successor: T | undefined = entity;
  do {
    currentEntity = successor;
    successor = findSuccessorEntity(entities, currentEntity);
  } while (successor !== undefined);
  return currentEntity;
}

export function enrichProtocolWithAllContents<T extends Protocol>(protocol: T, predecessor?: T): T {
  if (!protocol.deleted || !predecessor) {
    return protocol;
  }
  // we need content of previous protocol as the deleted protocol does not contain
  // this data anymore
  return {
    ...protocol,
    contents: predecessor.contents,
    timestamp: predecessor.timestamp,
    position: predecessor.position,
    sogCog: predecessor.sogCog,
    isDecrypting: predecessor.isDecrypting,
  };
}

export async function loadSuccessorsAndPredecessors<T extends Protocol>(
  protocols: T[],
  protocolService: AdapterService<T>,
  skipSuccessors = false,
): Promise<T[]> {
  if (protocols.length === 0) {
    return protocols;
  }
  const successorMap: Record<Protocol['_id'], Protocol> = protocols.reduce(
    (map, protocol) => ({ ...map, ...(protocol.predecessor ? { [protocol.predecessor._id]: protocol } : {}) }),
    {},
  );
  // look for predecessors
  const missingPredecessorIds: Set<Protocol['_id']> = new Set();
  protocols.forEach((protocol) => {
    if (protocol.predecessor && !protocols.find(({ _id: protocolId }) => protocolId === protocol.predecessor?._id)) {
      missingPredecessorIds.add(protocol.predecessor._id);
    }
  });
  const predecessors =
    missingPredecessorIds.size === 0
      ? []
      : await find(protocolService as unknown as FeathersService<unknown, Service<T>>, {
          query: { _id: { $in: Array.from(missingPredecessorIds) } },
        });
  // look for successors
  let successors: T[] = [];
  const predecessorIdsOfMissingSuccessors: Set<Protocol['_id']> = new Set();
  protocols.forEach((protocol) => {
    if (!successorMap[protocol._id]) {
      predecessorIdsOfMissingSuccessors.add(protocol._id);
    }
  });
  try {
    if (!skipSuccessors) {
      successors = await find(protocolService as unknown as FeathersService<unknown, Service<T>>, {
        query: { 'predecessor._id': { $in: Array.from(predecessorIdsOfMissingSuccessors) } },
      });
    }
    if (successors.length === 0 && predecessors.length === 0) {
      return protocols;
    }
    return await loadSuccessorsAndPredecessors<T>(
      [...protocols, ...predecessors, ...successors],
      protocolService,
      skipSuccessors,
    );
  } catch (error) {
    logger.error(error);
    throw error;
  }
}

export { getLatestObjectsWithHistory };
