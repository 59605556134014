import { decryptPrivateKey, User } from '@anschuetz-elog/common';
import { AuthenticationClient } from '@feathersjs/authentication-client';
import { FeathersError, NotAuthenticated } from '@feathersjs/errors';
import * as jsrsasign from 'jsrsasign';
import moment from 'moment';
import ms from 'ms';
import { v4 as uuidV4 } from 'uuid';

import useFeathers from '#/compositions/useFeathers';
import dynamicConfig from '#/lib/dynamicConfig';
import logger from '#/logger';
import store from '#/store';
import { ONLINE_RECONNECT_STRATEGY } from '#/store/modules/auth';

const jwtOptions = {
  header: { alg: 'ES256', typ: 'JWT' },
  payload: {
    iss: 'elog',
  },
};

interface JwtPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  iss?: string | undefined;
  sub?: string | undefined;
  aud?: string | string[] | undefined;
  exp?: number | undefined;
  nbf?: number | undefined;
  iat?: number | undefined;
  jti?: string | undefined;
}

export default class ELogAuthenticationClient extends AuthenticationClient {
  private doOfflineAuth(): boolean {
    return !dynamicConfig('ONLINE_ONLY') && !store.getters.websocket.isConnected;
  }

  private getAuthResult(): ReturnType<AuthenticationClient['authenticate']> | null {
    return this.app.get('authentication');
  }

  private setAuthResult(promise: ReturnType<AuthenticationClient['authenticate']> | null): void {
    this.app.set('authentication', promise);
  }

  async reAuthenticate(
    force: Parameters<AuthenticationClient['reAuthenticate']>[0],
    strategy: Parameters<AuthenticationClient['reAuthenticate']>[1],
  ): ReturnType<AuthenticationClient['reAuthenticate']> {
    if (this.doOfflineAuth() && strategy !== ONLINE_RECONNECT_STRATEGY) {
      const authResultPromise = this.getAuthResult();
      if (authResultPromise && force !== true) {
        const authResult = await authResultPromise;
        // auto update token so that sessions gets extended in terms of session timeout
        const newAccessToken = this.createAccessToken(authResult.user);
        this.setAccessToken(newAccessToken);
        authResult.accessToken = newAccessToken;
        return authResult;
      }
      try {
        const accessToken = await this.getAccessToken();
        if (!accessToken) {
          throw new NotAuthenticated('No access token');
        }
        const { payloadObj: payload } = jsrsasign.KJUR.jws.JWS.parse(accessToken);
        const userId = (payload as JwtPayload | undefined)?.sub;
        if (!userId) {
          throw new NotAuthenticated('Unknown user of access token');
        }
        const user = await useFeathers().service('user').get(userId);
        try {
          const payload = this.verifyAccessToken(accessToken, user);
          const { key, encryptionKey } = store.state.auth;
          if (key === null) {
            throw new NotAuthenticated('User keys missing');
          }
          const authenticatedUser: User = {
            ...user,
            encryptionPrivateKey: encryptionKey || undefined,
            privateKey: key,
          };
          // create new token so that sessions gets extended in terms of session timeout
          const newAccessToken = this.createAccessToken(authenticatedUser);
          this.setAccessToken(newAccessToken);
          const result = {
            accessToken: newAccessToken,
            authentication: {
              strategy: 'jwt',
              accessToken: newAccessToken,
              payload,
            },
            user: authenticatedUser,
          };
          this.setAuthResult(Promise.resolve(result));
          return result;
        } catch (error) {
          throw new NotAuthenticated('Invalid access token');
        }
      } catch (error) {
        return this.handleError(error as FeathersError, 'authenticate') as ReturnType<
          AuthenticationClient['reAuthenticate']
        >;
      }
    }
    // do re-authentication against backend
    const authResult = await super.reAuthenticate(force, strategy);
    // auto update token so that sessions gets extended in terms of session timeout
    if (strategy !== ONLINE_RECONNECT_STRATEGY) {
      const newAccessToken = this.createAccessToken(authResult.user);
      this.setAccessToken(newAccessToken);
      authResult.accessToken = newAccessToken;
    }
    return authResult;
  }

  async authenticate(
    authentication: Parameters<AuthenticationClient['authenticate']>[0],
    params: Parameters<AuthenticationClient['authenticate']>[1],
  ): ReturnType<AuthenticationClient['authenticate']> {
    if (this.doOfflineAuth() && authentication?.strategy !== ONLINE_RECONNECT_STRATEGY) {
      try {
        return await this.offlineAuth(authentication);
      } catch (error) {
        this.handleError(error as FeathersError, 'authenticate');
        throw error;
      }
    }
    if (authentication?.strategy === ONLINE_RECONNECT_STRATEGY) {
      authentication.strategy = this.options.jwtStrategy;
      const authResult = this.getAuthResult();
      if (!authResult) {
        throw new NotAuthenticated('No authenticated user');
      }
      await this.service.create(authentication, params);
      return authResult;
    }
    return await super.authenticate(authentication, params);
  }

  async logout(): ReturnType<AuthenticationClient['logout']> {
    if (this.doOfflineAuth()) {
      try {
        const authResult = await this.getAuthResult();
        this.removeAccessToken();
        await this.reset();
        this.app.emit('logout', authResult);
        return authResult;
      } catch (error) {
        this.handleError(error as FeathersError, 'logout');
        throw error;
      }
    }
    return super.logout();
  }

  private async offlineAuth(
    authentication: Parameters<AuthenticationClient['authenticate']>[0],
  ): ReturnType<AuthenticationClient['authenticate']> {
    if (!authentication) {
      return this.reAuthenticate(undefined, undefined);
    }
    const { username, password } = authentication;
    const [user] = (await useFeathers()
      .service('user')
      .find({ query: { userName: username } })) as [User | undefined];
    if (!user) {
      throw new NotAuthenticated();
    }
    try {
      const privateKey = decryptPrivateKey(user.privateKey, password, user.privateKeySalt);
      const encryptionPrivateKey =
        user.encryptionPrivateKey && user.encryptionPrivateKeySalt
          ? decryptPrivateKey(user.encryptionPrivateKey, password, user.encryptionPrivateKeySalt)
          : undefined;
      const authenticatedUser: User = {
        ...user,
        privateKey,
        encryptionPrivateKey,
        privateKeySalt: '',
        encryptionPrivateKeySalt: '',
      };
      const accessToken = this.createAccessToken(authenticatedUser);
      this.setAccessToken(accessToken);
      const result = {
        accessToken,
        authentication: { strategy: 'gateway' },
        user: authenticatedUser,
      };
      this.setAuthResult(Promise.resolve(result));
      return result;
    } catch (error) {
      logger.error('Error at offline login', error);
      throw new NotAuthenticated();
    }
  }

  private createAccessToken(user: User) {
    const start = moment();
    const end = start.clone().add(ms(dynamicConfig('SESSION_TIMEOUT')), 'milliseconds');
    const payload = {
      ...jwtOptions.payload,
      jti: uuidV4(),
      iat: start.unix(),
      exp: end.unix(),
      sub: user._id,
    };
    return jsrsasign.KJUR.jws.JWS.sign(
      jwtOptions.header.alg,
      JSON.stringify(jwtOptions.header),
      JSON.stringify(payload),
      user.privateKey,
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  private verifyAccessToken(accessToken: string, user: User): object {
    if (!jsrsasign.KJUR.jws.JWS.verifyJWT(accessToken, user.publicKey, { alg: [jwtOptions.header.alg] })) {
      throw new NotAuthenticated('Invalid access token');
    }
    const { payloadObj } = jsrsasign.KJUR.jws.JWS.parse(accessToken);
    return payloadObj || {};
  }
}
