<template>
  <div class="positionRelative" :class="{ marked: mark }">
    <span class="titleWrapper">{{ title }}</span>
    <v-combobox
      v-model="innerValue"
      class="combobox bg-white"
      v-bind="$attrs"
      :items="content"
      :placeholder="placeholder"
      hide-details
      outlined
      flat
      allow-overflow
      dense
      @input.native="handleInputEvent"
    >
      <template #append>
        <Icon class="expand-icon" name="arrow/chevronDown" />
      </template>
      <template #item="{ item }">
        <span class="proposed">{{ item }}</span>
      </template>
    </v-combobox>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import Icon from '#/components/Icon.vue';

/**
 * ComboBox
 */

export default defineComponent({
  name: 'ComboBox',

  inheritAttrs: false,

  components: {
    Icon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    contentArray: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    value: {
      type: String,
      default: '',
    },
    mark: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: string): boolean => true,
  },

  setup(props, context) {
    const contentArray = toRef(props, 'contentArray');
    const value = toRef(props, 'value');
    const content = computed<string[]>(() => (contentArray ? contentArray.value : ['']));
    const placeholder = computed<string>(() => content.value.join(', '));
    const innerValue = computed<string>({
      get() {
        return value.value;
      },
      set(newValue: string) {
        context.emit('input', newValue);
      },
    });

    function handleInputEvent(event: { srcElement: HTMLInputElement }): void {
      innerValue.value = event.srcElement.value;
    }

    return {
      content,
      handleInputEvent,
      innerValue,
      placeholder,
    };
  },
});
</script>

<style scoped>
.positionRelative {
  position: relative;
}

.titleWrapper {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: var(--v-gray_second-base);
  text-align: left;
}

.proposed {
  font-size: 12px;
  letter-spacing: 0.2px;
}

.combobox {
  font-size: 16px;
}

.combobox >>> input {
  padding-left: 12px !important;
}

.combobox.v-select.v-select--is-menu-active .expand-icon {
  transform: rotate(180deg);
}

.combobox >>> .v-input__append-inner {
  padding-right: 4px;
}

.marked .combobox {
  font-size: 16px;
  background: var(--v-white-base);
}

.marked .combobox {
  background-color: var(--v-error_bg-base) !important;
}
</style>
