<template>
  <Fragment>
    <DetailsListEntry v-if="!edit" class="ma-0" :title="element.label" :content="readModeModel" />
    <DateTimeField
      v-else
      v-model="model"
      :title="element.label"
      :placeholder="element.placeholder"
      :custom-format="element.format"
      :disable-rounding="disableRounding"
      :data-test="`datetime-${element.name}`"
      :mark="invalid"
      @invalid="$emit('invalid', $event)"
    />
  </Fragment>
</template>

<script lang="ts">
import { DateTimeElement, logger } from '@anschuetz-elog/common';
import { cloneDeep } from 'lodash';
import { computed, defineComponent, PropType, toRef } from 'vue';

import DateTimeField from '#/components/DateTimeField.vue';
import DetailsListEntry from '#/components/DetailsListEntry.vue';
import { dateTimeStr, format } from '#/utilities';

export default defineComponent({
  name: 'FormDateTime',

  components: {
    DetailsListEntry,
    DateTimeField,
  },

  props: {
    element: {
      type: Object as PropType<DateTimeElement>,
      required: true,
    },

    value: {
      type: Object as PropType<Record<string, unknown>>,
      required: true,
    },

    edit: {
      type: Boolean,
    },

    invalid: {
      type: Boolean,
    },
  },

  emits: {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    input: (_value: Record<string, unknown>) => true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    invalid: (_invalid: boolean) => true,
  },
  setup(props, { emit }) {
    const value = toRef(props, 'value');
    const element = toRef(props, 'element');

    const disableRounding = computed(() => {
      if (!element.value.format) {
        return false;
      }
      return element.value.format.includes('ss');
    });

    const model = computed<string | undefined>({
      get() {
        const v = value.value[element.value.name];
        if (v !== undefined && typeof v !== 'string') {
          logger.error(
            'Value does not fit to datetime element',
            'value:',
            cloneDeep(v),
            'element:',
            cloneDeep(element.value),
          );
          return undefined;
        }
        return v;
      },
      set(newElementValue) {
        emit('input', { ...value.value, [element.value.name]: newElementValue });
      },
    });

    const readModeModel = computed(() => {
      if (!model.value) {
        return '';
      }
      return element.value.format
        ? format(model.value, element.value.format, disableRounding.value)
        : dateTimeStr(model.value);
    });

    return { model, readModeModel, disableRounding };
  },
});
</script>
