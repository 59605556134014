<template>
  <div class="m-2" :class="{ 'protocol-container': !oneColumnLayout }">
    <div v-if="$slots['aside-start']" class="aside-start <md:w-full">
      <slot name="aside-start" />
    </div>

    <div
      class="item-main <md:w-full"
      :class="{
        itemMain: $slots.aside,
      }"
    >
      <slot />
    </div>
    <div v-if="$slots['aside-end']" class="aside-end <md:w-full md:-mt-4">
      <slot name="aside-end" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProtocolContainer',

  props: {
    oneColumnLayout: {
      type: Boolean,
    },
  },
});
</script>

<style scoped>
@media (min-width: 768px) {
  .protocol-container {
    display: grid;
    grid-template-columns: 1fr minmax(0, 2fr);
    grid-template-rows: [row1] auto [row2] auto [row3] auto [row-end];
    grid-template-areas:
      'aside-start item-main'
      'aside-end item-main'
      '. item-main';
  }

  .protocol-container .aside-start,
  .protocol-container .aside-end {
    min-width: 440px;
  }

  .protocol-container .aside-start {
    grid-area: aside-start;
  }

  .protocol-container .aside-end {
    grid-area: aside-end;
  }

  .protocol-container .item-main {
    grid-area: item-main;
  }
}
</style>
