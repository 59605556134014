<template>
  <Box
    v-if="type === 'entries'"
    title-centered
    :title="$tc('protocol.generic_protocol.entries')"
    :class="{ crossed: deleted }"
  >
    <DetailsListEntry
      v-for="entry in entries"
      :key="entry.Id"
      :title="$tc('protocol.generic_protocol.entry', undefined, { type: entry.Type })"
      :mark-as-unknown="!!entry.Unknown"
      :content="valueToString(entry.Value)"
    />
    <span v-if="entries.length === 0">{{ $t('protocol.generic_protocol.no_entries') }}</span>
  </Box>
  <Box
    v-else-if="type === 'extensions'"
    title-centered
    :title="$tc('protocol.generic_protocol.extensions')"
    :class="{ crossed: deleted }"
  >
    <DetailsListEntry
      v-for="extension in extensions"
      :key="extension.Name"
      :title="$tc('protocol.generic_protocol.extension', undefined, { name: extension.Name })"
      :content="valueToString(extension.Value)"
    />
    <span v-if="extensions.length === 0">{{ $t('protocol.generic_protocol.no_extensions') }}</span>
  </Box>
</template>

<script lang="ts">
import { FIXED_PROTOCOL_CONTENT_TYPE_IDS, GenericProtocolContentData, Protocol } from '@anschuetz-elog/common';
import { computed, defineComponent, PropType, toRef } from 'vue';

import DetailsListEntry from '#/components/DetailsListEntry.vue';
import Box from '#/components/layout/Box.vue';

export default defineComponent({
  name: 'GenericProtocolContent',

  components: {
    DetailsListEntry,
    Box,
  },

  props: {
    protocol: {
      type: Object as PropType<Protocol>,
      required: true,
    },

    type: {
      type: String as PropType<'entries' | 'extensions'>,
      required: true,
    },

    deleted: {
      type: Boolean,
    },
  },

  setup(props) {
    const protocol = toRef(props, 'protocol');

    const record = computed(() => {
      const data: GenericProtocolContentData | undefined = protocol.value.contents.find(
        ({ type: { _id: contentTypeId } }) => contentTypeId === FIXED_PROTOCOL_CONTENT_TYPE_IDS.GENERIC,
      )?.data as GenericProtocolContentData;
      return data?.record;
    });
    const entries = computed(() => record.value?.Entries || []);
    const extensions = computed(() => record.value?.Extensions || []);

    function valueToString(value: unknown): string {
      if (typeof value === 'string' || typeof value === 'number') {
        return `${value}`;
      }
      return String(value);
    }

    return { entries, extensions, valueToString };
  },
});
</script>

<style scoped>
.crossed ::v-deep .listContent {
  text-decoration: line-through;
}
</style>
