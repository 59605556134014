import { AbstractEntity, OfflineServiceKeys } from '@anschuetz-elog/common';
import { AdapterService, ServiceOptions } from '@feathersjs/adapter-commons';
import { NotFound } from '@feathersjs/errors';
import { Id, NullableId, Paginated, Params, Query } from '@feathersjs/feathers';
import { cloneDeep } from 'lodash';

import logger from '@/logger';

import { Database, getDB } from './databases';

type PouchServiceOptions = Partial<ServiceOptions> & { database: OfflineServiceKeys };

// Introducing entity queues to avoid document update conflicts
// due to lots of emitted backend events and updates especially for
// the client device and system status
// These queues are held globally because there are multiple
// instances of pouch services per database while synchronizing the app
const entityQueues = new Map<string, Map<string, Promise<unknown>>>();
let globalNextRequestKey = 0;

export class PouchService<T extends AbstractEntity> extends AdapterService<T> {
  constructor(private pouchServiceOptions: PouchServiceOptions) {
    super(pouchServiceOptions);

    if (!pouchServiceOptions.database) {
      throw new Error('The `storage` option needs to be provided');
    }
  }

  get db(): Database<T> {
    return getDB(this.pouchServiceOptions.database) as Database<T>;
  }

  /**
   * Get the proper queue for the according database of this service
   * from the globally existing queues.
   * If no queue exists yet, a new one is created and registered
   */
  get entityQueue(): Map<string, Promise<unknown>> {
    let entityQueue = entityQueues.get(this.db.name);
    if (entityQueue === undefined) {
      entityQueue = new Map();
      entityQueues.set(this.db.name, entityQueue);
    }
    return entityQueue;
  }

  /**
   * This method registers an async function for a given entity in the update queue.
   * It waits until all previously registered functions for this entity are performed.
   * Then it performs the given function which blocks all functions that have been
   * registered afterwards.
   */
  private async registerAtQueue<T>(id: string, fn: () => Promise<T>): Promise<T> {
    const currentQueueEntry = this.entityQueue.get(id);
    const requestKey = globalNextRequestKey;
    globalNextRequestKey++;
    logger.debug('%s: Registering op in queue for %s (%s)', this.db.name, id, requestKey);

    const newQueueEntry = new Promise<T>((resolve, reject) => {
      void (async () => {
        try {
          await currentQueueEntry;
        } catch (error) {
          // ignore errors of previous queue entry
        }
        try {
          logger.debug('%s: Start op in queue for %s (%s)', this.db.name, id, requestKey);
          const result = await fn();
          logger.debug('%s: Finished op in queue for %s (%s)', this.db.name, id, requestKey);
          resolve(result);
        } catch (error) {
          logger.debug('%s: Error in queue-op %s (%s)', this.db.name, id, requestKey);
          reject(error);
        }
      })();
    });

    this.entityQueue.set(id, newQueueEntry);
    return newQueueEntry;
  }

  private extractPureDoc(doc: T & PouchDB.Core.IdMeta & PouchDB.Core.GetMeta): T {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
    const { _attachments, _conflicts, _rev, _revs_info, _revisions, ...pureDoc } = doc;
    return pureDoc as T;
  }

  private transformSortQuery(sortQuery?: Record<string, string>): { [key: string]: 'asc' | 'desc' } | undefined {
    if (sortQuery === undefined) {
      return undefined;
    }
    const transformedSortQuery: { [key: string]: 'asc' | 'desc' } = {};
    for (const key of Object.keys(sortQuery)) {
      switch (parseInt(sortQuery[key])) {
        case 1:
          transformedSortQuery[key] = 'asc';
          break;
        case -1:
          transformedSortQuery[key] = 'desc';
          break;
        default:
          throw new Error(
            `value for the sort key ${key} was provided ${sortQuery[key]} which is invalid. must be 1 or -1`,
          );
      }
    }

    return transformedSortQuery;
  }

  private createFindRequest(query: Query): PouchDB.Find.FindRequest<T> & { limit: number } {
    const {
      $limit: limit,
      $skip: skip,
      $sort: sort,
      $select: select,
      ...queryRest
    } = query as Query & {
      $limit?: number | string;
      $skip?: number | string;
      $sort?: Record<string, string>;
      $select: string[];
    };
    const pouchSort = this.transformSortQuery(sort);
    return {
      selector: {
        _id: { $exists: true }, // this line is necessary for empty queries which would not give any data => TODO
        ...((queryRest as PouchDB.Find.Selector) || {}),
      },
      limit: limit ? parseInt(`${limit}`) : Number.MAX_SAFE_INTEGER,
      ...(skip && { skip: parseInt(`${skip}`) }),
      ...(pouchSort && { sort: [pouchSort] }),
      ...(select && { fields: select }),
    };
  }

  async find(params?: Params): Promise<T[] | Paginated<T>> {
    const paramsQuery = params && params.query ? params.query : {};
    const findRequest = this.createFindRequest(paramsQuery);
    const result = await this.db.find(findRequest);
    const docs: T[] = result.docs.map((doc) => this.extractPureDoc(doc));
    if (paramsQuery.$limit) {
      let total = (findRequest.skip || 0) + docs.length;
      if (docs.length >= findRequest.limit) {
        total += 1;
      }
      return {
        total,
        skip: findRequest.skip || 0,
        limit: findRequest.limit,
        data: docs,
      };
    }
    return docs;
  }

  async get(id: Id): Promise<T> {
    let doc;

    try {
      doc = await this.db.get<T>(id.toString());
    } catch (e) {
      if (e instanceof Error && e.name === 'not_found') {
        throw new NotFound(`Can't find document with id: ${id}`);
      } else {
        throw e;
      }
    }

    return this.extractPureDoc(doc);
  }

  private async bulkDocs(data: T[]): Promise<T[]> {
    const clonedData = cloneDeep(data); // clone obj as pouchdb changes original reference
    const result = await this.db.bulkDocs(clonedData);
    const { rows } = await this.db.allDocs({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      include_docs: true,
      keys: (result as PouchDB.Core.Response[]).map((r) => r.id),
    });
    return rows
      .map((row) => row.doc)
      .filter((doc): doc is PouchDB.Core.ExistingDocument<T> => doc !== undefined)
      .map((doc) => this.extractPureDoc(doc));
  }

  async create(_data: T, params?: Params): Promise<T>;
  async create(_data: T[], params?: Params): Promise<T[]>;
  async create(_data: T | T[]): Promise<T | T[]> {
    if (Array.isArray(_data)) {
      return await this.registerAtQueue('all', async () => {
        return await this.bulkDocs(_data);
      });
    }
    return await this.registerAtQueue('all', async () => {
      logger.debug('%s: Creating new entity %s', this.db.name, _data._id);
      const data = cloneDeep(_data); // clone obj as pouchdb changes original reference
      const result = await this.db.post(data);
      logger.debug('%s: entity %s created', this.db.name, _data._id);
      return this.get(result.id);
    });
  }

  async update(id: Id, data: T): Promise<T> {
    return await this.registerAtQueue('all', async () => {
      const doc = await this.db.get<T>(id.toString());

      if (!doc) {
        throw new Error('Document not found.');
      }

      await this.db.put({
        _rev: doc._rev,
        ...data,
      });
      return this.get(id);
    });
  }

  private canUseAllDocs(query: Query): query is { _id: { $in: string[] } } {
    return '_id' in query && Object.keys(query).length === 1 && '$in' in query._id && Array.isArray(query._id.$in);
  }

  patch(id: Id, data: Partial<T>, params?: Params): Promise<T>;
  patch(id: null, data: Partial<T>, params?: Params): Promise<T[]>;
  async patch(id: NullableId, data: Partial<T>, params?: Params): Promise<T | T[]> {
    if (id === null) {
      return await this.registerAtQueue('all', async () => {
        if (!params?.query) {
          throw new Error('Patch with no id requires query params');
        }
        let docs: PouchDB.Core.ExistingDocument<T>[];
        if (this.canUseAllDocs(params.query)) {
          const allDocsResult = await this.db.allDocs<T>({
            // eslint-disable-next-line @typescript-eslint/naming-convention
            include_docs: true,
            keys: params.query._id.$in,
          });
          docs = allDocsResult.rows
            .map((row) => row.doc)
            .filter((doc): doc is PouchDB.Core.ExistingDocument<T> => doc !== undefined);
        } else {
          const findResult = await this.db.find(this.createFindRequest(params.query));
          docs = findResult.docs;
        }
        const result = await this.bulkDocs(docs.map((doc) => ({ ...doc, ...data })));
        return result;
      });
    }

    return await this.registerAtQueue('all', async () => {
      const doc = await this.db.get<T>(id.toString());

      if (!doc) {
        throw new Error('Document not found.');
      }

      const completeData = {
        ...doc, // reuse old document data
        ...data, // overwrite with patch data
      };

      await this.db.put(completeData);
      return this.get(id);
    });
  }

  async _remove(id: NullableId): Promise<T | T[]> {
    if (id === null) {
      throw new Error("Id can't be null.");
    }

    return await this.registerAtQueue('all', async () => {
      const doc = await this.db.get<T>(id.toString());

      if (!doc) {
        throw new Error('Document not found.');
      }

      await this.db.remove(doc._id, doc._rev);

      return this.extractPureDoc(doc);
    });
  }
}

export default <T extends AbstractEntity>(options: PouchServiceOptions): PouchService<T> => {
  return new PouchService<T>(options);
};
