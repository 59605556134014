import { utilities } from '@anschuetz-elog/frontend-core';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/views/auth/AuthLogin.vue'),
    meta: { auth: utilities.AuthenticationMode.UNAUTHENTICATED_ONLY },
  },
];

export default routes;
