import {
  AbstractEntity,
  Application,
  FileExchangeAndCloudConnectionServiceTypes,
  OfflineQueueAction,
  offlineServiceConfigurations,
  OfflineServiceKeys,
  ServiceTypes,
  signEcdsa,
  softDelete,
} from '@anschuetz-elog/common';
import { store } from '@anschuetz-elog/frontend-core';
import { AdapterService } from '@feathersjs/adapter-commons';
import { MethodNotAllowed, NotAuthenticated } from '@feathersjs/errors';
import { FeathersService, ServiceAddons } from '@feathersjs/feathers';
import { v4 as uuidV4 } from 'uuid';

import { Debug } from '@/lib';
import logger from '@/logger';

import { registerApprovalMapper } from './approval-mapper';
import { loadDB } from './databases';
import { ignoreError } from './error';
import { PouchService } from './feathers-pouchdb';
import { offlineDatabase } from './offline-queue';
import { trackSyncMetaData } from './sync-meta';
import { RegisteredOfflineService, RegisteredOfflineServiceMap } from './types';

const debug = Debug('lib:offline-services');

function registerOfflineService<T extends AbstractEntity, K extends OfflineServiceKeys>(
  feathers: Application,
  serviceName: K,
): RegisteredOfflineService<K> {
  const { readOnly, activateSoftDelete } = offlineServiceConfigurations[serviceName];
  loadDB<T>(serviceName);
  const onlineService = feathers.service(serviceName) as unknown as AdapterService<T> & ServiceAddons<T>;

  const offlineService = new PouchService<T>({ database: serviceName }) as unknown as ServiceTypes[K];
  feathers.use(serviceName, offlineService as (ServiceTypes & FileExchangeAndCloudConnectionServiceTypes)[K]);

  const feathersService = feathers.service(serviceName) as unknown as FeathersService<Application, AdapterService<T>>;

  feathersService.hooks({
    before: {
      all: [
        ...(activateSoftDelete ? [softDelete] : []),
        async (hook) => {
          if (activateSoftDelete && hook.method === 'remove') {
            return;
          }

          if (hook.params.onlineRequest === true && hook.method === 'find') {
            const result = await onlineService.find(hook.params);
            hook.result = result;
            return;
          }

          if (hook.params.onlineRequest === true && hook.method === 'get' && hook.id) {
            const result = await onlineService.get(hook.id, hook.params);
            hook.result = result;
            return;
          }

          // skip if not one of the following methods
          if (!['create', 'patch', 'update', 'remove'].includes(hook.method)) {
            return hook;
          }
          if (hook.params.onlineEvent === true) {
            return hook;
          }

          const id = hook.id;

          debug(serviceName, 'after - upstreamHook', hook.method);

          async function addToOfflineQueue(): Promise<void> {
            if (readOnly) {
              throw new MethodNotAllowed(`read-only offline service ${serviceName}`);
            }
            const user = store.state.auth.user;
            const key = store.state.auth.key;
            if (!user || !key) {
              throw new NotAuthenticated();
            }
            const data: Omit<OfflineQueueAction, 'signature'> = {
              _id: uuidV4(),
              userId: user._id,
              service: serviceName,
              method: hook.method,
            };

            if (['create', 'patch', 'update'].includes(hook.method)) {
              data.itemData = hook.data as AbstractEntity;
            }

            if (['patch', 'update', 'remove'].includes(hook.method)) {
              data.itemId = id as string;
            }
            const signature = signEcdsa(data, user.keyId, key);

            debug(serviceName, 'add to offline-queue', hook.method);
            await offlineDatabase().put({ ...data, signature });
          }

          if (!store.getters.websocket.isConnected) {
            await addToOfflineQueue();
            return hook;
          }

          try {
            // send request to online service and skip actual offline service method call
            // because if request was successful, result was emitted and already handled
            // by the live event listeners below
            if (hook.method === 'remove') {
              if (!id) {
                throw new Error('No id provided');
              }
              hook.result = await onlineService.remove(id);
              return hook;
            }

            if (hook.method === 'create') {
              if (hook.data === undefined) {
                throw new Error('No data for the request');
              }
              hook.result = await onlineService.create(hook.data as Partial<T>, hook.params);
              return hook;
            }

            if (hook.method === 'patch') {
              if (!id) {
                throw new Error('No id provided');
              }
              hook.result = await onlineService.patch(id, hook.data as Partial<T>, hook.params);
              return hook;
            }

            if (hook.method === 'update') {
              if (!id) {
                throw new Error('No id provided');
              }
              hook.result = await onlineService.update(id, hook.data as Partial<T>, hook.params);
              return hook;
            }
          } catch (error) {
            // in case of any error that occurred we need to check the reason
            //   - if we are offline we need to store the request in the offline queue
            //     and let the offline service handle the full request
            //   - on any other reason we throw the error
            if (!store.getters.websocket.isConnected) {
              await addToOfflineQueue();
              return hook;
            }
            throw error;
          }
        },
      ],
    },
  });

  // created
  const createdEventHandler = async (item: T): Promise<void> => {
    debug(serviceName, 'created event', item);
    try {
      await feathersService.create(item, { onlineEvent: true });
    } catch (error) {
      if (ignoreError(error, 'conflict')) {
        return;
      }
      logger.error('%s created event failed for item %s (error %s)', serviceName, item._id, error);
    }
    feathersService.emit('created', item);
  };
  onlineService.on('created', (item) => void createdEventHandler(item));

  // updated
  const updatedEventHandler = async (item: T): Promise<void> => {
    debug(serviceName, 'updated event', item);
    try {
      await feathersService.update(item._id, item, { onlineEvent: true });
    } catch (error) {
      if (ignoreError(error, 'conflict')) {
        return;
      }
      logger.error('%s updated event for item %s (error %s)', serviceName, item._id, error);
    }
    feathersService.emit('updated', item);
  };
  onlineService.on('updated', (item) => void updatedEventHandler(item));

  // patched
  const patchedEventHandler = async (item: T): Promise<void> => {
    debug(serviceName, 'patched event', item);
    try {
      await feathersService.patch(item._id, item, { onlineEvent: true });
    } catch (error) {
      if (ignoreError(error, 'conflict')) {
        return;
      }
      logger.error('%s patched event failed for item %s (error %s)', serviceName, item._id, error);
    }
    feathersService.emit('patched', item);
  };
  onlineService.on('patched', (item) => void patchedEventHandler(item));

  // removed
  const removedEventHandler = async (item: T): Promise<void> => {
    debug(serviceName, 'removed event', item);
    try {
      await feathersService.remove(item._id, { onlineEvent: true });
    } catch (error) {
      if (ignoreError(error, 'not_found')) {
        return;
      }
      logger.error('%s removed event failed for item %s (error %s)', serviceName, item._id, error);
    }
    feathersService.emit('removed', item);
  };
  onlineService.on('removed', (item) => void removedEventHandler(item));

  // add service to local list of all services
  return {
    online: onlineService as unknown as ServiceTypes[K],
    offline: offlineService,
  };
}

export function registerOfflineServices(feathers: Application): RegisteredOfflineServiceMap {
  const registeredServiceMap: RegisteredOfflineServiceMap = {
    user: registerOfflineService(feathers, 'user'),
    role: registerOfflineService(feathers, 'role'),

    protocolDraft: registerOfflineService(feathers, 'protocolDraft'),
    protocolType: registerOfflineService(feathers, 'protocolType'),
    protocolContentType: registerOfflineService(feathers, 'protocolContentType'),
    protocolTypeGroup: registerOfflineService(feathers, 'protocolTypeGroup'),
    book: registerOfflineService(feathers, 'book'),
    'book-key-bundle': registerOfflineService(feathers, 'book-key-bundle'),
    vessel: registerOfflineService(feathers, 'vessel'),
    crewList: registerOfflineService(feathers, 'crewList'),
    alert: registerOfflineService(feathers, 'alert'),
    voyage: registerOfflineService(feathers, 'voyage'),

    protocol: registerOfflineService(feathers, 'protocol'),
    approval: registerOfflineService(feathers, 'approval'),
    'noon-report-dispatch': registerOfflineService(feathers, 'noon-report-dispatch'),
    'error-log': registerOfflineService(feathers, 'error-log'),
    equipmentItem: registerOfflineService(feathers, 'equipmentItem'),
    equipmentList: registerOfflineService(feathers, 'equipmentList'),

    file: registerOfflineService(feathers, 'file'),
    'record-file': registerOfflineService(feathers, 'record-file'),
    plausibilityCheck: registerOfflineService(feathers, 'plausibilityCheck'),
    order: registerOfflineService(feathers, 'order'),
    view: registerOfflineService(feathers, 'view'),
    'sounding-spot': registerOfflineService(feathers, 'sounding-spot'),
    'book-arrangement': registerOfflineService(feathers, 'book-arrangement'),
    'etmal-calculation': registerOfflineService(feathers, 'etmal-calculation'),
    'book-configuration': registerOfflineService(feathers, 'book-configuration'),
    responsibility: registerOfflineService(feathers, 'responsibility'),
    checklistType: registerOfflineService(feathers, 'checklistType'),
    systemInfo: registerOfflineService(feathers, 'systemInfo'),
  };

  trackSyncMetaData(feathers);
  registerApprovalMapper(feathers);

  return registeredServiceMap;
}
