import { dynamicConfig, i18n, store } from '@anschuetz-elog/frontend-core';
import moment from 'moment';
import Vue from 'vue';

import { Debug } from '@/lib';
import router from '@/router';
import useFeathers from '#/compositions/useFeathers';

const debug = Debug('lib:health-status');

const HEALTH_STATUS_UPDATE_INTERVAL_SECONDS = 20;

async function updateHealthStatus(): Promise<void> {
  const feathers = useFeathers();
  const { clientDeviceId } = store.state.clientDevice;
  if (store.getters.websocket.isConnected && clientDeviceId) {
    // send health status
    try {
      debug('updateHealthStatus at: ', new Date());
      await feathers.service('clientDevice').patch(clientDeviceId, {
        systemTime: moment().toISOString(true),
      });
    } catch (error) {
      if (error instanceof Error) {
        // can't patch, because server doesn't know client => go to client registration
        if (error.name === 'not_found') {
          Vue.toasted.error(i18n.t('setup.client_device.device_name_unknown') as string, {
            position: 'bottom-center',
            duration: 3000,
          });
          await store.dispatch.clientDevice.unregisterClientDevice();
          void router.replace({ name: 'setup-client-device' });
          return;
        }

        if (error.name === 'conflict') {
          debug('pouch conflict: ', error);
        }
      }
      debug('error occurred while updating health status', error);
      throw error;
    }
  }
}

export function load(): void {
  if (dynamicConfig('ONLINE_ONLY')) {
    return;
  }

  Vue.use((): void => {
    setInterval(() => {
      void updateHealthStatus();
    }, 1000 * HEALTH_STATUS_UPDATE_INTERVAL_SECONDS);

    // send health status directly after each reconnect
    store.original.watch(
      (state, getters) => {
        return getters['websocket/isConnected'];
      },
      (isConnected) => {
        if (isConnected) {
          void updateHealthStatus();
        }
      },
    );

    debug('Health status loop registered');
  });
}
