import { Book, RuleAlias } from '@anschuetz-elog/common';
import { computed, Ref } from 'vue';

import useFind from '#/compositions/useFind';
import { getLicenses, isLicenseValid } from '#/compositions/useLicenses';
import store from '#/store';

type AccessibleBooks = Ref<(Book & { isLicensed: boolean })[]>;
type DetailedResponse = {
  books: AccessibleBooks;
  isLoading: Ref<boolean>;
  accessToAllBooks: Ref<boolean>;
};

/** returns all the books that are accessible by the user and also specifies if the book is licensed */
export function getBooksForUser(withDetails: true): DetailedResponse;
export function getBooksForUser(withDetails?: false): AccessibleBooks;
export function getBooksForUser(withDetails?: boolean): AccessibleBooks | DetailedResponse {
  const isAuthenticated = computed(() => {
    return store.getters.auth.isAuthenticated;
  });

  const { data: configurations } = useFind(
    'book-configuration',
    computed(() => (isAuthenticated.value ? {} : undefined)),
  );
  const { data: books, isLoading } = useFind(
    'book',
    computed(() => (isAuthenticated.value ? {} : undefined)),
  );
  const configuration = computed(() => {
    return configurations.value[0];
  });

  const licenses = getLicenses();

  const activeBooks = computed(() =>
    books.value
      .filter((book) => !configuration.value?.deactivatedBooks.map((db) => db._id).includes(book._id))
      .sort(({ name }, { name: otherName }) => name.localeCompare(otherName))
      .map((book) => {
        const currentBook = { ...book, isLicensed: isLicenseValid(book.technicalName, licenses.value) };
        return currentBook;
      }),
  );

  const accessibleBooks = computed(() => {
    const user = store.state.auth.user;
    if (!user) {
      return [];
    }

    if (
      user.roles.some(
        (role) =>
          role.name === 'admin' ||
          role.name === 'master' ||
          role.name === 'service' ||
          role.rules.some(({ alias }) => alias === RuleAlias.READ_ALL),
      )
    ) {
      return activeBooks.value;
    }

    return activeBooks.value.filter((book) =>
      user.roles.some(
        (role) =>
          (book.accessToBookRole !== undefined && role._id === book.accessToBookRole._id) ||
          role._id === book.secondApprovalRole._id,
      ),
    );
  });

  if (withDetails) {
    return {
      books: accessibleBooks,
      isLoading,
      accessToAllBooks: computed(() => accessibleBooks.value.length === books.value.length),
    };
  }
  return accessibleBooks;
}
