import cancel from './cancel.svg';
import check from './check.svg';
import info from './info.svg';
import reOrder from './reOrder.svg';
import warningMarker from './warningMarker.svg';

export default {
  cancel,
  check,
  info,
  warningMarker,
  reOrder,
};
