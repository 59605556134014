import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: 'drafts',
    name: 'protocols-drafts',
    component: () => import('#/views/protocol/drafts/ProtocolDrafts.vue'),
    props: true,
  },
  {
    path: 'drafts/:protocolId',
    name: 'protocols-drafts-edit',
    component: () => import('#/views/protocol/drafts/ProtocolDraftsEdit.vue'),
    props: true,
  },
  {
    path: 'group/:protocolTypeGroupId',
    name: 'protocol-new-group-selector',
    component: () => import('#/views/protocol/ProtocolTypeGroupSelector.vue'),
    props: true,
  },
  {
    path: 'type/:protocolTypeId',
    name: 'protocols-create-new',
    component: () => import('#/views/protocol/ProtocolCreate.vue'),
    props: true,
  },
];

export default routes;
