import { convert } from '@feathersjs/errors';
import jsPDF from 'jspdf';

import * as style from '#/export/pdf/style';
import store from '#/store';

export default async function signPdf(pdfDocument: jsPDF): Promise<Blob> {
  if (!store.getters.websocket.isConnected) {
    throw new Error('Signing only works when connected to server');
  }

  const scaleFactor = pdfDocument.internal.scaleFactor;
  const marginRight = style.DOCUMENT_WIDTH - style.SEPARATION_LINE_LENGTH;

  const pdfBuffer = pdfDocument.output('blob');
  const options = {
    reason: 'Export from eLog',
    annotationAppearanceOptions: {
      signatureCoordinates: {
        left: style.DOCUMENT_WIDTH * 0.75 * scaleFactor,
        bottom: (style.DOCUMENT_HEIGHT - marginRight) * scaleFactor,
        right: (style.DOCUMENT_WIDTH - marginRight) * scaleFactor,
        top: (style.DOCUMENT_HEIGHT - style.SEPARATION_LINE_PAGE_TITLE_Y * 0.9) * scaleFactor,
      },
      signatureDetails: [
        {
          value: 'Digitally signed',
          fontSize: style.PAGE_TITLE_FONT_SIZE,
          transformOptions: { rotate: 0, space: 1, tilt: 0, xPos: 0, yPos: 30 },
        },
      ],
    },
  };
  const body = new FormData();
  body.append('options', JSON.stringify(options));
  body.append('pdfContent', pdfBuffer);
  const request = new Request('/api/v1/rest/pdf-sign', {
    method: 'post',
    body,
    headers: { Authorization: `Bearer ${store.state.auth.accessToken}` },
  });
  const response = await fetch(request);
  if (response.status !== 201) {
    throw convert(await response.json());
  }
  return await response.blob();
}
