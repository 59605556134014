import {
  FIXED_PROTOCOL_TYPE_IDS,
  getLatestObjectsWithHistory,
  Protocol,
  ProtocolContentType,
  ProtocolType,
  User,
} from '@anschuetz-elog/common';
import { Query } from '@feathersjs/feathers/lib';
import { UseFind } from '@geprog/use-feathers';
import { useStorage } from '@vueuse/core';
import moment from 'moment';
import { computed, Ref } from 'vue';

import { compareTimestamps } from '#/utilities';

import useFind from './useFind';

const HIDDEN_PROTOCOL_CONTENT_BOX = 'elog::hiddenProtocolContent';

const hiddenProtocolContents: Ref<Record<User['_id'], Record<ProtocolType['_id'], ProtocolContentType['_id'][]>>> =
  useStorage(HIDDEN_PROTOCOL_CONTENT_BOX, {});

export function loadProtocols<T extends Protocol>(
  protocolTypeId: Ref<string | string[] | undefined>,
  query?: Ref<Query | undefined>,
): UseFind<T> {
  const params = computed(() => {
    if (protocolTypeId.value === undefined) {
      return undefined;
    }
    return {
      query: {
        'type._id': Array.isArray(protocolTypeId.value) ? { $in: protocolTypeId.value } : protocolTypeId.value,
        ...(query?.value || {}),
      },
    };
  });
  return useFind('protocol', params, { loadAllPages: true });
}

export function getCurrentShipsMeanTimeOffset(): Ref<string | undefined> {
  const { data: shipTimezoneRecords, isLoading } = useFind(
    'protocol',
    computed(() => ({
      query: {
        'type._id': FIXED_PROTOCOL_TYPE_IDS.TIME_ZONE,
      },
    })),
    { loadAllPages: true },
  );

  const sortedTimezones = computed(() => {
    const protocolsWithHistory = getLatestObjectsWithHistory(shipTimezoneRecords.value);
    return protocolsWithHistory
      .map(({ data }) => data)
      .filter((protocol) => !protocol.deleted)
      .sort((a, b) => {
        return compareTimestamps(a.timestamp, b.timestamp);
      });
  });

  const currentShipsMeanTimeOffset = computed(() => {
    if (isLoading.value) {
      return undefined;
    }
    const lastTimeZone = sortedTimezones.value[0]?.contents[0].data['tz'] as string;
    if (lastTimeZone) {
      return lastTimeZone;
    }
    return '+00:00';
  });

  return currentShipsMeanTimeOffset;
}

export function getShipsMeanTime(): Ref<moment.Moment | undefined> {
  const currentShipsMeanTimeOffset = getCurrentShipsMeanTimeOffset();

  return computed(() => {
    if (currentShipsMeanTimeOffset.value !== undefined) {
      return moment().utc().utcOffset(currentShipsMeanTimeOffset.value);
    }
    return undefined;
  });
}

function getTimestampWithOffset(timestamp: string, timezoneOffset?: string): string {
  return moment(timestamp)
    .utc()
    .utcOffset(timezoneOffset || '+00:00')
    .format();
}

export function getShipsMeanTimeNow(): Ref<string | undefined> {
  const currentShipsMeanTimeOffset = getCurrentShipsMeanTimeOffset();

  const now = moment.utc().toISOString();
  let shipsMeanTimeNow: string | undefined = undefined;

  return computed(() => {
    // once the shipsMeanTime now was calculated we won't change it to not interfere with manual user input
    // and because the "now" is then already in the past
    if (shipsMeanTimeNow !== undefined) {
      return shipsMeanTimeNow;
    }
    if (currentShipsMeanTimeOffset.value !== undefined) {
      shipsMeanTimeNow = getTimestampWithOffset(now, currentShipsMeanTimeOffset.value);
      return shipsMeanTimeNow;
    }
    return undefined;
  });
}

export function isProtocolTypeReferenced(protocolType: Ref<ProtocolType | undefined>): Ref<boolean> {
  const { data: referencedProtocolTypes } = useFind(
    'protocolType',
    computed(() => {
      if (!protocolType.value) {
        return undefined;
      }
      return {
        query: {
          $or: [
            { takeoverConfigs: { $elemMatch: { 'sourceType._id': protocolType.value?._id } } },
            {
              contentTypes: {
                $elemMatch: { groupTakeoverConfigs: { $elemMatch: { 'sourceType._id': protocolType.value?._id } } },
              },
            },
          ],
        },
      };
    }),
  );
  return computed(() => referencedProtocolTypes.value.length > 0);
}

export function getProtocolTypeId(protocol: Ref<Protocol | undefined | null>): Ref<ProtocolType['_id'] | undefined> {
  return computed(() =>
    protocol.value ? ('_id' in protocol.value.type ? protocol.value.type._id : undefined) : undefined,
  );
}

export function getHiddenProtocolContentBox(
  userId: Ref<string>,
  protocol: Ref<Protocol>,
): Ref<ProtocolContentType['_id'][]> {
  const protocolTypeId = getProtocolTypeId(protocol);
  return computed(() =>
    hiddenProtocolContents.value[userId.value] && protocolTypeId.value
      ? hiddenProtocolContents.value[userId.value][protocolTypeId.value] || []
      : [],
  );
}

export function setHiddenProtocolContentBox(
  userId: Ref<User['_id']>,
  protocol: Ref<Protocol>,
  value: ProtocolContentType['_id'][],
): void {
  const protocolTypeId = getProtocolTypeId(protocol);
  if (protocolTypeId.value) {
    hiddenProtocolContents.value = {
      ...hiddenProtocolContents.value,
      [userId.value]: { ...hiddenProtocolContents.value[userId.value], [protocolTypeId.value]: value },
    };
  }
}
