import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/voyage',
    component: () => import('#/views/voyage/Voyage.vue'),
    props: true,
    meta: { authorization: { action: 'read', subject: AUTH_RESOURCES.VOYAGE } },
    children: [
      {
        path: '',
        name: 'voyages',
        component: () => import('#/views/voyage/Voyages.vue'),
        props: true,
      },
      {
        path: 'new',
        name: 'voyage-new',
        component: () => import('#/views/voyage/VoyageCreate.vue'),
        meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.VOYAGE } },
      },
      {
        path: ':voyageId',
        name: 'voyage-details',
        component: () => import('#/views/voyage/VoyageDetails.vue'),
        props: true,
      },
      {
        path: ':voyageId/edit',
        name: 'voyage-edit',
        component: () => import('#/views/voyage/VoyageEdit.vue'),
        props: true,
        meta: { authorization: { action: 'update', subject: AUTH_RESOURCES.VOYAGE } },
      },
    ],
  },
];

export default routes;
