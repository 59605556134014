import auth, { AuthState } from './auth';
import clientDevice, { ClientDeviceState } from './clientDevice';
import websocket, { WebsocketState } from './websocket';

export default {
  auth,
  clientDevice,
  websocket,
};

export interface ModuleStates {
  auth: AuthState;
  clientDevice: ClientDeviceState;
  websocket: WebsocketState;
}
