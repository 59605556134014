<template>
  <Fragment>
    <template v-for="(line, lineIndex) in lines">
      <template v-if="Array.isArray(line.value)">
        <span :key="`${lineIndex}-label`" :class="lineLabelStyle"> {{ symbol }} {{ line.label }}: </span>
        <LineDetails :key="`${lineIndex}-value`" :lines="line.value" line-label-style="italic" :level="level + 1" />
      </template>
      <span v-else :key="`${lineIndex}-${line.label}-flat`">{{ line.label }}: {{ line.value }}</span>
      <template v-if="Array.isArray(line.value)">
        <span v-if="level < 1" :key="`${lineIndex}-group-separator`" :class="lineLabelStyle"> {{ symbol }} </span>
      </template>
      <span v-else-if="lineIndex + 1 < lines.length" :key="`${lineIndex}-separator`">, </span>
    </template>
  </Fragment>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import { Line } from '#/types';

export default defineComponent({
  name: 'LineDetails',

  props: {
    lines: {
      type: Array as PropType<Line[]>,
      required: true,
    },

    lineLabelStyle: {
      type: String,
      default: '',
    },

    level: {
      type: Number,
      default: 0,
    },
  },

  setup(props) {
    const level = toRef(props, 'level');

    const symbol = computed(() => {
      const symbols = ['■', '○'];
      return symbols[level.value] || symbols[symbols.length - 1];
    });

    return { symbol };
  },
});
</script>
