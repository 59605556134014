<template>
  <div class="icon" :class="{ coloring: !noColoring }">
    <slot name="svg">
      <component
        :is="icon"
        class="svg"
        :class="{ [colorClass]: true, 'w-[24px] h-[24px]': sizing === 'default', 'w-auto h-full': sizing === 'auto' }"
      />
    </slot>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, toRef } from 'vue';

import icons, { IconName } from '#/icons';

export default defineComponent({
  name: 'Icon',

  props: {
    name: {
      type: String as PropType<IconName>,
      required: true,
    },
    color: {
      type: String,
      default: 'gray_first',
    },
    noColoring: {
      type: Boolean,
    },
    sizing: {
      type: String as PropType<'default' | 'auto' | 'origin'>,
      default: 'default',
    },
  },

  setup(props) {
    const name = toRef(props, 'name');
    const color = toRef(props, 'color');
    const icon = computed(() => icons[name.value]);
    const colorClass = computed<string>(() => `${color.value}--text`);

    return {
      colorClass,
      icon,
    };
  },
});
</script>

<style scoped>
.icon {
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
}

.icon.coloring ::v-deep *[fill]:not([fill='none']) {
  fill: currentColor;
}

.icon.coloring ::v-deep *[stroke]:not([stroke='none']) {
  stroke: currentColor;
}
</style>
