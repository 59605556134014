import { SystemInfo } from '@anschuetz-elog/common';
import { i18n } from '@anschuetz-elog/frontend-core';
import { compare } from 'compare-versions';
import { defineModule, localActionContext } from 'direct-vuex';
import Vue from 'vue';

import { resetDatabases } from '@/lib/offline/databases';
import { pushOfflineQueue } from '@/lib/offline/offline-queue';
import logger from '@/logger';
import { resetApp } from '@/utilities';
import useFeathers from '#/compositions/useFeathers';

export interface BackendIntegrityCheckState {
  backend?: {
    id: string;
    version?: string;
  };
  checked: boolean;
}

/** @deprecated */
const LS_BACKEND_INTEGRITY_CHECK_BACKEND_ID_KEY = 'backend-integrity-check:backend-id';
const LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY = 'backend-integrity-check:backend';

const backendIntegrityCheck = defineModule({
  namespaced: true,

  state: (): BackendIntegrityCheckState => {
    const rawBackend = localStorage.getItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY);
    if (rawBackend === null) {
      const backendId = localStorage.getItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_ID_KEY);
      if (backendId !== null) {
        return {
          backend: {
            id: backendId,
          },
          checked: false,
        };
      }
      return {
        checked: false,
      };
    }
    try {
      const backend = JSON.parse(rawBackend) as BackendIntegrityCheckState['backend'];
      return {
        backend,
        checked: false,
      };
    } catch (error) {
      logger.error(
        'Error at parsing backend integrity check info from local storage: %s. Backend integrity check info is reset.',
        error,
      );
      return { checked: false };
    }
  },

  mutations: {
    saveBackendInfo(state: BackendIntegrityCheckState, backend: BackendIntegrityCheckState['backend']): void {
      if (backend === undefined) {
        state.backend = undefined;
        localStorage.removeItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY);
        return;
      }

      state.backend = backend;
      localStorage.setItem(LS_BACKEND_INTEGRITY_CHECK_BACKEND_KEY, JSON.stringify(backend));
    },
    setChecked(state: BackendIntegrityCheckState, checked: boolean): void {
      state.checked = checked;
    },
  },

  actions: {
    async checkBackendIntegrity(context): Promise<void> {
      const feathers = useFeathers();
      const { commit, state, dispatch } = localActionContext(context, backendIntegrityCheck);
      let systemInfo: SystemInfo;
      try {
        // the id is ignored here as we only have system-info once
        systemInfo = await feathers.service('systemInfo').get(SystemInfo.FixedId, { onlineRequest: true });
      } catch (error) {
        let dialog;
        try {
          dialog = await Vue.dialog.confirm(i18n.t('general.backend_integrity_failed') as string, {
            loader: true,
            customClass: 'dialog',
            okText: i18n.t('general.try_again') as string,
            cancelText: i18n.t('general.close') as string,
          });
          dialog.close();
          await dispatch.checkBackendIntegrity();
        } catch (error) {
          dialog?.close();
        }
        return;
      }
      const backendId = systemInfo.backendId || '';
      try {
        // we first remove the already existing system info so that we can always use create later
        await feathers.service('systemInfo').remove(SystemInfo.FixedId, { onlineEvent: true });
      } catch (error) {
        // ignore error because then it is the first time the app loads on a device or we are in online only mode
      }
      try {
        await feathers.service('systemInfo').create(systemInfo, { onlineEvent: true });
      } catch (error) {
        // ignore error because it is most likely that we are in online only mode
      }

      // this means the local storage was cleared and now should be updated
      // with the newest server-version-uuid from backend
      if (state.backend === undefined) {
        commit.saveBackendInfo({ id: backendId, version: systemInfo.backendVersion });
        commit.setChecked(true);
        return;
      }

      // backend-ids do not match, so we need to reset whole app
      if (state.backend.id !== backendId) {
        let dialog;
        try {
          // open dialog and ask user to proceed
          dialog = await Vue.dialog.alert(i18n.tc('general.backend_integrity_reset'), {
            loader: true,
            customClass: 'dialog',
            okText: 'Confirm',
          });
        } catch (err) {
          // Clicked on cancel, so we do nothing
          return;
        }

        await resetApp();

        Vue.toasted.success(i18n.tc('general.reset_app'), {
          position: 'bottom-center',
          duration: 3000,
        });

        dialog.close();
        return;
      }

      if (
        systemInfo.versionRequiringClientDataReset &&
        compare(systemInfo.backendVersion || '0.0.0', systemInfo.versionRequiringClientDataReset, '>=') &&
        compare(state.backend.version || '0.0.0', systemInfo.versionRequiringClientDataReset, '<')
      ) {
        const dialog = await Vue.dialog.alert(i18n.tc('general.client_data_reset_notification'), {
          loader: true,
          customClass: 'dialog info',
        });

        // clear pouchDbs
        await pushOfflineQueue();
        await resetDatabases();
        context.commit('offline/setLastSync', {}, { root: true });

        dialog.close();
      }
      commit.saveBackendInfo({ id: backendId, version: systemInfo.backendVersion });
      commit.setChecked(true);
    },
  },
});

export default backendIntegrityCheck;
