import { AUTH_RESOURCES } from '@anschuetz-elog/common';
import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/checklist/new',
    name: 'checklist-new',
    component: () => import('#/views/checklist/ChecklistTypeSelector.vue'),
    props: true,
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.PROTOCOL } },
  },
  {
    path: '/checklist/new/:checklistTypeId',
    name: 'checklist-create-new',
    component: () => import('#/views/checklist/ChecklistCreate.vue'),
    props: true,
    meta: { authorization: { action: 'create', subject: AUTH_RESOURCES.PROTOCOL } },
  },
];

export default routes;
