import { VesselParticulars } from '@anschuetz-elog/common';
import { Query } from '@feathersjs/feathers/lib';
import { UseFind } from '@geprog/use-feathers';
import { orderBy } from 'lodash';
import moment from 'moment';
import { computed, Ref } from 'vue';

import useFind from './useFind';

export function useVesselParticulars(query?: Ref<Query | undefined>): UseFind<VesselParticulars> {
  const params = computed(() => {
    return { query: { ...(query?.value || {}) } };
  });
  const vessels = useFind('vessel', params);
  const sortedVessel = computed(() =>
    orderBy(vessels.data.value, (vessel) => moment(vessel.createdTimestamp), ['desc']),
  );
  return { ...vessels, data: sortedVessel };
}
