import { UserVuetifyPreset } from 'vuetify';

/* eslint-disable @typescript-eslint/naming-convention */
const preset: UserVuetifyPreset = {
  theme: {
    dark: true,
    themes: {
      light: {
        primary: '#095C8F',
        primary_dark: '#0D4E75',
        primary_light: '#4692C1',
        primary_bg: '#CEE4F2',
        secondary: '#016959',
        secondary_dark: '#01362E',
        secondary_light: '#029C84',
        gray_first: '#12191D',
        gray_second: '#607D8B',
        gray_third: '#90A4AE',
        gray_fourth: '#C6D2D9',
        gray_fifth: '#E4EAEE',
        white: '#FFFFFF',
        success: '#029C84',
        success_bg: '#B8E0B8',
        warning: '#F27830',
        warning_bg: '#F2DBAE',
        error: '#FF0000',
        error_bg: '#FFDEDE',
        caution: '#F2D022',
      },
      dark: {
        primary: '#05466F',
        primary_dark: '#0D4E75',
        primary_light: '#25506B',
        primary_bg: '#3D5362',
        secondary: '#014E42',
        secondary_dark: '#01362E',
        secondary_light: '#016B5B',
        gray_first: '#50636F',
        gray_second: '#334148',
        gray_third: '#252F34',
        gray_fourth: '#1E2529',
        gray_fifth: '#000000',
        white: '#12191D',
        success: '#014B01',
        success_bg: '#286B28',
        warning: '#7B2F03',
        warning_bg: '#7C6639',
        error: '#A40303',
        error_bg: '#815050',
        caution: '#96821A',
      },
    },
    options: {
      customProperties: true,
      variations: false,
    },
  },
};

export default preset;
