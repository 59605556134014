<template>
  <component :is="to ? 'router-link' : 'div'" :to="to">
    <v-btn
      v-bind="$attrs"
      class="relative flex items-center justify-center cursor-pointer px-3 min-w-[100px] w-full h-9 rounded"
      :class="{
        [customClass]: true,
        [`text-${color} bg-white border-${color} border-2 border-solid`]: outlined,
        [`text-white bg-${color}`]: !outlined,
        disabled: disabled,
      }"
      :disabled="disabled"
      :type="type"
      @click="doClick"
    >
      <slot>
        <span class="text">{{ text }}</span>
      </slot>
    </v-btn>
  </component>
</template>

<script lang="ts">
import { defineComponent, PropType, toRef } from 'vue';
import { Location } from 'vue-router';

export default defineComponent({
  name: 'Button',

  inheritAttrs: false,

  props: {
    disabled: {
      type: Boolean,
    },

    to: {
      type: Object as PropType<Location | undefined>,
      default: undefined,
    },

    text: {
      type: String,
      default: undefined,
    },

    color: {
      type: String,
      default: 'primary',
    },

    type: {
      type: String,
      default: 'button',
    },

    outlined: {
      type: Boolean,
    },

    customClass: {
      type: String,
      default: '',
    },
  },

  emits: {
    click: () => true,
  },

  setup(props, { emit }) {
    const disabled = toRef(props, 'disabled');

    function doClick() {
      if (disabled.value) {
        return;
      }

      emit('click');
    }

    return { doClick };
  },
});
</script>
