import { ClientDevice } from '@anschuetz-elog/common';
import { defineModule, localActionContext } from 'direct-vuex';

import useFeathers from '#/compositions/useFeathers';

const LS_DEVICE_ID = 'client-device:client-device-id';

export interface ClientDeviceState {
  clientDeviceId: string | null;
}

const clientDeviceModule = defineModule({
  namespaced: true,

  state: (): ClientDeviceState => ({
    clientDeviceId: localStorage.getItem(LS_DEVICE_ID) || null,
  }),

  mutations: {
    saveClientDeviceId(state, clientDeviceId: string | null): void {
      state.clientDeviceId = clientDeviceId;

      if (clientDeviceId === null) {
        localStorage.removeItem(LS_DEVICE_ID);
      } else {
        localStorage.setItem(LS_DEVICE_ID, clientDeviceId);
      }
    },
  },

  actions: {
    async createClientDevice(context, name: string): Promise<void> {
      const feathers = useFeathers();
      const { commit } = localActionContext(context, clientDeviceModule);
      const clientDevice = await feathers.service('clientDevice').create(new ClientDevice({ name }));

      commit.saveClientDeviceId(clientDevice._id);
    },
    unregisterClientDevice(context): void {
      const { commit } = localActionContext(context, clientDeviceModule);
      commit.saveClientDeviceId(null);
    },
  },
});

export default clientDeviceModule;
