import {
  Book,
  BookKeyBundle,
  Element,
  FieldValue,
  FIXED_PROTOCOL_CONTENT_TYPE_IDS,
  isLinkableValue,
  isProtocolLink,
  Protocol,
  ProtocolContentType,
  ProtocolLink,
  ProtocolLinkElement,
  ProtocolType,
  User,
} from '@anschuetz-elog/common';
import { FeathersService, Service } from '@feathersjs/feathers';
import jsrsasign from 'jsrsasign';
import { orderBy } from 'lodash';
import moment from 'moment';
import forge from 'node-forge';
import Vue from 'vue';

import useFeathers from '#/compositions/useFeathers';
import { createNewBookKeyBundle, getDecryptedPassphrase } from '#/helpers/book-key-bundle';
import { addSignature } from '#/helpers/SigningUtils';
import i18n from '#/i18n';
import { find } from '#/lib/feathers-helpers';
import logger from '#/logger';
import store from '#/store';
import { dateTimeStr } from '#/utilities';

/** length of the salt in bytes */
const SALT_LENGTH = 64;
/** derived key length in bits */
const KEY_LENGTH = 256;
/** derived iv length in bits */
const IV_LENGTH = 128;
/** the count of iterations for pbkdf2 algorithm */
const PBKDF2_ITERATIONS = 1e5;

async function getConfidentialBook(protocol: Protocol): Promise<Book | undefined> {
  const books = protocol.books || [];
  const feathers = useFeathers();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  for await (const _book of books) {
    const book = await feathers.service('book').get(_book._id);
    if (book.confidential) {
      return book;
    }
  }
  return undefined;
}

function isWebCryptoAvailable(): boolean {
  return !!window.crypto?.subtle && false;
}

async function nodeForgeKeysFromPassphrase(salt: string, passphrase: string) {
  // since the pbkdf2 of node-forge takes around 1.6s we give the browser time to do other things between subsequent calls
  const keyLengthInBytes = KEY_LENGTH / 8;
  const ivLengthInBytes = IV_LENGTH / 8;
  const aesKey = forge.pkcs5.pbkdf2(passphrase, salt, PBKDF2_ITERATIONS, keyLengthInBytes + ivLengthInBytes, 'sha512');

  // doing this slight timeout as node-forge is working synchronously and takes a long time
  // so we want to give the browser time to react on other things like user interactions
  await new Promise((resolve) => setTimeout(resolve, 50));

  return { key: aesKey.slice(0, keyLengthInBytes), iv: aesKey.slice(keyLengthInBytes) };
}

async function keysFromPassphrase(salt: BufferSource, passphrase: string) {
  const enc = new TextEncoder().encode(passphrase);
  const baseKey = await crypto.subtle.importKey('raw', enc, 'PBKDF2', false, ['deriveBits']);

  const keys = await crypto.subtle.deriveBits(
    {
      name: 'PBKDF2',
      hash: 'SHA-512',
      salt,
      iterations: PBKDF2_ITERATIONS,
    },
    baseKey,
    KEY_LENGTH + IV_LENGTH,
  );

  const keyLengthInBytes = KEY_LENGTH / 8;
  const iv = new Uint8Array(keys.slice(keyLengthInBytes));

  const key = await crypto.subtle.importKey(
    'raw',
    new Uint8Array(keys.slice(0, keyLengthInBytes)),
    { name: 'AES-CBC' },
    false,
    ['decrypt', 'encrypt'],
  );

  return { key, iv };
}

type EncryptResult = {
  encryptedData: string;
  salt: string;
};

async function nodeForgeEncrypt(plaintext: string, passphrase: string): Promise<EncryptResult> {
  const salt = jsrsasign.KJUR.crypto.Util.getRandomHexOfNbytes(SALT_LENGTH);

  const { key, iv } = await nodeForgeKeysFromPassphrase(salt, passphrase);

  const cipher = forge.cipher.createCipher('AES-CBC', key);
  cipher.start({ iv });
  cipher.update(new forge.util.ByteStringBuffer(plaintext));
  cipher.finish();
  const encryptedData = forge.util.encode64(cipher.output.data);

  return { encryptedData, salt };
}

async function encrypt(plaintext: string, passphrase: string): Promise<EncryptResult> {
  if (!isWebCryptoAvailable()) {
    // web crypto is only available with secure connections, so we fall back to node forge in that case
    return nodeForgeEncrypt(plaintext, passphrase);
  }
  const textEncoder = new TextEncoder();
  const saltStr = jsrsasign.KJUR.crypto.Util.getRandomHexOfNbytes(SALT_LENGTH);
  const salt = textEncoder.encode(saltStr);

  const { key, iv } = await keysFromPassphrase(salt, passphrase);

  const cipherText = (await window.crypto.subtle.encrypt(
    { name: 'AES-CBC', iv },
    key,
    textEncoder.encode(plaintext),
  )) as ArrayBuffer;
  const encryptedData = btoa(
    new Uint8Array(cipherText).reduce((data, byte) => {
      return data + String.fromCharCode(byte);
    }, ''),
  );

  return { encryptedData, salt: saltStr };
}

async function nodeForgeDecrypt(cipherText: string, passphrase: string, salt: string): Promise<string> {
  const { key, iv } = await nodeForgeKeysFromPassphrase(salt, passphrase);

  const decipher = forge.cipher.createDecipher('AES-CBC', key);
  decipher.start({ iv });
  const decodedData = forge.util.decode64(cipherText);
  decipher.update(new forge.util.ByteStringBuffer(decodedData));
  if (!decipher.finish()) {
    throw new Error('Decrypting data failed');
  }
  const decryptedData = decipher.output.data;
  return decryptedData;
}

async function decrypt(cipherText: string, passphrase: string, salt: string): Promise<string> {
  if (!isWebCryptoAvailable()) {
    // web crypto is only available with secure connections, so we fall back to node forge in that case
    return nodeForgeDecrypt(cipherText, passphrase, salt);
  }

  const textEncoder = new TextEncoder();
  const { key, iv } = await keysFromPassphrase(textEncoder.encode(salt), passphrase);

  const decryptedData = (await window.crypto.subtle.decrypt(
    { name: 'AES-CBC', iv },
    key,
    Uint8Array.from(atob(cipherText), (c) => c.charCodeAt(0)),
  )) as ArrayBuffer;

  const textDecoder = new TextDecoder();
  return textDecoder.decode(decryptedData);
}

async function encryptProtocol(protocol: Protocol, book: Book): Promise<void> {
  const feathers = useFeathers();

  if (!store.state.auth.user || !store.state.auth.encryptionKey) {
    throw new Error('No user logged in or not having an encryption key');
  }
  const { user } = store.state.auth;

  const now = new Date();
  const bookKeyBundles = (await feathers.service('book-key-bundle').find({
    query: { book: { _id: book._id }, startTime: { $lte: now }, endTime: { $gt: now }, $limit: 1 },
    paginate: false,
  })) as BookKeyBundle[];

  let bookKeyBundle = bookKeyBundles?.[0];
  if (!bookKeyBundle || moment(bookKeyBundle.endTime).isBefore(new Date())) {
    bookKeyBundle = await createNewBookKeyBundle(feathers, user, book);
  }

  const passphrase = getDecryptedPassphrase(bookKeyBundle, user, store.state.auth.encryptionKey);

  const { encryptedData, salt } = await encrypt(JSON.stringify(protocol.contents), passphrase);

  protocol.contents = [
    {
      type: ProtocolContentType.createRef(FIXED_PROTOCOL_CONTENT_TYPE_IDS.ENCRYPTED),
      data: {
        encryptedData,
        encryptionInfo: {
          salt, // TODO: can we save the salt here?
          bookKeyBundleId: bookKeyBundle._id,
        },
      },
    },
  ];
}

export async function saveProtocol(protocol: Protocol): Promise<Protocol> {
  const confidentialBook = await getConfidentialBook(protocol);
  if (confidentialBook) {
    await encryptProtocol(protocol, confidentialBook);
  }

  addSignature(protocol);

  const feathers = useFeathers();
  const savedProtocol = await feathers.service('protocol').create(protocol);
  Vue.toasted.success(i18n.t('protocol.successful_save') as string, {
    position: 'bottom-center',
    duration: 3000,
    className: 'notification-protocol-saved',
  });
  return savedProtocol;
}

export async function decryptProtocol(protocol: Protocol): Promise<Protocol> {
  try {
    protocol.isDecrypting = true;
    const feathers = useFeathers();

    if (!store.state.auth.user) {
      return protocol;
    }

    // TODO: fail once all users are migrated to have an encryption key
    if (!store.state.auth.encryptionKey) {
      return protocol;
    }

    const { user } = store.state.auth;

    const encryptedContent = protocol.contents.find(
      ({ type }) => type._id === FIXED_PROTOCOL_CONTENT_TYPE_IDS.ENCRYPTED,
    );
    if (!encryptedContent) {
      return protocol;
    }

    const { encryptedData, encryptionInfo } = encryptedContent.data as {
      encryptedData: string;
      encryptionInfo: { salt: string; bookKeyBundleId: string };
    };

    let bookKeyBundle: BookKeyBundle;
    try {
      bookKeyBundle = await feathers.service('book-key-bundle').get(encryptionInfo.bookKeyBundleId);

      const passphrase = getDecryptedPassphrase(bookKeyBundle, user, store.state.auth.encryptionKey);

      const decryptedData = await decrypt(encryptedData, passphrase, encryptionInfo.salt);

      protocol.contents = JSON.parse(decryptedData) as Protocol['contents'];
    } catch (e) {
      // we can't decrypt the protocol, so we just return it as is
      logger.error(e);
      return protocol;
    }

    return protocol;
  } finally {
    protocol.isDecrypting = undefined;
  }
}

export async function decryptProtocols(stop: () => boolean, protocols: Protocol[]): Promise<Protocol[]> {
  const encryptedProtocols = protocols.filter((protocol) => isProtocolEncrypted(protocol));
  encryptedProtocols.forEach((protocol) => (protocol.isDecrypting = true)); // show table
  const sortedEncryptedProtocols = orderBy(encryptedProtocols, (protocol) => moment(protocol.timestamp), ['desc']);
  for (const protocol of sortedEncryptedProtocols) {
    if (stop()) {
      return protocols;
    }
    await decryptProtocol(protocol);
    if (!isWebCryptoAvailable()) {
      // doing this slight timeout as node-forge is working synchronously and takes a long time
      // so we want to give the browser time to react on other things like user interactions
      await new Promise((resolve) => setTimeout(resolve, 100));
    }
  }
  return protocols;
}

export function isProtocolEncrypted(protocol: Protocol): boolean {
  return protocol.contents.some(({ type }) => type._id === FIXED_PROTOCOL_CONTENT_TYPE_IDS.ENCRYPTED);
}

export async function saveProtocolAsDraft(protocol: Protocol): Promise<Protocol> {
  protocol.isDrafted = true;

  const confidentialBook = await getConfidentialBook(protocol);
  if (confidentialBook) {
    await encryptProtocol(protocol, confidentialBook);
  }

  const feathers = useFeathers();
  const savedProtocol = await feathers.service('protocolDraft').create(protocol);
  Vue.toasted.success(i18n.tc('protocol.successful_draft_save'), {
    position: 'bottom-center',
    duration: 3000,
    className: 'notification-protocol-draft-saved',
  });
  return savedProtocol;
}

export async function updateProtocolsWithLink(
  protocol: Protocol | null,
  allProtocols: Protocol[],
  userId: string,
): Promise<void> {
  const feathers = useFeathers();

  if (!protocol || !protocol.predecessor) {
    return Promise.resolve();
  }
  const protocolId = protocol.predecessor._id;
  const author = User.createRef(userId);

  const protocolsWithLinks = allProtocols.filter((protocol) =>
    protocol.contents.some(({ data }) =>
      Object.values(data).some((value) => (value as ProtocolLink)?.protocol?._id === protocolId),
    ),
  );

  const recentProtocolsWithLinks = protocolsWithLinks.filter(
    (protocolWithLink) => !protocolsWithLinks.some((protocol) => protocol.predecessor?._id === protocolWithLink._id),
  );

  if (!recentProtocolsWithLinks.length) return Promise.resolve();

  const protocolTypes = await find(
    feathers.service('protocolType') as unknown as FeathersService<unknown, Service<ProtocolType>>,
    {
      query: {
        _id: {
          $in: [
            ...recentProtocolsWithLinks.map(({ type }) => ('_id' in type ? type._id : type.ref._id)),
            '_id' in protocol.type ? protocol.type._id : protocol.type.ref._id,
          ],
        },
      },
    },
  );
  const typeMap = protocolTypes.reduce<Record<string, ProtocolType>>(
    (map, type) => ({
      ...map,
      [type._id]: type,
    }),
    {},
  );

  const protocolContentTypes = await find(
    feathers.service('protocolContentType') as unknown as FeathersService<unknown, Service<ProtocolContentType>>,
    {
      query: {
        _id: {
          $in: protocolTypes.reduce<string[]>(
            (ids, { contentTypes }) => [...ids, ...contentTypes.map(({ ref }) => ref._id)],
            [],
          ),
        },
      },
    },
  );
  const contentTypeMap = protocolContentTypes.reduce<Record<string, ProtocolContentType>>(
    (map, contentType) => ({
      ...map,
      [contentType._id]: contentType,
    }),
    {},
  );

  await Promise.all(
    recentProtocolsWithLinks.map(async (oldProtocolWithLink): Promise<void> => {
      const newProtocolWithLink = Protocol.createAmendment(oldProtocolWithLink, author);
      const linkedRecordChangeReason = protocol.reasonForChange || '';
      newProtocolWithLink.reasonForChange = protocol.deleted
        ? `${linkedRecordChangeReason} (linked record deleted)`
        : linkedRecordChangeReason.search('(linked record changed)') !== -1
          ? linkedRecordChangeReason
          : `${linkedRecordChangeReason} (linked record changed)`;
      const protocolType =
        typeMap['_id' in newProtocolWithLink.type ? newProtocolWithLink.type._id : newProtocolWithLink.type.ref._id];
      protocolType.contentTypes.forEach(({ ref: { _id: contentTypeId }, links }) => {
        const data = newProtocolWithLink.contents.find((content) => content.type._id === contentTypeId)?.data;
        if (!data) {
          return;
        }
        const contentType = contentTypeMap[contentTypeId];
        (links || []).forEach((link) => {
          if (link.target.protocolType._id !== ('_id' in protocol.type ? protocol.type._id : protocol.type.ref._id)) {
            return;
          }
          const linkElement = contentType.schema.find(
            (element): element is Element => element.type !== undefined && element._id === link.elementId,
          );
          if (!linkElement) {
            return;
          }
          const value = data[linkElement.name] as FieldValue;
          if (!isProtocolLink(value)) {
            return;
          }
          if (protocol.deleted) {
            data[linkElement.name] = undefined;
            return;
          }
          const targetContentType = contentTypeMap[link.target.protocolContentType._id];
          const linkedContent = protocol.contents.find(({ type }) => type._id === targetContentType._id);
          const targetElement = targetContentType.schema.find(
            (element): element is Element => element.type !== undefined && element._id === link.target.elementId,
          );
          const linkedValue =
            !linkedContent || !targetElement ? undefined : (linkedContent.data[targetElement.name] as FieldValue);
          if (!isLinkableValue(linkedValue)) {
            throw new Error(`Can not link to such a type of value: ${value}`);
          }
          data[linkElement.name] = {
            protocol: Protocol.createRef(protocol._id),
            value: isProtocolLink(linkedValue) ? linkedValue.value : linkedValue,
          };
        });
        contentType.schema.forEach((element) => {
          if (element.type !== 'protocollink') {
            return;
          }
          data[element.name] = getLinkValue(protocol, element);
        });
      });
      await saveProtocol(newProtocolWithLink);
      await updateProtocolsWithLink(newProtocolWithLink, allProtocols, userId);
    }),
  );
}

function getTimestamp(timestamp: string, element: ProtocolLinkElement): string {
  const smtTimestamp = `${dateTimeStr(timestamp)} [SMT]`;
  const utcTimestamp = `${dateTimeStr(moment(timestamp).utc().format())} [UTC]`;

  if (element.timestampType === 'SMT') {
    return smtTimestamp;
  }
  if (element.timestampType === 'UTC') {
    return utcTimestamp;
  }
  return `${smtTimestamp} | ${utcTimestamp}`;
}

export function getLinkValue(protocol: Protocol, element: ProtocolLinkElement): ProtocolLink {
  const timestamp = getTimestamp(protocol.timestamp, element);
  const position = protocol.position;
  let value: ProtocolLink['value'] = timestamp;
  if (!element.displayedInformation || element.displayedInformation === 'timestamp') {
    value = timestamp;
  } else if (element.displayedInformation === 'position') {
    value = { timestamp: undefined, position };
  } else if (element.displayedInformation === 'both') {
    value = { timestamp, position };
  }
  return {
    protocol: Protocol.createRef(protocol._id),
    value,
  };
}
